
<div class="widget-container" #areaLineChart>
    <app-area-line-combo-chart-new
    style="width: 100%;"
    [config]="config"
    [headerConfig]="this.item.config.widget_header_config"
    [heading]="heading" 
    [data]="data"  
    [item]="item"
    [pageKey]="pageKey"
    >
    </app-area-line-combo-chart-new>
</div>
