import { Component, OnInit, ViewChild } from '@angular/core';
import Widget from '../widget';
import { MatMenuTrigger } from '@angular/material/menu';
import { RouterHandlerService } from 'src/app/services/router-handler.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-navigation-card',
  templateUrl: './navigation-card.component.html',
  styleUrls: ['./navigation-card.component.scss']
})
export class NavigationCardComponent extends Widget implements OnInit {
  static override key = 101002;

  loader: boolean = true;
  customHeader:any;
  kpi1Value:any='';
  kpi2Value:any ='';
  unit:any="";
  dbData:any;
  showByText:any;
  presId:any;
  headerName:any;
  donutData:any;
  apiKeys:any;

  clientKey: any;
  moduleKey: any;
  dashboardKey: any;
  cardName: any;
  cardId: any;
  moduleName:any;
  decryptedParams:any;

  constructor(private routerHandlerService: RouterHandlerService) {
    super();

    this.subs.push(this.filterService.filterQuery.subscribe((query:any)=>{
      this.loadApiData();
    }))

    this.route.params.subscribe((p: any) => {
      this.moduleName = p['module'] || '';
      this.cardName = p['cardName'] || '';

      this.route.queryParams.subscribe((params: any) => {
        this.decryptedParams = this.navigationService.decryptData(params);
        this.clientKey = this.decryptedParams["cl_key"];
        this.moduleKey = this.decryptedParams["md_key"];
        this.dashboardKey = this.decryptedParams["ds_key"];
      });
    });
  }

  override ngOnInit(): void {
    this.customHeader = this.item.config?.['kpi_header_value']?.headerControls?.[0];
  }

  @ViewChild(MatMenuTrigger) override trigger!: MatMenuTrigger;
  closeMenu() {
    this.trigger.closeMenu();
  }
  
  loadApiData() {
    this.loader = true;
    let kpi1 = this.item.config['custom_kpi_value_api'];
    this.apiKeys = kpi1.api_key
    // console.log('this.item.config',this.item.config)

    if (kpi1.api_key && (this.isEmptyObject(this.filterService.baseQuery) == false)) {

      // Api payload
      let obj:any = {"api_key" : kpi1.api_key } //'prescriber_id' : this.presId

      let query = Object.assign({}, this.filterService.baseQuery, obj)
      query["report_typ"] = this.filterService.report_type;
      
      this.subs.push(
        this.httpClient.post(`${this.apiService.apiBaseUrl}/${this.apiService.executionUrl}`, query).subscribe((res:any)=> {
          if(res!=null) {
            this.dbData = res[0]
    
            this.headerName = res[0]?.[this.customHeader?.headerNameDrop]
            this.kpi1Value = res[0]?.kpi_value_1
            this.kpi2Value = res[0]?.kpi_value_2
            // this.kpi2Value = '38'
            this.unit = res[0]?.uom_1
    
            let swapUOM:any = this.item?.config?.['kpi_header_value']?.controls
            
            // donutData
            this.donutData = [];

            swapUOM.forEach((f:any) => {
              let obj = {
                name: this.dbData[f.linkName],
                value: this.dbData[f.linkPoint],
                color: f.donutColor
              }
              this.donutData.push(obj);
            })

            
            let fndIdx:any;
            if(this.showByText) {
              if(swapUOM.length) {
                swapUOM.find((f:any, index:any)=> {
                  if (this.dbData?.[f.prefixNameDrop]?.toLowerCase()==this.showByText.toLowerCase()) {
                    fndIdx = index
                  } else if (this.dbData?.[f.suffixNameDrop]?.toLowerCase()==this.showByText.toLowerCase()) {
                    fndIdx = index
                  } else if (f.suffixName.toLowerCase()==this.showByText.toLowerCase()) {
                    fndIdx = index
                  } else if (f.prefixName.toLowerCase()==this.showByText.toLowerCase()) {
                    fndIdx = index
                  }
    
                  if (fndIdx >= 0 && fndIdx < swapUOM.length) {
                    const itemToMove = swapUOM[fndIdx];
                    swapUOM = [itemToMove, ...swapUOM.slice(0, fndIdx), ...swapUOM.slice(fndIdx + 1)];
                  }
                  this.item.config['kpi_header_value'].controls = [...swapUOM]
                })
              }
            }
          }
          this.loader=false;
        }, (err: any) =>{
          this.loader=false;
          console.log(err)
        })
      )
    }
  }

  isEmptyObject(obj:any) {
    return Object.keys(obj).length === 0;
  }

  navigationToDetailPage(data:any) {
    // Set Navigation
    const conf0 = this.item.config?.['nav_configuration'].controls[0]
    let nav = {
      dbJSON : conf0.dbJSON,
      s3JSON : conf0.s3JSON,
      pageName : conf0.s3JSON?.page_nm,
      isDashboardLevel : conf0.isDashboardLevel
    }

    this.routerHandlerService.navDetailInfo(nav); 
    this.routerHandlerService.storingPayloadAndFilterForGrid(conf0, this.dbData, this.cardName);

    let query:any = Object.assign({}, this.decryptedParams, {tab_id: 0}, {page: 'card'+this.apiKeys});
    let queryMaped = this.navigationService.movePropertyToFirst(query, 'tab_id');
    this.navigationService.routerNavigate(`dashboard/${this.moduleName}/${nav.pageName}`, queryMaped);
  }
}
