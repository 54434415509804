import { CurrencyPipe } from '@angular/common';
import { Component, ElementRef, HostBinding, HostListener, Injectable, Input, OnDestroy, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import * as d3 from "d3";
import { Subscription } from 'rxjs';
import { FilterService } from 'src/app/services/filter.service';
@Injectable({
  providedIn: 'root'
})
@Component({
  selector: 'app-area-line-combo-chart-new',
  templateUrl: './area-line-combo-new-chart.component.html',
  styleUrls: ['./area-line-combo-new-chart.component.scss']
})
export class AreaLineComboNewChartComponent implements OnInit, OnDestroy {
  // @ViewChild('barContainer', { static: true }) barContainer!: ElementRef
  // @ViewChild('WordCloudChart') WordCloudChart!: ElementRef;

  @Input('data') data: any
  @Input('pageKey') pageKey: any
  @Input('item') item: any
  @Input('config') config: any
  @Input('headerConfig') headerConfig: any
  @Input('heading') heading: string = ''
  
  @ViewChild('fs') fs!: ElementRef;

  isActive = false;
  reqSubcription: Subscription[] = []
  iconList: any[] = []
  timeCycleData: any = []
  dataTurn: any
  selectedType = 'D'
  mytooltipData: any
  isTooltip: boolean = false
  noData : boolean = false
  WordCloudChartData: any;
  initiateChart: Boolean = false
  areaLineData: any = {};
  divId: any = 'areaLineChartDiv';
  props: any = {};
  height: any = 0;
  isLoading = false;
  
  excelConfig = [
    {
      "header": "Date",
      "key": "period",
      "type": "date",
    },
    {
      "header": "Source Name",
      "key": "source_nm",
      "type": "string",
    },
    {
      "header": "No of Days",
      "key": "value",
      "type": "number",
    },
    {
      "header": "Average",
      "key": "network_avg",
      "type": "number",
    }
  ]
  
  @HostBinding('class.is-fullscreen') isFullscreen = false;
  @HostListener('fullscreenchange', ['$event'])
  @HostListener('webkitfullscreenchange', ['$event'])
  @HostListener('mozfullscreenchange', ['$event'])
  @HostListener('MSFullscreenChange', ['$event'])
  screenChange(event: any) {
    if (this.isFullscreen == true) {
      this.closeFullscreen();
    }
  }

  constructor(private currency: CurrencyPipe, private filterService: FilterService) {}

  
  showTooltip(myType: any, myData: any, myX: any, myY: any, chartWidth: any, chartHeight: any): void {
    const concatLabels = this.props.lineTypes.concat(this.props.areaTypes);
    let data = concatLabels.map((m:any)=> {
      return {
        name: m,
        color: this.props.colors[m],
        key: m,
        value: myData[m]
      }
    })
    
 
  data =  data.sort((a: any,b: any) =>  a['key'] == myData['selected'] ? -1 : b['key'] == myData['selected'] ? 1 : 0);
  myData['data']=data
    this.mytooltipData = myData
    this.height = 0
    this.dataTurn = 0
    this.height = chartHeight - myY
    this.dataTurn = chartWidth - myX

    if (this.isFullscreen == true) {
      if (this.height < 200) {
        d3.select("#d3AreaLineTooltip")
          .style('visibility', 'visible')
          .style('position', 'absolute')
          .style('bottom', (this.height - 60) + 'px')
          .style('top', 'unset')
      }
      else if (this.height > 200) {

        d3.select("#d3AreaLineTooltip")
          .style('visibility', 'visible')
          .style('position', 'absolute')
          .style('top', (myY + 30) + 'px')
          .style('bottom', 'unset')
      }

      if (this.dataTurn < 350) {
        d3.select("#d3AreaLineTooltip")
          .style('visibility', 'visible')
          .style('position', 'absolute')
          .style('right', (this.dataTurn + 20) + 'px')
          .style('left', 'unset')
      }
      else if (this.dataTurn > 350) {

        d3.select("#d3AreaLineTooltip")
          .style('visibility', 'visible')
          .style('position', 'absolute')
          .style('left', (myX + 25) + 'px')
          .style('right', 'unset')
      }
      this.isTooltip = true

    } 
    else {
      if (this.height < 200) {
        d3.select("#d3AreaLineTooltip")
          .style('visibility', 'visible')
          .style('position', 'absolute')
          .style('bottom', (this.height - 60) + 'px')
          .style('top', 'unset')
      }
      else if (this.height > 200) {

        d3.select("#d3AreaLineTooltip")
          .style('visibility', 'visible')
          .style('position', 'absolute')
          .style('top', (myY + 30) + 'px')
          .style('bottom', 'unset')
      }

      if (this.dataTurn <350) {
        d3.select("#d3AreaLineTooltip")
          .style('visibility', 'visible')
          .style('position', 'absolute')
          .style('right', (this.dataTurn + 20) + 'px')
          .style('left', 'unset')
      }
      else if (this.dataTurn >350) {

        d3.select("#d3AreaLineTooltip")
          .style('visibility', 'visible')
          .style('position', 'absolute')
          .style('left', (myX + 25) + 'px')
          .style('right', 'unset')
      }
      this.isTooltip = true
    }
  }

  ngOnDestroy(): void {
    this.reqSubcription.forEach(res => res.unsubscribe())
  }

  hideTooltip(myType: any): void {
    this.isTooltip = false
    // d3.select("#d3AreaLineTooltip")
    // .style('visibility', 'hidden');
  }
  
  async wait(ms: number): Promise<void> {
    return new Promise<void>(resolve => setTimeout(resolve, ms));
  }

  start() {
    this.isLoading = true;
  }

  changeTimeCycle(selectedType: any) {
    this.start()
    this.selectedType = selectedType
    this.props.period = this.selectedType
    
    // API Payloads
    let obj:any = {"api_key": "100171", "time_cycle": this.selectedType, "report_typ": this.filterService.report_type}

    this.reqSubcription.push(this.filterService.selfTimeCycleExecuteQuery(obj).subscribe((res: any) => {
      this.data = res == null ? [] : res
      this.areaLineData["lineData"] = this.data
      this.areaLineData["barData"] = this.data
      this.plotChart()
    }, (error: any) => {
      this.data = []
      console.error( "areaLineChart Chart", error)
    }))
  }

  closeFullscreen(): void {
    this.isFullscreen = false;
    if (document.fullscreenElement) {
      document.exitFullscreen();
    }
    this.props.chartHeight = 500
    this.isActive = false;
    setTimeout(() => {
      this.plotChart()
    }, 100);
  }

  @HostListener("window:resize", ["$event"])
  onResize(event: Event) {
    setTimeout(() => {
      if(!this.isFullscreen)
      this.plotChart()
    }, 100);
  }

  ngOnInit(): void {
    this.start()
    this.selectedType = this.item.config?.default_timeCycle ? this.item.config.default_timeCycle : this.selectedType
    this.timeCycleData = this.item.config?.time_cycle ? this.item.config.time_cycle : this.timeCycleData
    this.iconList = this.item.config.icon ? this.item.config.icon : this.iconList
    this.initiateCharts();
  }

  // data change Event
  ngOnChanges(changes: SimpleChanges): void {
    if (changes['data'].currentValue != changes['data'].previousValue && this.initiateChart) {
      this.getAreaLineComboData()
    }
  }

  //  d3 chart initial structure
  initiateCharts(): void {
    // only need to call this once on initialisation
    const myChart = this;
    const mySection: any = document.getElementById(myChart.divId);
    const myClass = myChart.divId;
    const width = mySection.clientWidth;
    const height = 300; // 300 is random height, height is fixed to data later on
    const mySvg = d3.select('#' + myClass)
      .append('svg')
      .attr('id', 'svg_' + myClass)
      .attr('width', '100%')
      .attr('height', height)
      .style('background-color', 'white');

    const defs = mySvg.append('defs');

    defs.append('clipPath').attr('id', 'alcBrushClip' + myClass)
      .append('rect').attr('id', 'alcBrushClipRect' + myClass);

    const filter = defs.append('filter').attr('id', 'drop-shadow').attr('width', 10).attr('height', 24);
    filter.append('feGaussianBlur').attr('in', 'SourceAlpha').attr('stdDeviation', 1).attr('result', 'blur');
    filter.append('feOffset').attr('in', 'blur').attr('dx', 1)
      .attr('dy', 1).attr('result', 'offsetBlur');
    filter.append('feFlood').attr('in', 'offsetBlur')
      .attr('flood-color', '#000000').attr('flood-opacity', 0.4)
      .attr('result', 'offsetColor');
    filter.append('feComposite').attr('in', 'offsetColor').attr('in2', 'offsetBlur').attr('operator', 'in').attr('result', 'offsetBlur');
    const feMerge = filter.append('feMerge');
    feMerge.append('feMergeNode').attr('in', 'offsetBlur');
    feMerge.append('feMergeNode').attr('in', 'SourceGraphic');

    mySvg.append('text').attr('id', 'noDataMessage' + myClass);

    defs.append('clipPath').attr('id', 'alcBrushClip' + myClass)
      .append('rect').attr('id', 'alcBrushClipRect' + myClass);

    mySvg.append('g').attr('id', 'legendGroup' + myClass);

    mySvg.append('g').attr('class', 'brushAreaGroup' + myClass);
    mySvg.append('g').attr('class', 'brushLineGroup' + myClass);
    mySvg.append('g').attr('id', 'brushGroup' + myClass)
    mySvg.append('text').attr('id', 'yAxisLeftLabel' + myClass);
    mySvg.append('text').attr('id', 'yAxisRightLabel' + myClass);
    mySvg.append('g').attr('id', 'xAxis' + myClass).attr('class', 'axis' + myClass);
    mySvg.append('g').attr('id', 'yAxis' + myClass).attr('class', 'axis' + myClass);
    mySvg.append('g').attr('id', 'yAxisRight' + myClass).attr('class', 'axis' + myClass);


    mySvg.append('g').attr('class', 'areaGroup' + myClass);
    mySvg.append('g').attr('class', 'lineGroup' + myClass);
    mySvg.append('line').attr('class', 'dotLine' + myClass);
    mySvg.append('g').attr('class', 'areaDotsGroup' + myClass);
    mySvg.append('g').attr('class', 'lineDotsGroup' + myClass);
    mySvg.append('line').attr('class', 'partialPeriodLine' + myClass);
    mySvg.append('text').attr('class', 'partialPeriodLineLabel' + myClass);
    mySvg.append('line').attr('class', 'handleLines' + myClass + ' handleLeftLine1' + myClass);
    mySvg.append('line').attr('class', 'handleLines' + myClass + ' handleLeftLine2' + myClass);
    mySvg.append('line').attr('class', 'handleLines' + myClass + ' handleRightLine1' + myClass);
    mySvg.append('line').attr('class', 'handleLines' + myClass + ' handleRightLine2' + myClass);
    mySvg.append('circle').attr('class', 'legendArrowLeftItem' + myClass + ' legendArrowCircleLeft' + myClass);
    mySvg.append('text').attr('class', 'legendArrowLeftItem' + myClass + ' legendArrowLeft' + myClass);
    mySvg.append('circle').attr('class', 'legendArrowRightItem' + myClass + ' legendArrowCircleRight' + myClass);
    mySvg.append('text').attr('class', 'legendArrowRightItem' + myClass + ' legendArrowRight' + myClass);
    mySvg.append('line').attr('class', 'barMouseoverLine' + myClass + ' barMouseoverItem' + myClass);
    mySvg.append('rect').attr('class', 'barMouseoverRect' + myClass + ' barMouseoverItem' + myClass);
    mySvg.append('text').attr('class', 'barMouseoverText' + myClass + ' barMouseoverItem' + myClass);
    // this.getAreaLineComboData()
    this.initiateChart = true
  }

  plotChart(): void {
    const myChart: any = this;
    const myClass = myChart.divId;
    const mySvg: any = d3.select('#svg_' + myClass);
    const width = mySvg.node().getBoundingClientRect().width;
    const height = myChart.props.chartHeight;
    let margins = { left: 50, right: 50, top: 80, bottom: 100, brush: 40};
    if(myChart.props.showBrush === false){
      // change margins if no brush
      margins.brush = 0;
      margins.bottom = 50;
    }
    // tick format used across charts (see comment in bar chart)
    const tickFormat: any = {"D": "%d %b %y", "W": "%d %b %y", "M": "%b %Y", "Q": "%b %Y", "Y": "%Y"};
    const timePeriod = this.props.period;
    const xTickFormat: any = d3.timeFormat(tickFormat[timePeriod]);
    const chartData: any = myChart.areaLineData;
    // two datasets here - one for RT (multiple lines), one for errors (stacked area)
    // convert to date format
    chartData.lineData?.map((m: any) => m.date = new Date(m[myChart.props.dateVar]));
    chartData.lineData?.sort((a: any, b: any) => d3.ascending(a.date, b.date));
    chartData.barData?.map((m: any) => m.date = new Date(m[myChart.props.dateVar]));
    chartData.barData?.sort((a: any, b: any) => d3.ascending(a.date, b.date));
    const areaDataset: any = [], lineDataset: any = [];
    let dateSet: any = new Set();
    myChart.props.lineTypes?.forEach((e: any) => {
      // empty array for each error type
      lineDataset.push([]);
    })
    //build area and line dataset from chart data
    // group error data by date
    let areaSet: any = d3.group(chartData.barData, (d: any) => d.date);
    let yMax: any = 0, tooltipData: any = {};
    areaSet = Array.from(areaSet);
    // build tooltip data
    areaSet.forEach((d: any) => {
      if(tooltipData[String(d[0])] === undefined){
        // set empty array and fill if not set already
        tooltipData[String(d[0])] = {}
      }
      tooltipData[String(d[0])].date = d[0];
      dateSet.add(String(d[0]));
      const myArray: any = {};
      myArray.date = d[0];
      const yVals: any = []
      // for each error type
      myChart.props.areaTypes.forEach((e: any, i: any) => {
        // find the record
        const myRecord = d[1].find((f: any) => f[myChart.props.errorTypeVar] === e);
        // get the value (or 0 if undefined)
        myArray[e] = myRecord === undefined ? 0 : +myRecord[myChart.props.errorVar];
        // set the value in tooltip data
        tooltipData[String(d[0])][e] = myArray[e];
        // push to yVals
        yVals.push(myArray[e]);
      })
      // set yMax and push to area dataset
      yMax = Math.max(yMax, d3.sum(yVals, (s: any) => s));
      areaDataset.push(myArray);
    })
    // define area stack
    const stack = d3.stack()
      .keys(myChart.props.areaTypes)
      .order(d3.stackOrderNone)
      .offset(d3.stackOffsetNone);
    // stack area data
    const areaSeries = stack(areaDataset);
    // now repeat for rt data
    let lineSet: any = d3.group(chartData.lineData, (d: any) => d.date);
    lineSet = Array.from(lineSet);
    lineSet.forEach((d: any) => {
      if(tooltipData[String(d[0])] === undefined){
        tooltipData[String(d[0])] = {};
      }
      tooltipData[String(d[0])].date = d[0];
      dateSet.add(String(d[0]));
      // debugger
      myChart.props.lineTypes.forEach((e: any, i: any) => {
        const myRecord = d[1].find((f: any) => f[myChart.props.errorTypeVar] === e);
        // now we have the record, different format as individual lines
        // add record for this line + data
        lineDataset[i].push({
          date: d[0],
          value: myRecord === undefined ? 0 : myRecord[myChart.props.rtVar],
          color: myChart.props.colors[myChart.props.errorTypeLabels[e]],
          type: e,
          valueVarName: myChart.props.rtVar
        })
        // and add to tooltip data
        tooltipData[String(d[0])][e] = myRecord === undefined ? 0 : myRecord[myChart.props.rtVar];
      })
    })
    // sort and convert dateset
    dateSet = Array.from(dateSet).map((m: any) => m = new Date(m));
    dateSet = dateSet.sort((a: any, b: any) => d3.ascending(a, b));
    //tick values and tick count (see bar chart, same as other 4 charts in terms of axis)
    const tickValues = myChart.props.d3AxisFormatting === true ? null : dateSet;
    const tickCount = myChart.props.d3AxisFormatting === true ? 6 : null;
    // set x extent and x scale (all and selected - xScale - for brush)
    const xExtent: any = d3.extent(dateSet,(d: any) => d);
    const xScaleAll: any = d3.scaleTime().range([0, width - margins.left - margins.right]).domain(xExtent);
    const xScale: any = d3.scaleTime().range([0, width - margins.left - margins.right]).domain(xExtent);
    // y scale right here as well so extra scale for chart AND brush
    const yMaxRight: any = d3.max(chartData.lineData, (m: any) => +m[myChart.props.rtVar])||10;
    const yScale: any = d3.scaleLinear().domain([0, myChart.props.yAxisRight?yMax: Math.max(yMax,yMaxRight)]).range([height - margins.top - margins.bottom, 0]);
    const yScaleRight: any = d3.scaleLinear().domain([0, yMaxRight]).range([height - margins.top - margins.bottom, 0]);
    const yScaleBrush: any = d3.scaleLinear().domain([0, myChart.props.yAxisRight?yMax:Math.max(yMax,yMaxRight)]).range([margins.brush,0]);
    const yScaleRightBrush: any = d3.scaleLinear().domain([0, yMaxRight]).range([margins.brush,0]);
    // line definitions - see bar chart, same logic
    const line: any = d3.line()
      .curve(d3.curveMonotoneX)
      .x((d: any) => xScale(d.date))
      .y((d: any) =>  yScaleRight(d.value));

    const lineBrush: any = d3.line()
      .curve(d3.curveMonotoneX)
      .x((d: any) =>  xScaleAll(d.date))
      .y((d: any) =>  yScaleRightBrush(d.value));

    // area definitions
    const areaBrush: any =  d3.area()
      .x((d: any) => xScaleAll(d.data.date))
      .y0((d: any) =>  yScaleBrush(d[1]))
      .y1( (d: any) =>  yScaleBrush(d[0]))

    const area: any = d3.area()
      .x((d: any) => xScale(d.data.date))
      .y0((d: any) =>  yScale(d[1]))
      .y1( (d: any) =>  yScale(d[0]))
    // mouseover and selection here is a bit complicated
    // resetAxisLabels resets to starting position, called a lot
    mySvg.attr('height', height)
      .on("mouseout", () => resetAxisLabels());
    // to be redundant - see bar
    mySvg.select('#noDataMessage' + myClass)
      .attr("x", margins.left + (width - margins.left - margins.right)/2)
      .attr("y", -8 + margins.top + (height - margins.top  - margins.brush - margins.bottom)/2)
      .attr('font-family', 'Poppins')
      .attr('fill', '#101D42')
      .attr('font-size', '16px')
      .attr('font-weight', '500')
      .attr('text-anchor', 'middle')
      .text(chartData.lineData.length === 0 && chartData.barData.length === 0 ? "There is no data for this time period" : "");
    // partial period - see bar
    mySvg.select('.partialPeriodLine' + myClass)
      .attr("visibility", myChart.props.partialPeriod === true ? "visible" : "hidden")
      .attr("x1", width - margins.right)
      .attr("x2", width - margins.right)
      .attr("y1", margins.top)
      .attr("y2", height - margins.bottom - margins.brush)
      .attr('stroke', '#1363DF')
      .attr('stroke-width', 1)
      .attr('stroke-dasharray','2,2');

    mySvg.select('.partialPeriodLineLabel' + myClass)
      .attr("visibility", myChart.props.partialPeriod === true ? "visible" : "hidden")
      .attr("transform", "translate(" + (width - margins.right + 15) + "," +
        (margins.top - 15) + ")")
      .attr("text-anchor", "end")
      .attr("dy", 0)
      .attr('font-size', '8px')
      .attr('font-family', 'Poppins')
      .attr('font-weight', '400')
      .attr('fill', '#1363DF')
      .text('Partial period')
      .call(wrap, 35);

    // this is the mouseover line, only visible at mouseover, setting key properties
    mySvg.select('.dotLine' + myClass)
      .attr('visibility', 'hidden')
      .attr('pointer-events', 'none')
      .attr('stroke', '#A0A0A0')
      .attr('fill', '#A0A0A0')
      .attr('stroke-width', 3)
      .attr('y1', 0)
      .attr('y2', height - margins.top - margins.bottom)
      .attr('transform',  'translate(' + margins.left + ',' + margins.top + ')');
    // same logic - see bar chart
    let startingVisibleRows: any = parseInt(String((width - margins.left - margins.right) / 25));
    startingVisibleRows = startingVisibleRows > dateSet.length ? dateSet.length : startingVisibleRows;
    const brushStartX = dateSet.length === 0 ? 0 : (1-(startingVisibleRows/dateSet.length)) * (width - margins.left - margins.right);
    // brush - no brush
    const brush: any = d3.brushX()
      .handleSize(10)
      .extent([[0, 0], [width - margins.left - margins.right, margins.brush]])
      .on('start brush end', brushed);
    // axis labels
    mySvg.select('#yAxisLeftLabel' + myClass)
      .attr('visibility', chartData.lineData.length === 0 && chartData.barData.length === 0 ? 'hidden' : 'visible')
      .attr('transform','translate(' + (margins.left) + ',' + (margins.top-20) + ')')
      .attr('text-anchor', 'end')
      .attr('font-family', 'Poppins')
      .attr('fill', '#737D88')
      .attr('font-size', '8px')
      .text(myChart.props.yAxisLabelLeft)
      .call(wrap, 35);

    if(myChart.props.yAxisRight)
      {mySvg.select('#yAxisRightLabel' + myClass)
      .attr('visibility', chartData.lineData.length === 0 && chartData.barData.length === 0 ? 'hidden' : 'visible')
      .attr('transform','translate(' + (width - (margins.right)) + ',' + (margins.top - 12) + ')')
      .attr('text-anchor', 'start')
      .attr('font-family', 'Poppins')
      .attr('fill', '#737D88')
      .attr('font-size', '8px')
      .text(myChart.props.yAxisLabelRight)
      .call(wrap, 35);}
    // define clip rect dimensions
    mySvg.select('#alcBrushClipRect' + myClass)
      .style('width', width -  margins.right - margins.left)
      .style('height', height - margins.brush - 5)
      .attr('transform', 'translate(' + margins.left + ',-5)');

    if(myChart.props.showBrush === true){
      // if brush, call and set handle lines and selection styles
      mySvg.select('#brushGroup' + myClass)
        .attr('transform', 'translate(' + margins.left + ',' + (height - margins.brush - 10) + ')')
        .call(brush)
        .call(brush.move, [brushStartX, width - margins.left - margins.right]);

      mySvg.selectAll('.handleLines' + myClass)
        .attr('visibility', chartData.lineData.length === 0 && chartData.barData.length === 0 ? 'hidden' : 'visible')
        .attr('y1', height - margins.brush - 10 + (margins.brush - 12) / 2)
        .attr('y2', height - margins.brush - 10 + 12 + (margins.brush - 12) / 2)
        .style('stroke', '#8A98AB')
        .style('stroke-width', '1')
        .attr('transform', 'translate(' + margins.left + ',0)');

      mySvg.selectAll('#brushGroup' + myClass)
        .selectAll('.selection')
        .attr('fill', '#A0A0A0')
        .attr('fill-opacity', '0.2')
        .style('stroke', '#E8EAEE')
        .style('stroke-width', '1');
    } else {
      // or  clear brush, handle lines and brush line
      mySvg.select('#brushGroup' + myClass).html("")

      mySvg.selectAll('.handleLines' + myClass)
        .style('stroke-width', '0')

      mySvg.select('#brushLinePath' + myClass)
        .attr('d',"")
        .attr('fill', 'transparent')
    }
    // set y axes
    mySvg.select('#yAxis' + myClass)
      // @ts-ignore
      .call(d3.axisLeft(yScale).tickSizeOuter(0).ticks(3, myChart.props.yAxisFormat))
      .attr('transform', 'translate(' + margins.left + ',' + margins.top + ')');

    mySvg.selectAll('#yAxis' + myClass + ' line')
      .attr('x1', '0')
      .attr('x2', width - margins.right - margins.left)
      .style('stroke', '#F0F3F6')
      .style('stroke-width', 1);

   if(myChart.props.yAxisRight){ mySvg.select('#yAxisRight' + myClass)
      // @ts-ignore
      .call(d3.axisRight(yScaleRight).tickSizeOuter(0).ticks(3, myChart.props.yAxisFormat))
      .attr('transform', 'translate(' + (width - margins.right) + ',' + margins.top + ')');

    mySvg.selectAll('#yAxisRight' + myClass + ' line')
      .style('display', 'none');
    // set formatting not to show 0
    mySvg.selectAll('#yAxisRight' + myClass + ' text')
      .text((d: any) => d3.format(myChart.props.yAxisFormat)(d));
      // .text((d: any) => d === 0 ? '': d3.format(myChart.props.yAxisFormat)(d));
      }

    mySvg.selectAll('#yAxis' + myClass + ' text')
      .text((d: any) => d3.format(myChart.props.yAxisFormat)(d));
      // .text((d: any) => d === 0 ? '': d3.format(myChart.props.yAxisFormat)(d));
    // draw brush chart (this is more standard to draw outside as does not reset
    const brushChartGroup = mySvg
      .select('.brushAreaGroup' + myClass)
      .selectAll('.brushCGroup' + myClass)
      .data(myChart.props.showBrush === true ? areaSeries : [])
      .join(function(group: any): any {
        if(myChart.props.areaPlot){
        const enter = group.append('g').attr('class', 'brushCGroup' + myClass);
        enter.append('path').attr('class', 'brushArea');
        return enter;}
      });

    brushChartGroup.select('.brushArea')
      .attr('d', areaBrush)
      .attr('fill', (d: any) => myChart.props.colors[myChart.props.errorTypeLabels[d.key]])
      .attr('transform', 'translate(' + margins.left + ',' + (height - margins.brush - 10) + ')');
    // draw brush line
    const brushLineGroup = mySvg
      .select('.brushLineGroup' + myClass)
      .selectAll('.brushLineGroup' + myClass)
      .data(myChart.props.showBrush === true ? lineDataset : [])
      .join(function(group: any): any {
        if(myChart.props.linePlot){
        const enter = group.append('g').attr('class', 'brushLineGroup' + myClass);
        enter.append('path').attr('class', 'brushLine');
        return enter;}
      });

    brushLineGroup.select('.brushLine')
      .attr('d', lineBrush)
      .attr("fill", "transparent")
      .attr('stroke', (d: any) =>  d.length === 0 ? "transparent" : myChart.props.colors[myChart.props.errorTypeLabels[d[0].type]])
      .attr('transform', 'translate(' + margins.left + ',' + (height - margins.brush - 10) + ')');
    // this is the legend, works the same in line chart and combo bar
    // see line chart for comments
    let currentLegendItems: any = Object.keys(myChart.props.colors);
    const legendWidth = d3.sum(currentLegendItems, (s: any) => measureWidth(s.toUpperCase(), 12)  + 40)
    let legendIndexLeft = 0;
    let legendIndexRight = currentLegendItems.length;
    mySvg.selectAll('.legendArrowLeftItem' + myClass).attr('visibility', 'hidden');
    if (legendWidth < (width - 60)){
      mySvg.selectAll('.legendArrowRightItem' + myClass).attr('visibility', 'hidden');
    } else {
      currentLegendItems = [];
      let currentWidth = 0;
      Object.keys(myChart.props.colors).forEach((d: any, i: any) => {
        currentWidth += (measureWidth(d.toUpperCase(), 12) + 40);
        if (currentWidth < (width - 60)){
          legendIndexRight = i;
          currentLegendItems.push(d);
        }
      });
    }
    // still legend - see line chart
    mySvg.select('.legendArrowCircleLeft' + myClass)
      .attr('r', 10)
      .attr('fill', 'transparent')
      .attr('stroke', '#E8EAEE')
      .attr('cursor', 'pointer')
      .attr('cx', 21)
      .attr('cy', 35)
      .on('click', () => {
        legendIndexLeft -= 1;
        legendIndexRight -= 1;
        const myFilteredLegendItems = JSON.parse(JSON.stringify(Object.keys(myChart.props.colors)));
        currentLegendItems = myFilteredLegendItems.filter((f: any, i: any) => (i >= legendIndexLeft && i <= legendIndexRight));
        drawLegend(currentLegendItems);
        mySvg.selectAll('.legendArrowRightItem' + myClass).attr('visibility', 'visible');
        if (legendIndexLeft === 0){
          mySvg.selectAll('.legendArrowLeftItem' + myClass).attr('visibility', 'hidden');
        }
      });
    // still legend - see line chart
    mySvg.select('.legendArrowLeft' + myClass)
      .attr('fill', '#1363DF')
      .attr('font-weight', 'bold')
      .attr('font-size', '16')
      .attr('text-anchor', 'middle')
      .attr('pointer-events', 'none')
      .attr('x', 21)
      .attr('y', 35 + 5.5)
      .text('<');
    // still legend - see line chart
    mySvg.select('.legendArrowCircleRight' + myClass)
      .attr('r', 10)
      .attr('fill', 'transparent')
      .attr('stroke', '#E8EAEE')
      .attr('cursor', 'pointer')
      .attr('cx', width - 21)
      .attr('cy', 35)
      .on('click', () => {
        legendIndexLeft += 1;
        legendIndexRight += 1;
        const myFilteredLegendItems = JSON.parse(JSON.stringify(Object.keys(myChart.props.colors)));
        currentLegendItems = myFilteredLegendItems.filter((f: any, i: any) => (i >= legendIndexLeft && i <= legendIndexRight));
        drawLegend(currentLegendItems);
        mySvg.selectAll('.legendArrowLeftItem' + myClass).attr('visibility', 'visible');
        if (legendIndexRight === (Object.keys(myChart.props.colors).length - 1)){
          mySvg.selectAll('.legendArrowRightItem' + myClass).attr('visibility', 'hidden');
        }
      });
    // still legend - see line chart
    mySvg.select('.legendArrowRight' + myClass)
      .attr('fill', '#1363DF')
      .attr('font-weight', 'bold')
      .attr('font-size', '16')
      .attr('text-anchor', 'middle')
      .attr('pointer-events', 'none')
      .attr('x', width - 21)
      .attr('y', 35 + 5.5)
      .text('>');

    drawLegend(currentLegendItems)
    drawChart();



    function drawChart(){
      // selection only visible if data - soon to be redundant
      mySvg.selectAll('.selection').attr('visibility', chartData.lineData.length === 0 && chartData.barData.length === 0 ? 'hidden' : 'visible')
      // redraw x axis and set styling

      mySvg.selectAll('#xAxis' + myClass + ' line')
        .attr('display', 'none');

      mySvg.selectAll('.axis' + myClass)
        .attr('visibility', chartData.lineData.length === 0 && chartData.barData.length === 0 ? 'hidden' : 'visible');

      mySvg.selectAll('.axis' + myClass + ' path')
       .style('stroke', '#E8EAEE');

      mySvg.selectAll('.axis' + myClass + ' text')
        .style('font-family', 'Poppins')
        .style('font-weight', 500)
        .style('font-size', 10);
      // depending on settings and axis text width, may go diagonal
      // same functionality in all 4 charts (combo, bar, line, arealine)
      // see bar chart for detailed explanation
      let ticksArray = tickValues === null ? xScale.ticks() : tickValues;
      ticksArray = ticksArray.sort((a: any, b: any) => d3.ascending(a,b));
      const xBandwidth: any = d3.min(ticksArray, (m: any, i: any) => i === ticksArray.length - 1
        ? width - margins.right - margins.left :  xScale(ticksArray[i+1]) - xScale(m) )
      const maxTextWidth = getMaxTextWidth();

      if (maxTextWidth > (xBandwidth - 5)) {
        let myFontSize = xBandwidth < myChart.props.axisFontSize + 2 ? xBandwidth - 2 : myChart.props.axisFontSize;

        mySvg.selectAll('#xAxis' + myClass + ' text')
          .style('font-family', 'Poppins')
          .style("text-anchor", "end")
          .style('font-size', myFontSize)
          //@ts-ignore
          .attr("dy",  3)
          //@ts-ignore
          .attr("transform", "rotate(-45)");
      } else {
        mySvg.selectAll('#xAxis' + myClass + ' text')
          .style('font-family', 'Poppins')
          .style("text-anchor", "middle")
          //@ts-ignore
          .attr("dy", 3)
          //@ts-ignore
          .attr("transform", "rotate(0)");
      }

      function getMaxTextWidth() {
        let maxTextWidth = 0;
        mySvg.selectAll('#xAxis' + myClass + ' text').each(function () {
          //@ts-ignore
          const myNode = d3.select(this).node().getBBox();
          maxTextWidth = Math.max(maxTextWidth, myNode.width)
        })
        return maxTextWidth
      }
      // bind data
      // area group
      const areaGroup = mySvg
        .select('.areaGroup' + myClass)
        .selectAll('.areaPathGroup' + myClass)
        .data(areaSeries)
        .join(function(group: any): any {
          if(myChart.props.areaPlot){
          const enter = group.append('g').attr('class', 'areaPathGroup' + myClass);
          enter.append('path').attr('class', 'areaPath');
          return enter;}
        });

      areaGroup.attr('clip-path', 'url(#alcBrushClip' + myClass + ')');

      areaGroup.select('.areaPath')
        .attr('id', (d: any, i: any) => "area" + i)
        .attr('d', area)
        .attr('fill', (d: any) => myChart.props.colors[myChart.props.errorTypeLabels[d.key]])
        .attr('transform', 'translate(' + margins.left + ',' + margins.top + ')')
        .on("mousemove", function (event: any, d: any) {
          // mousemove logic repeated throughout too.
          // get position date by inverting scale
          const positionDate = xScale.invert(event.offsetX - margins.left);
          // then look at data and get closest date
          let closest: any = dateSet.findIndex((f: any) => new Date(f) >= positionDate);
          // if it is more than 0, work out whether we are more than half way from left -> right
          // move left if not more than half way through
          if(closest > 0){
            const leftMinutes = d3.timeMinute.count(dateSet[closest - 1], positionDate);
            const rightMinutes = Math.abs(d3.timeMinute.count(dateSet[closest], positionDate));
            if(leftMinutes < rightMinutes){
              closest -= 1;
            }
          } else if(closest === -1){
            // if closest does not exist, set to last
            closest = dateSet.length - 1;
          }
          // set position and visibility of dot line
          mySvg.select('.dotLine' + myClass)
            .attr('visibility', 'visible')
            .attr('x1', xScale(dateSet[closest]))
            .attr('x2', xScale(dateSet[closest]));
          // THIS IS AN AREA MOUSEOVER
          // all lines go low opacity
          mySvg.selectAll('.linePath').attr("opacity", 0.2);
          // hiding all data dots
          mySvg.selectAll(".dataDot").attr("visibility", "hidden")
          // hiding lines on brush
          mySvg.selectAll('.brushLine').attr('visibility', 'hidden');
          // showing AREA data dots for this date
          mySvg.selectAll("#areaDot" + closest)
            .interrupt()
            .attr('visibility', 'visible');
          // AREA AXIS is RIGHT HAND SIDE SO
          // make left hand axis LESS prominent
          mySvg.select('#yAxis' + myClass).selectAll("text").style("fill", "#D0D0D0");
          mySvg.select('#yAxisLeftLabel' + myClass).style("font-size", 8).style("font-weight", "normal").style("fill", " #D0D0D0")
          // make right hand axis MORE prominent
          mySvg.select('#yAxisRightLabel' + myClass).style("font-size", 10).style("font-weight", "bold").style("fill", "black");
          mySvg.select('#yAxisRight' + myClass).selectAll("text").style("font-size", 11).style("font-weight", "bold").style("fill", "black");
          mySvg.select('#yAxisRight' + myClass).selectAll("path").style("stroke-width", '3');
          // show tooltip
          tooltipData[String(dateSet[closest])].selected = d.key;
          if(myChart.props.areaPlot){
          myChart.showTooltip('area', tooltipData[String(dateSet[closest])], event.offsetX, event.offsetY,width,height);}
        })
        .on('mouseout', () => {resetAxisLabels()});

      // separate group for area dots so always above dot line
      const areaDotsGroup = mySvg
        .select('.areaDotsGroup' + myClass)
        .selectAll('.areaDotsGroupGroup' + myClass)
        .data(areaSeries)
        .join(function(group: any): any {
          const enter = group.append('g').attr('class', 'areaDotsGroupGroup' + myClass);
          enter.append("g").attr("class", 'areaDotGroup')
          return enter;
        });
      // set clip
      areaDotsGroup.attr('clip-path', 'url(#alcBrushClip' + myClass + ')');
      // transform group
      areaDotsGroup.select('.areaDotGroup')
        .attr('transform',  'translate(' + margins.left + ',' + margins.top + ')');

      const areaDotGroup =  areaDotsGroup.select('.areaDotGroup')
        .selectAll('.areaDotGroup' + myClass)
        .data((d: any) =>  {
          const myData = d;
          // map fill and dateIndex (for id)
          myData.map((m: any) => m.fill = myChart.props.colors[myChart.props.errorTypeLabels[d.key]])
          myData.map((m: any) => m.dateIndex = dateSet.findIndex((f: any) => String(f) === String(m.data.date)))
          return myData;
        })
        .join((group: any): any => {
          const enter = group.append('g').attr('class', 'areaDotGroup' + myClass);
          enter.append('circle').attr('class', 'dataDot');
          return enter;
        });
      // area dot style
      areaDotGroup.select('.dataDot')
        .attr('id', (d: any) => 'areaDot' + d.dateIndex)
        .attr('visibility', 'hidden')
        .attr('r', 4)
        .attr('stroke-width', 2)
        .attr('stroke', 'white')
        .attr('pointer-events', 'none')
        .attr('fill', (d: any) => d.fill)
        .attr('cx', (d: any) => xScale(d.data.date))
        .attr('cy', (d: any) => yScale(d[1]));

      // now repeating the process for line group
      // console.log('lineDataset',lineDataset);
      
      const lineGroup = mySvg
        .select('.lineGroup' + myClass)
        .selectAll('.linePathGroup' + myClass)
        .data(lineDataset)
        .join(function(group: any): any {
          if(myChart.props.linePlot){
          const enter = group.append('g').attr('class', 'linePathGroup' + myClass);
          enter.append('path').attr('class', 'linePath');
          return enter;}
        });

      lineGroup.select('.linePath')
        .attr('id', (d: any, i: any) => "line" + i)
        .attr('d', line)
        .attr('stroke', (d: any) => d[0] === undefined ? 0 : myChart.props.colors[ myChart.props.errorTypeLabels[d[0].type]])
        .attr('stroke-width', 4)
        .attr('fill','none')
        .attr('transform', 'translate(' + margins.left + ',' + margins.top + ')')
        .on("mousemove", function (event: any, d: any) {
          // as above for area, calculate closest - see above for more detail
          const positionDate = xScale.invert(event.offsetX - margins.left);
          let closest: any = dateSet.findIndex((f: any) => new Date(f) >= positionDate);
          if(closest > 0){
            const leftMinutes = d3.timeMinute.count(dateSet[closest - 1], positionDate);
            const rightMinutes = Math.abs(d3.timeMinute.count(dateSet[closest], positionDate));
            if(leftMinutes < rightMinutes){
              closest -= 1;
            }
          } else if(closest === -1){
            closest = dateSet.length - 1;
          }
          // LINE MOUSEOVER
          // fade out area + hide area on brush
          mySvg.selectAll('.areaPath').attr("opacity", 0.2);
          mySvg.selectAll('.brushArea').attr('visibility', 'hidden')
          // show dot line
          mySvg.select('.dotLine' + myClass)
            .attr('visibility', 'visible')
            .attr('x1', xScale(dateSet[closest]))
            .attr('x2', xScale(dateSet[closest]));
          // hide all data dots
          mySvg.selectAll(".dataDot").attr("visibility", "hidden")
          // show line dots for this data point
          mySvg.selectAll("#lineDot" + closest)
            .interrupt()
            .attr('visibility', 'visible');
          // line axis is LEFT HAND SIDE
          // make right axis LESS prominent
          mySvg.select('#yAxisRightLabel' + myClass).style("font-size", 8).style("font-weight", "normal").style("fill", " #D0D0D0");
          mySvg.select('#yAxisRight' + myClass).selectAll("text").style("fill", '#D0D0D0');
          // make left axis MORE prominent
          mySvg.select('#yAxisLeftLabel' + myClass).style("font-size", 10).style("font-weight", "bold").style("fill", "black");
          mySvg.select('#yAxis' + myClass).selectAll("text").style("font-size", 11).style("font-weight", "bold").style("fill", "black");
          mySvg.select('#yAxis' + myClass).selectAll("path").style("stroke-width", '3');
          // show tooltip
          tooltipData[String(dateSet[closest])].selected = d[0].type;
          if(myChart.props.linePlot){
          myChart.showTooltip('line', tooltipData[String(dateSet[closest])], event.offsetX, event.offsetY,width,height);}
        })
        .on('mouseout', () => {resetAxisLabels()});

      lineGroup.attr('clip-path', 'url(#alcBrushClip' + myClass + ')');

      // as above, separate group for line dots so above dot line
      const lineDotsGroup = mySvg
        .select('.lineDotsGroup' + myClass)
        .selectAll('.lineDotsGroupGroup' + myClass)
        .data(lineDataset)
        .join(function(group: any): any {
          const enter = group.append('g').attr('class', 'lineDotsGroupGroup' + myClass);
          enter.append("g").attr("class", 'lineDotGroup')
          return enter;
        });

      lineDotsGroup.attr('clip-path', 'url(#alcBrushClip' + myClass + ')');

      lineDotsGroup.select('.lineDotGroup')
        .attr('transform',  'translate(' + margins.left + ',' + margins.top + ')');

      const lineDotGroup =  lineDotsGroup.select('.lineDotGroup')
        .selectAll('.lineDotGroup' + myClass)
        .data((d: any) =>  {
          const myData = d;
          // same as above for area
          myData.map((m: any) => m.fill = myChart.props.colors[ myChart.props.errorTypeLabels[m.type]])
          myData.map((m: any) => m.dateIndex = dateSet.findIndex((f: any) => String(f) === String(m.date)))
          return myData;
        })
        .join((group: any): any => {
          const enter = group.append('g').attr('class', 'lineDotGroup' + myClass);
          enter.append('circle').attr('class', 'dataDot');
          return enter;
        });

      lineDotGroup.select('.dataDot')
        .attr('id', (d: any) => 'lineDot' + d.dateIndex)
        .attr('visibility', 'hidden')
        .attr('r', 4)
        .attr('stroke-width', 2)
        .attr('stroke', 'white')
        .attr('pointer-events', 'none')
        .attr('fill', (d: any) => d.fill)
        .attr('cx', (d: any) => xScale(d.date))
        .attr('cy', (d: any) => yScaleRight(d.value));
    }

    function drawLegend(myLegendItems: any): void {
      // draw legend group
      const legendGroup = mySvg
        .select('#legendGroup' + myClass)
        .attr('visibility', chartData.lineData.length === 0 && chartData.barData.length === 0 ? 'hidden' : 'visible')
        .selectAll('.legendGroup' + myClass)
        .data(myLegendItems)
        .join((group: any) => {
          const enter = group.append('g').attr('class', 'legendGroup' + myClass);
          enter.append('circle').attr('class', 'legendCircle');
          enter.append('text').attr('class', 'legendLabel' + myClass);
          return enter;
        });

      legendGroup.select('.legendCircle')
        .attr('id', (d: any) => 'circle_legendItem' + d.replace(/ /g, '') + myClass)
        .attr('cy', 35)
        .attr('fill', (d: any) => myChart.props.colors[d])
        .attr('r', 4);

      legendGroup.select('.legendLabel' + myClass)
        .attr('id', (d: any) => 'legendItem' + d.replace(/ /g, '') + myClass)
        .attr('y', 35 + 5)
        .attr('fill', '#101D42')
        .attr('font-weight', '500')
        .attr('font-size', '12')
        .attr('font-family', 'Poppins')
        .attr('cursor',  'pointer')
        .text((d: any) => d.toUpperCase())

      // legend functionality repeated through the project
      // legendX is the starting x position
      let legendX = 30;
      mySvg.selectAll('.legendLabel' + myClass).each(function(d: any): any {
        //@ts-ignore
        const myObject = this;
        // set this x and circle x
        d3.select(myObject).attr('x', legendX);
        mySvg.select('#circle_' + myObject.id).attr('cx', legendX - 8 - 6);
        // calculate the width of the label and reset the legendX for next item
        legendX += (40 + measureWidth(d.toUpperCase(),12));
      });
      // centre legend across screen
      legendGroup.attr('transform', 'translate(' + ((width - legendX + 24) / 2) + ',0)');
    }

    function resetAxisLabels(){
      // resetting all relevant parts to original values
      mySvg.select('.dotLine' + myClass).attr('visibility', 'hidden');
      mySvg.selectAll('.brushArea').attr('visibility', 'visible');
      mySvg.selectAll('.brushLine').attr('visibility', 'visible');
      mySvg.selectAll('.areaPath').attr("opacity", 1);
      mySvg.selectAll('.linePath').attr("opacity", 1);
      mySvg.selectAll(".dataDot").attr("visibility", "hidden");
      mySvg.select('#yAxisRightLabel' + myClass).style("font-size", 8).style("font-weight", "normal").style("fill", " #737D88");
      mySvg.select('#yAxisLeftLabel' + myClass).style("font-size", 8).style("font-weight", "normal").style("fill", " #737D88");
      mySvg.select('#yAxis' + myClass).selectAll("text").style("font-size", 10).style("font-weight", "normal").style("fill", " #737D88");
      mySvg.select('#yAxis' + myClass).selectAll("path").style("stroke-width", '1');
      mySvg.select('#yAxisRight' + myClass).selectAll("text").style("font-size", 10).style("font-weight", "normal").style("fill", " #737D88");
      mySvg.select('#yAxisRight' + myClass).selectAll("path").style("stroke-width", '1');
      // hide tooltip just in case visible
      myChart.hideTooltip('area')
    }
    function brushed(event: any): void {

      const selection = event.selection;
      if (selection !== null) {
        // hide tooltip and mouseover line
        myChart.hideTooltip('area')
        mySvg.select('.dotLine' + myClass).attr('visibility', 'hidden');
        // reset xDomain based on selection
        const xDomain: any = selection.map(xScaleAll.invert);
        xScale.domain(xDomain);
        mySvg.select('#xAxis' + myClass)
          .call(d3.axisBottom(xScale).tickSizeOuter(0).tickValues(  tickValues === null ? null : tickValues.filter((f: any) => f >= xDomain[0] && f <= xDomain[1])).ticks(tickCount).tickFormat(xTickFormat))
      .attr('transform', 'translate(' + margins.left + ',' + (height - margins.bottom) + ')');

        // draw chart
        drawChart();
        // set partial period (only visible if last date visible)
        if(String(xDomain[1]) === String(xScaleAll.domain()[1])){
          mySvg.select('.partialPeriodLine' + myClass)
            .attr("visibility", myChart.props.partialPeriod === true ? "visible" : "hidden")
          mySvg.select('.partialPeriodLineLabel' + myClass)
            .attr("visibility", myChart.props.partialPeriod === true ? "visible" : "hidden")
        } else {
          mySvg.select('.partialPeriodLine' + myClass)
            .attr("visibility",  "hidden")
          mySvg.select('.partialPeriodLineLabel' + myClass)
            .attr("visibility","hidden")
        }
        // set handle line positions (sometimes these are in drawChart, sometimes in brush
        // both called anyway
        // logic the same in all charts with a brush
        // get x position of handles and lines (I think all)
        const handleEastX = +mySvg.select('#brushGroup' + myClass).select('.handle--e').attr('x');
        const handleWestX = +mySvg.select('#brushGroup' + myClass).select('.handle--w').attr('x');
        // reposition handle lines
        mySvg.select('.handleLeftLine1' + myClass)
          .attr('x1', handleEastX + 3)
          .attr('x2', handleEastX + 3);

        mySvg.select('.handleLeftLine2' + myClass)
          .attr('x1', handleEastX + 7)
          .attr('x2', handleEastX + 7);

        mySvg.select('.handleRightLine1' + myClass)
          .attr('x1', handleWestX + 3)
          .attr('x2', handleWestX + 3);

        mySvg.select('.handleRightLine2' + myClass)
          .attr('x1', handleWestX + 7)
          .attr('x2', handleWestX + 7);
        // reset handle appearance
        mySvg.select('#brushGroup' + myClass)
          .selectAll('.handle')
          .attr('fill', 'white')
          .attr('rx', 4)
          .attr('ry', 4)
          .attr('y', (margins.brush - 24) / 2)
          .attr('height', 24)
          .style('filter', 'url(#drop-shadow)');
      }
    }
    // wrap and measureWidth repeated a lot - you might want to store elsewhere
    function measureWidth(myText: any, myFontSize: any): any {
      const context: any = document.createElement('canvas').getContext('2d');
      context.font = myFontSize + 'px sans-serif';
      return context.measureText(myText).width;
    }
    function wrap(text: any, width: any): void {
      text.selectAll('tspan').remove();
      let lineNumber = 0;
      const originalValue = text.text();
      text.each(function(): any {
        // @ts-ignore
        const text = d3.select(this);
        const words = text.text().split(/\s+/).reverse();
        let word = null;
        let line: any = [];
        const lineHeight = 1.1; // ems
        const dy = 0;
        let tspan = text.text(null).append('tspan').attr('x', 0).attr('y', 0).attr('dy', dy + 'em');
        while (word = words.pop()) {
          line.push(word);
          tspan.text(line.join(' '));
          // @ts-ignore
          if (tspan.node().getComputedTextLength() > width) {
            line.pop();
            tspan.text(line.join(' '));
            line = [word];
            if (word.trim().length > 0){
              tspan = text.append('tspan').attr('x', 0).attr('y', 0).attr('dy', ++lineNumber * lineHeight + dy + 'em').text(word);

            }
          }
        }
      });
      if (lineNumber > 1){
        text.selectAll('tspan').remove();
        text.attr('id', originalValue)
        text.text(originalValue.substr(0, 5) + '..');
      }
    }
    this.wait(100).then(() => this.isLoading = false);
  }

  // property & data for the chart
  getAreaLineComboData() {
    this.areaLineData={
      "lineData": [
        {
        "error_type": "Amber",
        "period": "2022-08-01T00:00:00",
        "no_of_days": 600.0
      },
      {
        "error_type": "Amber",
        "period": "2022-08-02T00:00:00",
        "no_of_days": 600.0
      },
      {
        "error_type": "Amber",
        "period": "2022-08-03T00:00:00",
        "no_of_days": 2100.0
      },
      {
        "error_type": "Amber",
        "period": "2022-08-04T00:00:00",
        "no_of_days": 3600.0
      },
      {
        "error_type": "Amber",
        "period": "2022-08-05T00:00:00",
        "no_of_days": 300.0
      },
      {
        "error_type": "Amber",
        "period": "2022-08-06T00:00:00",
        "no_of_days": 1200.0
      },
      {
        "error_type": "Amber",
        "period": "2022-08-07T00:00:00",
        "no_of_days": 600.0
      },
      {
        "error_type": "Amber",
        "period": "2022-08-08T00:00:00",
        "no_of_days": 600.0
      },
      {
        "error_type": "Amber",
        "period": "2022-08-09T00:00:00",
        "no_of_days": 600.0
      },
      {
        "error_type": "Amber",
        "period": "2022-08-10T00:00:00",
        "no_of_days": 6000.0
      }, {
        "error_type": "Amber",
        "period": "2022-08-11T00:00:00",
        "no_of_days": 3460.0
      }, {
        "error_type": "Amber",
        "period": "2022-08-12T00:00:00",
        "no_of_days": 6150.0
      },
      {
        "error_type": "Amber",
        "period": "2022-08-13T00:00:00",
        "no_of_days": 1260.0
      },
      {
        "error_type": "Biologics",
        "period": "2022-08-01T00:00:00",
        "no_of_days": 1920.0
      },
      {
        "error_type": "Biologics",
        "period": "2022-08-02T00:00:00",
        "no_of_days": 2280.0
      },
      {
        "error_type": "Biologics",
        "period": "2022-08-03T00:00:00",
        "no_of_days": 3030.0
      },
      {
        "error_type": "Biologics",
        "period": "2022-08-04T00:00:00",
        "no_of_days": 2910.0
      },
      {
        "error_type": "Biologics",
        "period": "2022-08-05T00:00:00",
        "no_of_days": 3630.0
      },
      {
        "error_type": "Biologics",
        "period": "2022-08-06T00:00:00",
        "no_of_days": 4290.0
      },
      {
        "error_type": "Biologics",
        "period": "2022-08-07T00:00:00",
        "no_of_days": 3960.0
      },
      {
        "error_type": "Biologics",
        "period": "2022-08-08T00:00:00",
        "no_of_days": 3810.0
      },
      {
        "error_type": "Biologics",
        "period": "2022-08-09T00:00:00",
        "no_of_days": 4050.0
      },
      {
        "error_type": "Biologics",
        "period": "2022-08-10T00:00:00",
        "no_of_days": 3990.0
      },
      {
        "error_type": "Biologics",
        "period": "2022-08-11T00:00:00",
        "no_of_days": 4500.0
      },
      {
        "error_type": "Biologics",
        "period": "2022-08-12T00:00:00",
        "no_of_days": 2910.0
      },
      {
        "error_type": "Biologics",
        "period": "2022-08-13T00:00:00",
        "no_of_days": 1230.0
      },
      {
        "error_type": "Onco360",
        "period": "2022-08-01T00:00:00",
        "no_of_days": 60.0
      },
      {
        "error_type": "Onco360",
        "period": "2022-08-02T00:00:00",
        "no_of_days": 120.0
      },
      {
        "error_type": "Onco360",
        "period": "2022-08-03T00:00:00",
        "no_of_days": 180.0
      },
      {
        "error_type": "Onco360",
        "period": "2022-08-04T00:00:00",
        "no_of_days": 240.0
      },
      {
        "error_type": "Onco360",
        "period": "2022-08-05T00:00:00",
        "no_of_days": 300.0
      },
      {
        "error_type": "Onco360",
        "period": "2022-08-06T00:00:00",
        "no_of_days": 3601.0
      },
      {
        "error_type": "Onco360",
        "period": "2022-08-07T00:00:00",
        "no_of_days": 4120.0
      },
      {
        "error_type": "Onco360",
        "period": "2022-08-08T00:00:00",
        "no_of_days": 4180.0
      },
      {
        "error_type": "Onco360",
        "period": "2022-08-09T00:00:00",
        "no_of_days": 1540.0
      },
      {
        "error_type": "Onco360",
        "period": "2022-08-10T00:00:00",
        "no_of_days": 6100.0
      },
      {
        "error_type": "Onco360",
        "period": "2022-08-11T00:00:00",
        "no_of_days": 4810.0
      },
      {
        "error_type": "Onco360",
        "period": "2022-08-12T00:00:00",
        "no_of_days": 1540.0
      },
      {
        "error_type": "Onco360",
        "period": "2022-08-13T00:00:00",
        "no_of_days": 1600.0
      },
      {
        "error_type": "RxCrossroads",
        "period": "2022-08-01T00:00:00",
        "no_of_days": 1500.0
      },
      {
        "error_type": "RxCrossroads",
        "period": "2022-08-02T00:00:00",
        "no_of_days": 2000.0
      },
      {
        "error_type": "RxCrossroads",
        "period": "2022-08-03T00:00:00",
        "no_of_days": 2050.0
      },
      {
        "error_type": "RxCrossroads",
        "period": "2022-08-04T00:00:00",
        "no_of_days": 3000.0
      },
      {
        "error_type": "RxCrossroads",
        "period": "2022-08-05T00:00:00",
        "no_of_days": 3050.0
      },
      {
        "error_type": "RxCrossroads",
        "period": "2022-08-06T00:00:00",
        "no_of_days": 1400.0
      },
      {
        "error_type": "RxCrossroads",
        "period": "2022-08-07T00:00:00",
        "no_of_days": 1450.0
      },
      {
        "error_type": "RxCrossroads",
        "period": "2022-08-08T00:00:00",
        "no_of_days": 1500.0
      },
      {
        "error_type": "RxCrossroads",
        "period": "2022-08-09T00:00:00",
        "no_of_days": 5501.0
      },
      {
        "error_type": "RxCrossroads",
        "period": "2022-08-10T00:00:00",
        "no_of_days": 1600.0
      }, 
      {
        "error_type": "RxCrossroads",
        "period": "2022-08-11T00:00:00",
        "no_of_days": 1600.0
      }, {
        "error_type": "RxCrossroads",
        "period": "2022-08-12T00:00:00",
        "no_of_days": 1600.0
      },{
        "error_type": "RxCrossroads",
        "period": "2022-08-13T00:00:00",
        "no_of_days": 2600.0
      }
    ],
    "barData": [
      {
        "error_type": "Network Average",
        "period": "2022-08-01T00:00:00",
        "defect_count": 500.0
      },
      {
        "error_type": "Network Average",
        "period": "2022-08-02T00:00:00",
        "defect_count": 3000.0
      },
      {
        "error_type": "Network Average",
        "period": "2022-08-03T00:00:00",
        "defect_count": 2050.0
      },
      {
        "error_type": "Network Average",
        "period": "2022-08-04T00:00:00",
        "defect_count": 4000.0
      },
      {
        "error_type": "Network Average",
        "period": "2022-08-05T00:00:00",
        "defect_count": 5050.0
      },
      {
        "error_type": "Network Average",
        "period": "2022-08-06T00:00:00",
        "defect_count": 1000.0
      },
      {
        "error_type": "Network Average",
        "period": "2022-08-07T00:00:00",
        "defect_count": 4050.0
      },
      {
        "error_type": "Network Average",
        "period": "2022-08-08T00:00:00",
        "defect_count": 6000.0
      },
      {
        "error_type": "Network Average",
        "period": "2022-08-09T00:00:00",
        "defect_count": 5500.0
      },
      {
        "error_type": "Network Average",
        "period": "2022-08-10T00:00:00",
        "defect_count": 800.0
      }, {
        "error_type": "Network Average",
        "period": "2022-08-11T00:00:00",
        "defect_count": 5000.0
      },
      {
        "error_type": "Network Average",
        "period": "2022-08-12T00:00:00",
        "defect_count": 2010.0
      },
      {
        "error_type": "Network Average",
        "period": "2022-08-13T00:00:00",
        "defect_count": 2300.0
      }

    ]
    }
    // this.areaLineData["lineData"] = this.data
    // this.areaLineData["barData"] = this.data
    //  ", "TEST ONE": "gold", "test TWO": "red", "test three" : "purple", "test four": "black"
    this.props = {
      chartHeight: 500,
      colors:{"Network Average":"#E2E5E9","Amber":"#0BBF1D","Biologics":"#C90F0F","RxCrossroads":"orange","Onco360":'blue'},
      rtVar: "no_of_days",
      errorTypeVar: "error_type",
      errorVar: "defect_count",
      areaTypes: ["Network Average"],
      lineTypes:["Amber","Biologics","RxCrossroads","Onco360",],
      errorTypeLabels:{"Network Average":"Network Average","Amber":"Amber","Biologics":"Biologics","RxCrossroads":"RxCrossroads","Onco360":"Onco360"},
      dateVar: "period",
      showBrush: true,
      d3AxisFormatting: false,
      period: "D",
      yAxisLabelLeft:" ",
      yAxisLabelRight:" ",
      yAxisFormat: "-2~s",
      partialPeriod: true,
      areaPlot:true,
      linePlot:true,
      yAxisRight:true
    }

    // this.props = {
    //   chartHeight: 500,
    //  lineColor:{ 'AVG RT-E':"#C90F0F", 'AVG RT-F':"#0BBF1D",'E': "#FFD951",'F':"#FF7715"},
    //   colors: { "Failures": "#FF7715", "Errors": "#FFD951", "AVG RT-Failures": "#0BBF1D", "AVG RT-Errors": "#C90F0F" },
    //   tooltip: { "Failures": "Failures", "Errors": "Errors", "AVG RT-Failures": "AVERAGE Resolution time-Failures (IN DAYS)", "AVG RT-Errors": "AVERAGE Resolution time-Errors (IN DAYS)" },
    //   rtVar: "no_of_days",
    //   errorTypeVar: "error_type",
    //   errorVar: "defect_count",
    //   errorTypes: ["E", "F"],
    //   errorTypeLabels: { "F": "Failures", "E": "Errors" },
    //   dateVar: "period",
    //   showBrush: true,
    //   d3AxisFormatting: false,
    //   period: this.selectedType,
    //   yAxisLabelLeft: "Defects",
    //   yAxisLabelRight: "Number of Days",
    //   yAxisFormat: "-2~s",
    //   partialPeriod: false
    // }
    // no data is below
    // this.areaLineData = {rtData: [], errorData: []};
    if (this.areaLineData.lineData.length > 0) {
      if (Object.keys(this.areaLineData.lineData[0]).indexOf("partial_period_flg") > -1) {
        if (this.areaLineData.lineData.find((f: any) => f["partial_period_flg"] === "Y") !== undefined) {
          this.props.partialPeriod = true;
        }
      }
    }
    if (this.isFullscreen) {
      this.props.chartHeight = window.outerHeight - 60;
    }
    if ( this.areaLineData.lineData.length > 0 && this.areaLineData.barData.length > 0) {
      this.noData= false
      setTimeout(() => {
        this.plotChart();
      }, 100);
    } else {
      this.noData= true
      this.areaLineData = { lineData: [], barData: [] };
      setTimeout(() => {
        this.plotChart();
      }, 100);
    }
    // });

  }

  // numberFormat
  numbedPipe(value: any) {
    return this.currency.transform(value, '', '', '1.0-2');
    //  return   this.numberpipe.transform(value)
  }
}
