
<div class="widget-container" #StackedComboChart>
    <app-stacked-combo-bar-chart
    style="width: 100%;"
    [config]="config"
    [headerConfig]="this.item.config.widget_header_config"
    [heading]="heading" 
    [data]="data"  
    [item]="item"
    [pageKey]="pageKey"
    >
    </app-stacked-combo-bar-chart>
</div>
