<div class="widget-container" #alertSubscriptions>
    <!-- Headings -->
    <main>
        <article #fs fullscreen-able [class.is-active]="isActive" [class.isFulScreen]="isActive"
            style="background-color: #fff;">
            <div class="mb-3">
                <icon-chart [headerConfig]="item.config?.widget_header_config" [pageKey]="pageKey" [expand]="fs"
                    [fullscreen]="isFullscreen" [item]="item" (screenChanges)="screenChanges($event)"></icon-chart>
            </div>

            <div *ngIf="!loader" style="width: 100%; height: 200px;">
                <loading></loading>
            </div>

            <div *ngIf="loader">
                <div class="row mb-3" >
                    <div class="col-6 col-md-12 col-lg-6 col-sm-12 mb-5">
                        <div class="mb-3" *ngFor="let kpi of kpiLeftLists; let idx = index;">
                            <mat-expansion-panel 
                                class="mx-2 du-expansionPanelMat"
                                (opened)="setOpened(idx)"
                                (closed)="setClosed(idx)"
                                hideToggle="true" #expensionLeft>
                                <!-- Header -->
                                <mat-expansion-panel-header class="du-expansion-panel-header">
                                    <mat-panel-title class="d-flex align-items-center justify-content-between">
                                        <div class="d-flex align-items-center">
                                            <span class="mx-3">
                                                <span *ngIf="expensionLeft.expanded">
                                                    <svg width="12" height="7" viewBox="0 0 10 5" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M5 -4.37114e-07L0.669872 4.5L9.33013 4.5L5 -4.37114e-07Z" fill="#101D42"></path>
                                                    </svg>
                                                </span>
                                                <span *ngIf="!expensionLeft.expanded">
                                                    <svg width="12" height="7" viewBox="0 0 10 5" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M5 5L0.669872 0.500001L9.33013 0.5L5 5Z" fill="#8A98AB"></path>
                                                    </svg>
                                                </span>
                                            </span>
                                            <span class="du-alert-name">{{ kpi.alert_nm }}</span>
                                        </div>
    
                                        <div class="d-flex align-items-center justify-content-between">
    
                                            <span style="cursor: pointer;padding: 3px;"
                                                matTooltip="Alert info"
                                                [matMenuTriggerFor]="info"
                                                (click)="getInfoDetails(kpi);$event.stopPropagation();" 
                                                >
                                                <svg width="18" height="19" viewBox="0 0 11 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M7.82328 9.17647C5.31348 11.6863 4.78009 11.7803 1.99976 9C-0.780575 6.21967 -0.689584 5.68934 1.99976 3C4.68909 0.310661 5.12063 0.120873 7.99976 3C10.8789 5.87913 10.7801 6.21967 7.82328 9.17647Z" fill="black"/>
                                                    <path d="M4.99976 8.3418L4.99976 6.22048" stroke="white" stroke-linecap="round" stroke-linejoin="round"/>
                                                    <path d="M4.99978 4.09869C5.14623 4.09869 5.26495 3.97997 5.26495 3.83352C5.26495 3.68708 5.14623 3.56836 4.99978 3.56836C4.85334 3.56836 4.73462 3.68708 4.73462 3.83352C4.73462 3.97997 4.85334 4.09869 4.99978 4.09869Z" fill="white" stroke="white" stroke-linecap="round" stroke-linejoin="round"/>
                                                </svg>
                                            </span>
    
                                            <mat-icon 
                                                *ngIf="kpi.selected_data.length>0"
                                                style="color: #3AC97C;font-size: 20px;height: auto;width: auto;"
                                                (click)="$event.stopPropagation()"
                                                matTooltip="Subscribed">
                                                notifications_active
                                            </mat-icon>
    
                                            <mat-icon 
                                                *ngIf="kpi.selected_data.length==0"
                                                style="color:#8A98AB;font-size: 20px;height: auto;width: auto;" 
                                                (click)="$event.stopPropagation()" 
                                                matTooltip="Not subscribed">
                                                notifications_off
                                            </mat-icon>
    
                                            <mat-icon 
                                                *ngIf="kpi.selected_data.length>0"
                                                (click)="$event.stopPropagation();deleteAlerts(kpi);" 
                                                style="cursor: pointer;color: #D11044;font-size: 20px;height: auto;width: auto;"
                                                matTooltip="Delete alerts">
                                                delete
                                            </mat-icon>
    
                                        </div>
                                    </mat-panel-title>
                                </mat-expansion-panel-header>
                                <!-- Body -->
                                <div *ngFor="let data of kpi.selected_data; let dI = index;let last = last;">
                                    <div class="mt-3 mb-3 du-alertFormBorder" >
                                        <div class="row mb-3" *ngIf="kpi.selected_fields.length>0">
                                            <div class="mb-2" [class]="field.type=='alert-tree-select' ? 'col-12' : 'col-6'" *ngFor="let field of kpi.selected_fields; let fI = index;">
                                                <div *ngIf="field.type=='alert-single-select'">
                                                    <app-alert-single-select [field]="field" [allFields]="kpi.selected_fields" [selectAll]="kpi.selected_option[dI]" [valueChanges]="{'position': 'left'+idx+dI, valueChange: valueChanges}" [data]="data" (emitSelectedValue)="emitLeftSelectedValue($event, idx, dI, fI)"></app-alert-single-select>
                                                </div>
                                                <div *ngIf="field.type=='alert-multi-select'">
                                                    <app-alert-multi-select [field]="field" [allFields]="kpi.selected_fields" [selectAll]="kpi.selected_option[dI]" [valueChanges]="{'position': 'left'+idx+dI, valueChange: valueChanges}" [data]="data" (emitSelectedValue)="emitLeftSelectedValue($event, idx, dI, fI)"></app-alert-multi-select>
                                                </div>
                                                <div *ngIf="field.type=='alert-tree-select'">
                                                    <app-alert-tree-select [field]="field" [allFields]="kpi.selected_fields" [data]="data" (emitSelectedValue)="emitLeftSelectedValue($event, idx, dI, fI)"></app-alert-tree-select>
                                                </div>
                                            </div>
                                        </div>
        
                                        <hr style="margin-left: -15px !important;margin-right: -15px !important;" *ngIf="kpi.selected_fields.length>0">
        
                                        <div class="d-flex align-items-center justify-content-between" [style.margin-top.px]="kpi.selected_fields.length>0 ? 10 : 0">
                                            <div class="d-flex align-items-center">
                                                <mat-icon 
                                                    style="cursor: pointer;color: #D11044;font-size: 18px;height: auto;width: auto;">
                                                    delete
                                                </mat-icon>
                                                <span class="du-remove-subscription du-remove-icon" (click)="removeLeftSubscription(idx, dI)">
                                                    Remove subscription
                                                </span>
                                            </div>
                                            <div>
                                                <section class="example-section-du-check">
                                                    <mat-checkbox class="example-margin-du-check" [(ngModel)]="data['in_app_subscription_flg']" (ngModelChange)="onCheckboxChange($event)" color="primary">In-App alerts</mat-checkbox>
                                                    <mat-checkbox class="example-margin-du-check" [(ngModel)]="data['email_subscription_flg']" (ngModelChange)="onCheckboxChange($event)" color="primary">Email alerts</mat-checkbox>
                                                </section>
                                                <span *ngIf="!data['in_app_subscription_flg'] && !data['email_subscription_flg']">
                                                    <span class="m-2 du-req-text"> {{ 'Alert type is required' }} </span>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    
                                    <div class="mt-3" *ngIf="last && kpi.selected_fields.length>0">
                                        <span 
                                            class="du-add-subsription" 
                                            (click)="addSubsription(kpi, idx)" 
                                            [class.disabled]="duplicatesDetected(kpi.selected_data) || allValuesExist(data)"
                                            >
                                            + Add additional subscription
                                        </span>
                                        <span *ngIf="allValuesExist(data)" class="du-remove-subscription" style="display: block;font-weight: 500;">Please complete the above section to add new one</span>
                                        <span *ngIf="duplicatesDetected(kpi.selected_data)" class="du-remove-subscription" style="display: block;font-weight: 500;">Duplicates Detected</span>
                                    </div>
                                </div>
    
                                <div class="mt-3" *ngIf="kpi.selected_data.length==0">
                                    <span 
                                        class="du-add-subsription" 
                                        (click)="addSubsription(kpi, idx)"
                                        >
                                        + Add additional subscription
                                    </span>
                                </div>
    
                            </mat-expansion-panel>
                        </div>
                    </div>
                    <div class="col-6 col-md-12 col-lg-6 col-sm-12 mb-5">
                        <div class="mb-3" *ngFor="let kpi of kpiRightLists; let idx = index;">
                            <mat-expansion-panel 
                                class="mx-2 du-expansionPanelMat"
                                (opened)="setRightOpened(idx)"
                                (closed)="setRightClosed(idx)"
                                hideToggle="true" #expensionRight>
                                <!-- Header -->
                                <mat-expansion-panel-header class="du-expansion-panel-header">
                                    <mat-panel-title class="d-flex align-items-center justify-content-between">
                                        <div class="d-flex align-items-center">
                                            <span class="mx-3">
                                                <span *ngIf="expensionRight.expanded">
                                                    <svg width="12" height="7" viewBox="0 0 10 5" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M5 -4.37114e-07L0.669872 4.5L9.33013 4.5L5 -4.37114e-07Z" fill="#101D42"></path>
                                                    </svg>
                                                </span>
                                                <span *ngIf="!expensionRight.expanded">
                                                    <svg width="12" height="7" viewBox="0 0 10 5" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M5 5L0.669872 0.500001L9.33013 0.5L5 5Z" fill="#8A98AB"></path>
                                                    </svg>
                                                </span>
                                            </span>
                                            <span class="du-alert-name">{{ kpi.alert_nm }}</span>
                                        </div>
    
                                        <div class="d-flex align-items-center justify-content-between">
    
                                            <span style="cursor: pointer;"
                                                matTooltip="Alert info"
                                                [matMenuTriggerFor]="info"
                                                (click)="getInfoDetails(kpi);$event.stopPropagation();" 
                                                >
                                                <svg width="18" height="19" viewBox="0 0 11 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M7.82328 9.17647C5.31348 11.6863 4.78009 11.7803 1.99976 9C-0.780575 6.21967 -0.689584 5.68934 1.99976 3C4.68909 0.310661 5.12063 0.120873 7.99976 3C10.8789 5.87913 10.7801 6.21967 7.82328 9.17647Z" fill="black"/>
                                                    <path d="M4.99976 8.3418L4.99976 6.22048" stroke="white" stroke-linecap="round" stroke-linejoin="round"/>
                                                    <path d="M4.99978 4.09869C5.14623 4.09869 5.26495 3.97997 5.26495 3.83352C5.26495 3.68708 5.14623 3.56836 4.99978 3.56836C4.85334 3.56836 4.73462 3.68708 4.73462 3.83352C4.73462 3.97997 4.85334 4.09869 4.99978 4.09869Z" fill="white" stroke="white" stroke-linecap="round" stroke-linejoin="round"/>
                                                </svg>
                                            </span>
    
                                            <mat-icon 
                                                *ngIf="kpi.selected_data.length>0"
                                                style="color: #3AC97C;font-size: 20px;height: auto;width: auto;"
                                                (click)="$event.stopPropagation()"
                                                matTooltip="Subscribed">
                                                notifications_active
                                            </mat-icon>
    
                                            <mat-icon 
                                                *ngIf="kpi.selected_data.length==0"
                                                style="color:#8A98AB;font-size: 20px;height: auto;width: auto;" 
                                                (click)="$event.stopPropagation()" 
                                                matTooltip="Not subscribed">
                                                notifications_off
                                            </mat-icon>
    
                                            <mat-icon 
                                                *ngIf="kpi.selected_data.length>0"
                                                (click)="$event.stopPropagation();deleteAlerts(kpi);" 
                                                style="cursor: pointer;color: #D11044;font-size: 20px;height: auto;width: auto;"
                                                matTooltip="Delete alerts">
                                                delete
                                            </mat-icon>
    
                                        </div>
                                    </mat-panel-title>
                                </mat-expansion-panel-header>
                                <!-- Body -->
                                <div *ngFor="let data of kpi.selected_data; let dI = index;let last = last;">
                                    <div class="mt-3 mb-3 du-alertFormBorder" >
                                        <div class="row mb-3" *ngIf="kpi.selected_fields.length>0">
                                            <div class="mb-2" [class]="field.type=='alert-tree-select' ? 'col-12' : 'col-6'" *ngFor="let field of kpi.selected_fields; let fI = index;">
                                                <div *ngIf="field.type=='alert-single-select'">
                                                    <app-alert-single-select [field]="field" [allFields]="kpi.selected_fields" [selectAll]="kpi.selected_option[dI]" [valueChanges]="{'position': 'right'+idx+dI, valueChange: valueChanges}" [data]="data" (emitSelectedValue)="emitRightSelectedValue($event, idx, dI)"></app-alert-single-select>
                                                </div>
                                                <div *ngIf="field.type=='alert-multi-select'">
                                                    <app-alert-multi-select [field]="field" [allFields]="kpi.selected_fields" [selectAll]="kpi.selected_option[dI]" [valueChanges]="{'position': 'right'+idx+dI, valueChange: valueChanges}" [data]="data" (emitSelectedValue)="emitRightSelectedValue($event, idx, dI)"></app-alert-multi-select>
                                                </div>
                                                <div *ngIf="field.type=='alert-tree-select'">
                                                    <app-alert-tree-select [field]="field" [allFields]="kpi.selected_fields" [data]="data" (emitSelectedValue)="emitRightSelectedValue($event, idx, dI)"></app-alert-tree-select>
                                                </div>
                                            </div>
                                        </div>
        
                                        <hr style="margin-left: -15px !important;margin-right: -15px !important;" *ngIf="kpi.selected_fields.length>0">
        
                                        <div class="d-flex align-items-center justify-content-between" [style.margin-top.px]="kpi.selected_fields.length>0 ? 10 : 0">
                                            <div class="d-flex align-items-center">
                                                <mat-icon 
                                                    style="cursor: pointer;color: #D11044;font-size: 18px;height: auto;width: auto;">
                                                    delete
                                                </mat-icon>
                                                <span class="du-remove-subscription du-remove-icon" (click)="removeRightSubscription(idx, dI)">
                                                    Remove subscription
                                                </span>
                                            </div>
                                            <div>
                                                <section class="example-section-du-check">
                                                    <mat-checkbox class="example-margin-du-check" [(ngModel)]="data['in_app_subscription_flg']" (ngModelChange)="onCheckboxChange($event)" color="primary">In-App alerts</mat-checkbox>
                                                    <mat-checkbox class="example-margin-du-check" [(ngModel)]="data['email_subscription_flg']" (ngModelChange)="onCheckboxChange($event)" color="primary">Email alerts</mat-checkbox>
                                                </section>
                                                <span *ngIf="!data['in_app_subscription_flg'] && !data['email_subscription_flg']">
                                                    <span class="m-2 du-req-text"> {{ 'Alert type is required' }} </span>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
    
                                    <div class="mt-3" *ngIf="last && kpi.selected_fields.length>0">
                                        <span 
                                            class="du-add-subsription" 
                                            (click)="addSubsription(kpi, idx)" 
                                            [class.disabled]="duplicatesDetected(kpi.selected_data) || allValuesExist(data)"
                                            >
                                            + Add additional subscription
                                        </span>
                                        <span *ngIf="allValuesExist(data)" class="du-remove-subscription" style="display: block;font-weight: 500;">Please complete the above section to add new one</span>
                                        <span *ngIf="duplicatesDetected(kpi.selected_data)" class="du-remove-subscription" style="display: block;font-weight: 500;">Duplicates Detected</span>
                                    </div>
                                </div>
    
                                <div class="mt-3" *ngIf="kpi.selected_data.length==0">
                                    <span 
                                        class="du-add-subsription" 
                                        (click)="addSubsription(kpi, idx)"
                                        >
                                        + Add additional subscription
                                    </span>
                                </div>
                            </mat-expansion-panel>
                        </div>
                    </div>
                </div>
    
    
                <div class="d-flex bd-highlight align-items-center changesBtn-du-bottom mb-3">
                    <div class="me-auto p-2 bd-highlight">
                        <!-- <span class="du-remove-subscription" style="font-weight: 500;" *ngIf="!this.cancelSaveChanges && validationForSaveChanges()">*Please fill mandatory fields to save changes</span> -->
                    </div>
                    <div class="p-2 bd-highlight">
                        <button type="button" class="btn btn-light du-cancel-changes" [disabled]="validationForCancel" (click)="cancelChanges()">Cancel</button>
                    </div>
                    <div class="p-2 bd-highlight">
                        <button type="button" class="btn btn-primary du-save-changes" [class.disabled]="(this.cancelSaveChanges || this.duplicatesDetectes() || validationForSaveChanges())" (click)="saveChanges()" >Save Changes</button>
                    </div>
                </div>

            </div>
        </article>
    </main>
</div>


<mat-menu #info="matMenu">
    <div class="card" style="min-width: 300px;border: none;width: auto;" (click)="$event.stopPropagation()">
        <div class="card-header d-flex justify-content-between align-items-center"
            style="border-bottom: none;background: #ddd;">
            <span style="font-weight: 600;">{{currentSelection || ''}}</span>

            <span (click)="closeMenu()" style="cursor: pointer;">
                <svg width="16" height="16" style="width: 12px !important;height: 12px !important;" viewBox="0 0 16 16"
                    fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M12.9501 0.409871L8 5.35998L3.04989 0.409871C2.5034 -0.136624 1.61634 -0.136624 1.06985 0.409871L0.409871 1.06985C-0.136624 1.61634 -0.136624 2.5034 0.409871 3.04989L5.35998 8L0.409871 12.9501C-0.136624 13.4966 -0.136624 14.3837 0.409871 14.9302L1.06985 15.5901C1.61634 16.1366 2.5034 16.1366 3.04989 15.5901L8 10.64L12.9501 15.5901C13.4966 16.1366 14.3837 16.1366 14.9302 15.5901L15.5901 14.9302C16.1366 14.3837 16.1366 13.4966 15.5901 12.9501L10.64 8L15.5901 3.04989C16.1366 2.5034 16.1366 1.61634 15.5901 1.06985L14.9302 0.409871C14.3837 -0.136624 13.4966 -0.136624 12.9501 0.409871Z"
                        fill="#1363DF" />
                </svg>
            </span>
        </div>

        <div class="card-body">
            <div>{{alertDescription ? alertDescription : ' No Data Found'}}</div>
        </div>
    </div>
</mat-menu>