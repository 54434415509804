import { Component, OnInit } from '@angular/core';
import Widget from '../widget';
import moment from 'moment';

@Component({
  selector: 'app-cluster-stacked-bar-card',
  templateUrl: './cluster-stacked-bar-card.component.html',
  styleUrls: ['./cluster-stacked-bar-card.component.scss']
})
export class ClusterStackedBarCardComponent extends Widget implements OnInit {
  static override key = 100705;
  
  props: any = {};
  chartData:any = [];
  loader = false;
  designConfig:any;
  excelConfig = [
    {
      "header": "Date",
      "key": "period",
      "type": "date",
    },
    {
      "header": "Source Name",
      "key": "source_nm",
      "type": "string",
    },
    {
      "header": "KPI",
      "key": "kpi_name",
      "type": "string",
    },
    {
      "header": "KPI Value",
      "key": "kpi_value",
      "type": "number",
    },
    {
      "header": "Average",
      "key": "kpi_average_name",
      "type": "string",
    },
    {
      "header": "Average Value",
      "key": "kpi_average_value",
      "type": "number"
    }
  ]

  constructor() {
    super();

    this.subs.push(this.filterService.filterQuery.subscribe((query: any) => {
      this.loadApiData();
    }))
  }

  override ngOnInit(): void {
    super.ngOnInit();
  }

  loadApiData() {
    let cfg:any = this.item.config;
    
    this.loader = false;
    if (cfg && cfg['list_of_api'] && (this.isEmptyObject(this.filterService.baseQuery) == false)) {

      // Api payload
      let obj: any = { "api_key": cfg['list_of_api'] } //'prescriber_id' : this.presId

      let query = Object.assign({}, this.filterService.baseQuery, obj)
      query["report_typ"] = this.filterService.report_type;

      // if(obj.api_key==100210) { 
      //   this.excelConfig = [
      //     {
      //       "header": "Date",
      //       "key": "period",
      //       "type": "date",
      //     },
      //     {
      //       "header": "Source Name",
      //       "key": "source_nm",
      //       "type": "string",
      //     },
      //     {
      //       "header": "KPI",
      //       "key": "kpi_name",
      //       "type": "number",
      //     },
      //     {
      //       "header": "KPI Value",
      //       "key": "kpi_value",
      //       "type": "number",
      //     },
      //     {
      //       "header": "Average",
      //       "key": "kpi_average_name",
      //       "type": "number",
      //     },
      //     {
      //       "header": "Average Value",
      //       "key": "kpi_average_value",
      //       "type": "number"
      //     }
      //   ]
      // } else {

      // }

      this.subs.push(
        this.httpClient.post(`${this.apiService.apiBaseUrl}/${this.apiService.executionUrl}`, query).subscribe((res: any) => {
          if (res && res.length > 0) {

            if(obj.api_key==100210) {
              res = [
                {
                  "source_nm": "Biologics",
                  "kpi_name": "New Patients",
                  "kpi_value": 18,
                  "kpi_average_name": "New Network Avg",
                  "kpi_average_value": 22,
                  "period": "2021-09-30"
                },
                {
                  "source_nm": "Biologics",
                  "kpi_name": "Refill Patients",
                  "kpi_value": 28,
                  "kpi_average_name": "Refill Network Avg",
                  "kpi_average_value": 9,
                  "period": "2021-09-30"
                },
                {
                  "source_nm": "CVS",
                  "kpi_name": "New Patients",
                  "kpi_value": 12,
                  "kpi_average_name": "New Network Avg",
                  "kpi_average_value": 22,
                  "period": "2021-09-30"
                },
                {
                  "source_nm": "CVS",
                  "kpi_name": "Refill Patients",
                  "kpi_value": 43,
                  "kpi_average_name": "Refill Network Avg",
                  "kpi_average_value": 9,
                  "period": "2021-09-30"
                },
                {
                  "source_nm": "Amber",
                  "kpi_name": "New Patients",
                  "kpi_value": 6,
                  "kpi_average_name": "New Network Avg",
                  "kpi_average_value": 22,
                  "period": "2021-09-30"
                },
                {
                  "source_nm": "Amber",
                  "kpi_name": "Refill Patients",
                  "kpi_value": 37,
                  "kpi_average_name": "Refill Network Avg",
                  "kpi_average_value": 9,
                  "period": "2021-09-30"
                },
                {
                  "source_nm": "RxCrossroads",
                  "kpi_name": "New Patients",
                  "kpi_value": 7,
                  "kpi_average_name": "New Network Avg",
                  "kpi_average_value": 22,
                  "period": "2021-09-30"
                },
                {
                  "source_nm": "RxCrossroads",
                  "kpi_name": "Refill Patients",
                  "kpi_value": 36,
                  "kpi_average_name": "Refill Network Avg",
                  "kpi_average_value": 9,
                  "period": "2021-09-30"
                },
                {
                  "source_nm": "Biologics",
                  "kpi_name": "New Patients",
                  "kpi_value": 17,
                  "kpi_average_name": "New Network Avg",
                  "kpi_average_value": 10,
                  "period": "2021-10-31"
                },
                {
                  "source_nm": "Biologics",
                  "kpi_name": "Refill Patients",
                  "kpi_value": 29,
                  "kpi_average_name": "Refill Network Avg",
                  "kpi_average_value": 23,
                  "period": "2021-10-31"
                },
                {
                  "source_nm": "CVS",
                  "kpi_name": "New Patients",
                  "kpi_value": 14,
                  "kpi_average_name": "New Network Avg",
                  "kpi_average_value": 10,
                  "period": "2021-10-31"
                },
                {
                  "source_nm": "CVS",
                  "kpi_name": "Refill Patients",
                  "kpi_value": 44,
                  "kpi_average_name": "Refill Network Avg",
                  "kpi_average_value": 23,
                  "period": "2021-10-31"
                },
                {
                  "source_nm": "Amber",
                  "kpi_name": "New Patients",
                  "kpi_value": 8,
                  "kpi_average_name": "New Network Avg",
                  "kpi_average_value": 10,
                  "period": "2021-10-31"
                },
                {
                  "source_nm": "Amber",
                  "kpi_name": "Refill Patients",
                  "kpi_value": 38,
                  "kpi_average_name": "Refill Network Avg",
                  "kpi_average_value": 23,
                  "period": "2021-10-31"
                },
                {
                  "source_nm": "RxCrossroads",
                  "kpi_name": "New Patients",
                  "kpi_value": 9,
                  "kpi_average_name": "New Network Avg",
                  "kpi_average_value": 10,
                  "period": "2021-10-31"
                },
                {
                  "source_nm": "RxCrossroads",
                  "kpi_name": "Refill Patients",
                  "kpi_value": 34,
                  "kpi_average_name": "Refill Network Avg",
                  "kpi_average_value": 23,
                  "period": "2021-10-31"
                },
                {
                  "source_nm": "Biologics",
                  "kpi_name": "New Patients",
                  "kpi_value": 19,
                  "kpi_average_name": "New Network Avg",
                  "kpi_average_value": 50,
                  "period": "2021-11-30"
                },
                {
                  "source_nm": "Biologics",
                  "kpi_name": "Refill Patients",
                  "kpi_value": 30,
                  "kpi_average_name": "Refill Network Avg",
                  "kpi_average_value": 20,
                  "period": "2021-11-30"
                },
                {
                  "source_nm": "CVS",
                  "kpi_name": "New Patients",
                  "kpi_value": 13,
                  "kpi_average_name": "New Network Avg",
                  "kpi_average_value": 50,
                  "period": "2021-11-30"
                },
                {
                  "source_nm": "CVS",
                  "kpi_name": "Refill Patients",
                  "kpi_value": 42,
                  "kpi_average_name": "Refill Network Avg",
                  "kpi_average_value": 20,
                  "period": "2021-11-30"
                },
                {
                  "source_nm": "Amber",
                  "kpi_name": "New Patients",
                  "kpi_value": 7,
                  "kpi_average_name": "New Network Avg",
                  "kpi_average_value": 50,
                  "period": "2021-11-30"
                },
                {
                  "source_nm": "Amber",
                  "kpi_name": "Refill Patients",
                  "kpi_value": 33,
                  "kpi_average_name": "Refill Network Avg",
                  "kpi_average_value": 20,
                  "period": "2021-11-30"
                },
                {
                  "source_nm": "RxCrossroads",
                  "kpi_name": "New Patients",
                  "kpi_value": 12,
                  "kpi_average_name": "New Network Avg",
                  "kpi_average_value": 50,
                  "period": "2021-11-30"
                },
                {
                  "source_nm": "RxCrossroads",
                  "kpi_name": "Refill Patients",
                  "kpi_value": 39,
                  "kpi_average_name": "Refill Network Avg",
                  "kpi_average_value": 20,
                  "period": "2021-11-30"
                },
                {
                  "source_nm": "Biologics",
                  "kpi_name": "New Patients",
                  "kpi_value": 16,
                  "kpi_average_name": "New Network Avg",
                  "kpi_average_value": 35,
                  "period": "2021-12-31"
                },
                {
                  "source_nm": "Biologics",
                  "kpi_name": "Refill Patients",
                  "kpi_value": 32,
                  "kpi_average_name": "Refill Network Avg",
                  "kpi_average_value": 20,
                  "period": "2021-12-31"
                },
                {
                  "source_nm": "CVS",
                  "kpi_name": "New Patients",
                  "kpi_value": 15,
                  "kpi_average_name": "New Network Avg",
                  "kpi_average_value": 35,
                  "period": "2021-12-31"
                },
                {
                  "source_nm": "CVS",
                  "kpi_name": "Refill Patients",
                  "kpi_value": 46,
                  "kpi_average_name": "Refill Network Avg",
                  "kpi_average_value": 20,
                  "period": "2021-12-31"
                },
                {
                  "source_nm": "Amber",
                  "kpi_name": "New Patients",
                  "kpi_value": 9,
                  "kpi_average_name": "New Network Avg",
                  "kpi_average_value": 35,
                  "period": "2021-12-31"
                },
                {
                  "source_nm": "Amber",
                  "kpi_name": "Refill Patients",
                  "kpi_value": 41,
                  "kpi_average_name": "Refill Network Avg",
                  "kpi_average_value": 20,
                  "period": "2021-12-31"
                },
                {
                  "source_nm": "RxCrossroads",
                  "kpi_name": "New Patients",
                  "kpi_value": 14,
                  "kpi_average_name": "New Network Avg",
                  "kpi_average_value": 35,
                  "period": "2021-12-31"
                },
                {
                  "source_nm": "RxCrossroads",
                  "kpi_name": "Refill Patients",
                  "kpi_value": 43,
                  "kpi_average_name": "Refill Network Avg",
                  "kpi_average_value": 20,
                  "period": "2021-12-31"
                },
                {
                  "source_nm": "Biologics",
                  "kpi_name": "New Patients",
                  "kpi_value": 25,
                  "kpi_average_name": "New Network Avg",
                  "kpi_average_value": 20,
                  "period": "2022-01-31"
                },
                {
                  "source_nm": "Biologics",
                  "kpi_name": "Refill Patients",
                  "kpi_value": 33,
                  "kpi_average_name": "Refill Network Avg",
                  "kpi_average_value": 10,
                  "period": "2022-01-31"
                },
                {
                  "source_nm": "CVS",
                  "kpi_name": "New Patients",
                  "kpi_value": 18,
                  "kpi_average_name": "New Network Avg",
                  "kpi_average_value": 20,
                  "period": "2022-01-31"
                },
                {
                  "source_nm": "CVS",
                  "kpi_name": "Refill Patients",
                  "kpi_value": 47,
                  "kpi_average_name": "Refill Network Avg",
                  "kpi_average_value": 10,
                  "period": "2022-01-31"
                },
                {
                  "source_nm": "Amber",
                  "kpi_name": "New Patients",
                  "kpi_value": 11,
                  "kpi_average_name": "New Network Avg",
                  "kpi_average_value": 20,
                  "period": "2022-01-31"
                },
                {
                  "source_nm": "Amber",
                  "kpi_name": "Refill Patients",
                  "kpi_value": 42,
                  "kpi_average_name": "Refill Network Avg",
                  "kpi_average_value": 10,
                  "period": "2022-01-31"
                },
                {
                  "source_nm": "RxCrossroads",
                  "kpi_name": "New Patients",
                  "kpi_value": 19,
                  "kpi_average_name": "New Network Avg",
                  "kpi_average_value": 20,
                  "period": "2022-01-31"
                },
                {
                  "source_nm": "RxCrossroads",
                  "kpi_name": "Refill Patients",
                  "kpi_value": 44,
                  "kpi_average_name": "Refill Network Avg",
                  "kpi_average_value": 10,
                  "period": "2022-01-31"
                },
                {
                  "source_nm": "Biologics",
                  "kpi_name": "New Patients",
                  "kpi_value": 28,
                  "kpi_average_name": "New Network Avg",
                  "kpi_average_value": 10,
                  "period": "2022-02-28"
                },
                {
                  "source_nm": "Biologics",
                  "kpi_name": "Refill Patients",
                  "kpi_value": 35,
                  "kpi_average_name": "Refill Network Avg",
                  "kpi_average_value": 19,
                  "period": "2022-02-28"
                },
                {
                  "source_nm": "CVS",
                  "kpi_name": "New Patients",
                  "kpi_value": 16,
                  "kpi_average_name": "New Network Avg",
                  "kpi_average_value": 10,
                  "period": "2022-02-28"
                },
                {
                  "source_nm": "CVS",
                  "kpi_name": "Refill Patients",
                  "kpi_value": 48,
                  "kpi_average_name": "Refill Network Avg",
                  "kpi_average_value": 19,
                  "period": "2022-02-28"
                },
                {
                  "source_nm": "Amber",
                  "kpi_name": "New Patients",
                  "kpi_value": 10,
                  "kpi_average_name": "New Network Avg",
                  "kpi_average_value": 10,
                  "period": "2022-02-28"
                },
                {
                  "source_nm": "Amber",
                  "kpi_name": "Refill Patients",
                  "kpi_value": 39,
                  "kpi_average_name": "Refill Network Avg",
                  "kpi_average_value": 19,
                  "period": "2022-02-28"
                },
                {
                  "source_nm": "RxCrossroads",
                  "kpi_name": "New Patients",
                  "kpi_value": 22,
                  "kpi_average_name": "New Network Avg",
                  "kpi_average_value": 10,
                  "period": "2022-02-28"
                },
                {
                  "source_nm": "RxCrossroads",
                  "kpi_name": "Refill Patients",
                  "kpi_value": 45,
                  "kpi_average_name": "Refill Network Avg",
                  "kpi_average_value": 19,
                  "period": "2022-02-28"
                },
                {
                  "source_nm": "Biologics",
                  "kpi_name": "New Patients",
                  "kpi_value": 38,
                  "kpi_average_name": "New Network Avg",
                  "kpi_average_value": 29,
                  "period": "2022-03-31"
                },
                {
                  "source_nm": "Biologics",
                  "kpi_name": "Refill Patients",
                  "kpi_value": 42,
                  "kpi_average_name": "Refill Network Avg",
                  "kpi_average_value": 16,
                  "period": "2022-03-31"
                },
                {
                  "source_nm": "CVS",
                  "kpi_name": "New Patients",
                  "kpi_value": 22,
                  "kpi_average_name": "New Network Avg",
                  "kpi_average_value": 29,
                  "period": "2022-03-31"
                },
                {
                  "source_nm": "CVS",
                  "kpi_name": "Refill Patients",
                  "kpi_value": 57,
                  "kpi_average_name": "Refill Network Avg",
                  "kpi_average_value": 16,
                  "period": "2022-03-31"
                },
                {
                  "source_nm": "Amber",
                  "kpi_name": "New Patients",
                  "kpi_value": 15,
                  "kpi_average_name": "New Network Avg",
                  "kpi_average_value": 29,
                  "period": "2022-03-31"
                },
                {
                  "source_nm": "Amber",
                  "kpi_name": "Refill Patients",
                  "kpi_value": 46,
                  "kpi_average_name": "Refill Network Avg",
                  "kpi_average_value": 16,
                  "period": "2022-03-31"
                },
                {
                  "source_nm": "RxCrossroads",
                  "kpi_name": "New Patients",
                  "kpi_value": 31,
                  "kpi_average_name": "New Network Avg",
                  "kpi_average_value": 29,
                  "period": "2022-03-31"
                },
                {
                  "source_nm": "RxCrossroads",
                  "kpi_name": "Refill Patients",
                  "kpi_value": 53,
                  "kpi_average_name": "Refill Network Avg",
                  "kpi_average_value": 16,
                  "period": "2022-03-31"
                },
                {
                  "source_nm": "Biologics",
                  "kpi_name": "New Patients",
                  "kpi_value": 39,
                  "kpi_average_name": "New Network Avg",
                  "kpi_average_value": 30,
                  "period": "2022-04-30"
                },
                {
                  "source_nm": "Biologics",
                  "kpi_name": "Refill Patients",
                  "kpi_value": 43,
                  "kpi_average_name": "Refill Network Avg",
                  "kpi_average_value": 17,
                  "period": "2022-04-30"
                },
                {
                  "source_nm": "CVS",
                  "kpi_name": "New Patients",
                  "kpi_value": 23,
                  "kpi_average_name": "New Network Avg",
                  "kpi_average_value": 30,
                  "period": "2022-04-30"
                },
                {
                  "source_nm": "CVS",
                  "kpi_name": "Refill Patients",
                  "kpi_value": 58,
                  "kpi_average_name": "Refill Network Avg",
                  "kpi_average_value": 17,
                  "period": "2022-04-30"
                },
                {
                  "source_nm": "Amber",
                  "kpi_name": "New Patients",
                  "kpi_value": 16,
                  "kpi_average_name": "New Network Avg",
                  "kpi_average_value": 30,
                  "period": "2022-04-30"
                },
                {
                  "source_nm": "Amber",
                  "kpi_name": "Refill Patients",
                  "kpi_value": 47,
                  "kpi_average_name": "Refill Network Avg",
                  "kpi_average_value": 17,
                  "period": "2022-04-30"
                },
                {
                  "source_nm": "RxCrossroads",
                  "kpi_name": "New Patients",
                  "kpi_value": 32,
                  "kpi_average_name": "New Network Avg",
                  "kpi_average_value": 30,
                  "period": "2022-04-30"
                },
                {
                  "source_nm": "RxCrossroads",
                  "kpi_name": "Refill Patients",
                  "kpi_value": 54,
                  "kpi_average_name": "Refill Network Avg",
                  "kpi_average_value": 17,
                  "period": "2022-04-30"
                },
                {
                  "source_nm": "Biologics",
                  "kpi_name": "New Patients",
                  "kpi_value": 41,
                  "kpi_average_name": "New Network Avg",
                  "kpi_average_value": 18,
                  "period": "2022-05-31"
                },
                {
                  "source_nm": "Biologics",
                  "kpi_name": "Refill Patients",
                  "kpi_value": 44,
                  "kpi_average_name": "Refill Network Avg",
                  "kpi_average_value": 8,
                  "period": "2022-05-31"
                },
                {
                  "source_nm": "CVS",
                  "kpi_name": "New Patients",
                  "kpi_value": 24,
                  "kpi_average_name": "New Network Avg",
                  "kpi_average_value": 18,
                  "period": "2022-05-31"
                },
                {
                  "source_nm": "CVS",
                  "kpi_name": "Refill Patients",
                  "kpi_value": 59,
                  "kpi_average_name": "Refill Network Avg",
                  "kpi_average_value": 8,
                  "period": "2022-05-31"
                },
                {
                  "source_nm": "Amber",
                  "kpi_name": "New Patients",
                  "kpi_value": 17,
                  "kpi_average_name": "New Network Avg",
                  "kpi_average_value": 18,
                  "period": "2022-05-31"
                },
                {
                  "source_nm": "Amber",
                  "kpi_name": "Refill Patients",
                  "kpi_value": 48,
                  "kpi_average_name": "Refill Network Avg",
                  "kpi_average_value": 8,
                  "period": "2022-05-31"
                },
                {
                  "source_nm": "RxCrossroads",
                  "kpi_name": "New Patients",
                  "kpi_value": 33,
                  "kpi_average_name": "New Network Avg",
                  "kpi_average_value": 18,
                  "period": "2022-05-31"
                },
                {
                  "source_nm": "RxCrossroads",
                  "kpi_name": "Refill Patients",
                  "kpi_value": 55,
                  "kpi_average_name": "Refill Network Avg",
                  "kpi_average_value": 8,
                  "period": "2022-05-31"
                },
                {
                  "source_nm": "Biologics",
                  "kpi_name": "New Patients",
                  "kpi_value": 42,
                  "kpi_average_name": "New Network Avg",
                  "kpi_average_value": 32,
                  "period": "2022-06-30"
                },
                {
                  "source_nm": "Biologics",
                  "kpi_name": "Refill Patients",
                  "kpi_value": 45,
                  "kpi_average_name": "Refill Network Avg",
                  "kpi_average_value": 19,
                  "period": "2022-06-30"
                },
                {
                  "source_nm": "CVS",
                  "kpi_name": "New Patients",
                  "kpi_value": 25,
                  "kpi_average_name": "New Network Avg",
                  "kpi_average_value": 32,
                  "period": "2022-06-30"
                },
                {
                  "source_nm": "CVS",
                  "kpi_name": "Refill Patients",
                  "kpi_value": 60,
                  "kpi_average_name": "Refill Network Avg",
                  "kpi_average_value": 19,
                  "period": "2022-06-30"
                },
                {
                  "source_nm": "Amber",
                  "kpi_name": "New Patients",
                  "kpi_value": 18,
                  "kpi_average_name": "New Network Avg",
                  "kpi_average_value": 32,
                  "period": "2022-06-30"
                },
                {
                  "source_nm": "Amber",
                  "kpi_name": "Refill Patients",
                  "kpi_value": 49,
                  "kpi_average_name": "Refill Network Avg",
                  "kpi_average_value": 19,
                  "period": "2022-06-30"
                },
                {
                  "source_nm": "RxCrossroads",
                  "kpi_name": "New Patients",
                  "kpi_value": 34,
                  "kpi_average_name": "New Network Avg",
                  "kpi_average_value": 32,
                  "period": "2022-06-30"
                },
                {
                  "source_nm": "RxCrossroads",
                  "kpi_name": "Refill Patients",
                  "kpi_value": 56,
                  "kpi_average_name": "Refill Network Avg",
                  "kpi_average_value": 19,
                  "period": "2022-06-30"
                },
                {
                  "source_nm": "Biologics",
                  "kpi_name": "New Patients",
                  "kpi_value": 38,
                  "kpi_average_name": "New Network Avg",
                  "kpi_average_value": 20,
                  "period": "2022-07-31"
                },
                {
                  "source_nm": "Biologics",
                  "kpi_name": "Refill Patients",
                  "kpi_value": 26,
                  "kpi_average_name": "Refill Network Avg",
                  "kpi_average_value": 10,
                  "period": "2022-07-31"
                },
                {
                  "source_nm": "CVS",
                  "kpi_name": "New Patients",
                  "kpi_value": 10,
                  "kpi_average_name": "New Network Avg",
                  "kpi_average_value": 20,
                  "period": "2022-07-31"
                },
                {
                  "source_nm": "CVS",
                  "kpi_name": "Refill Patients",
                  "kpi_value": 45,
                  "kpi_average_name": "Refill Network Avg",
                  "kpi_average_value": 10,
                  "period": "2022-07-31"
                },
                {
                  "source_nm": "Amber",
                  "kpi_name": "New Patients",
                  "kpi_value": 5,
                  "kpi_average_name": "New Network Avg",
                  "kpi_average_value": 20,
                  "period": "2022-07-31"
                },
                {
                  "source_nm": "Amber",
                  "kpi_name": "Refill Patients",
                  "kpi_value": 35,
                  "kpi_average_name": "Refill Network Avg",
                  "kpi_average_value": 10,
                  "period": "2022-07-31"
                },
                {
                  "source_nm": "RxCrossroads",
                  "kpi_name": "New Patients",
                  "kpi_value": 5,
                  "kpi_average_name": "New Network Avg",
                  "kpi_average_value": 20,
                  "period": "2022-07-31"
                },
                {
                  "source_nm": "RxCrossroads",
                  "kpi_name": "Refill Patients",
                  "kpi_value": 35,
                  "kpi_average_name": "Refill Network Avg",
                  "kpi_average_value": 10,
                  "period": "2022-07-31"
                },
                {
                  "source_nm": "Biologics",
                  "kpi_name": "New Patients",
                  "kpi_value": 30,
                  "kpi_average_name": "New Network Avg",
                  "kpi_average_value": 24,
                  "period": "2022-08-31"
                },
                {
                  "source_nm": "Biologics",
                  "kpi_name": "Refill Patients",
                  "kpi_value": 36,
                  "kpi_average_name": "Refill Network Avg",
                  "kpi_average_value": 10,
                  "period": "2022-08-31"
                },
                {
                  "source_nm": "CVS",
                  "kpi_name": "New Patients",
                  "kpi_value": 14,
                  "kpi_average_name": "New Network Avg",
                  "kpi_average_value": 24,
                  "period": "2022-08-31"
                },
                {
                  "source_nm": "CVS",
                  "kpi_name": "Refill Patients",
                  "kpi_value": 50,
                  "kpi_average_name": "Refill Network Avg",
                  "kpi_average_value": 10,
                  "period": "2022-08-31"
                },
                {
                  "source_nm": "Amber",
                  "kpi_name": "New Patients",
                  "kpi_value": 11,
                  "kpi_average_name": "New Network Avg",
                  "kpi_average_value": 24,
                  "period": "2022-08-31"
                },
                {
                  "source_nm": "Amber",
                  "kpi_name": "Refill Patients",
                  "kpi_value": 41,
                  "kpi_average_name": "Refill Network Avg",
                  "kpi_average_value": 10,
                  "period": "2022-08-31"
                },
                {
                  "source_nm": "RxCrossroads",
                  "kpi_name": "New Patients",
                  "kpi_value": 25,
                  "kpi_average_name": "New Network Avg",
                  "kpi_average_value": 24,
                  "period": "2022-08-31"
                },
                {
                  "source_nm": "RxCrossroads",
                  "kpi_name": "Refill Patients",
                  "kpi_value": 47,
                  "kpi_average_name": "Refill Network Avg",
                  "kpi_average_value": 10,
                  "period": "2022-08-31"
                },
                {
                  "source_nm": "Biologics",
                  "kpi_name": "New Patients",
                  "kpi_value": 32,
                  "kpi_average_name": "New Network Avg",
                  "kpi_average_value": 42,
                  "period": "2022-09-30"
                },
                {
                  "source_nm": "Biologics",
                  "kpi_name": "Refill Patients",
                  "kpi_value": 38,
                  "kpi_average_name": "Refill Network Avg",
                  "kpi_average_value": 18,
                  "period": "2022-09-30"
                },
                {
                  "source_nm": "CVS",
                  "kpi_name": "New Patients",
                  "kpi_value": 17,
                  "kpi_average_name": "New Network Avg",
                  "kpi_average_value": 42,
                  "period": "2022-09-30"
                },
                {
                  "source_nm": "CVS",
                  "kpi_name": "Refill Patients",
                  "kpi_value": 52,
                  "kpi_average_name": "Refill Network Avg",
                  "kpi_average_value": 18,
                  "period": "2022-09-30"
                },
                {
                  "source_nm": "Amber",
                  "kpi_name": "New Patients",
                  "kpi_value": 12,
                  "kpi_average_name": "New Network Avg",
                  "kpi_average_value": 42,
                  "period": "2022-09-30"
                },
                {
                  "source_nm": "Amber",
                  "kpi_name": "Refill Patients",
                  "kpi_value": 42,
                  "kpi_average_name": "Refill Network Avg",
                  "kpi_average_value": 18,
                  "period": "2022-09-30"
                },
                {
                  "source_nm": "RxCrossroads",
                  "kpi_name": "New Patients",
                  "kpi_value": 26,
                  "kpi_average_name": "New Network Avg",
                  "kpi_average_value": 42,
                  "period": "2022-09-30"
                },
                {
                  "source_nm": "RxCrossroads",
                  "kpi_name": "Refill Patients",
                  "kpi_value": 49,
                  "kpi_average_name": "Refill Network Avg",
                  "kpi_average_value": 18,
                  "period": "2022-09-30"
                },
                {
                  "source_nm": "Biologics",
                  "kpi_name": "New Patients",
                  "kpi_value": 35,
                  "kpi_average_name": "New Network Avg",
                  "kpi_average_value": 27,
                  "period": "2022-10-31"
                },
                {
                  "source_nm": "Biologics",
                  "kpi_name": "Refill Patients",
                  "kpi_value": 40,
                  "kpi_average_name": "Refill Network Avg",
                  "kpi_average_value": 35,
                  "period": "2022-10-31"
                },
                {
                  "source_nm": "CVS",
                  "kpi_name": "New Patients",
                  "kpi_value": 19,
                  "kpi_average_name": "New Network Avg",
                  "kpi_average_value": 27,
                  "period": "2022-10-31"
                },
                {
                  "source_nm": "CVS",
                  "kpi_name": "Refill Patients",
                  "kpi_value": 54,
                  "kpi_average_name": "Refill Network Avg",
                  "kpi_average_value": 35,
                  "period": "2022-10-31"
                },
                {
                  "source_nm": "Amber",
                  "kpi_name": "New Patients",
                  "kpi_value": 13,
                  "kpi_average_name": "New Network Avg",
                  "kpi_average_value": 27,
                  "period": "2022-10-31"
                },
                {
                  "source_nm": "Amber",
                  "kpi_name": "Refill Patients",
                  "kpi_value": 44,
                  "kpi_average_name": "Refill Network Avg",
                  "kpi_average_value": 35,
                  "period": "2022-10-31"
                },
                {
                  "source_nm": "RxCrossroads",
                  "kpi_name": "New Patients",
                  "kpi_value": 28,
                  "kpi_average_name": "New Network Avg",
                  "kpi_average_value": 27,
                  "period": "2022-10-31"
                },
                {
                  "source_nm": "RxCrossroads",
                  "kpi_name": "Refill Patients",
                  "kpi_value": 50,
                  "kpi_average_name": "Refill Network Avg",
                  "kpi_average_value": 35,
                  "period": "2022-10-31"
                },
                {
                  "source_nm": "Biologics",
                  "kpi_name": "New Patients",
                  "kpi_value": 37,
                  "kpi_average_name": "New Network Avg",
                  "kpi_average_value": 36,
                  "period": "2022-11-30"
                },
                {
                  "source_nm": "Biologics",
                  "kpi_name": "Refill Patients",
                  "kpi_value": 41,
                  "kpi_average_name": "Refill Network Avg",
                  "kpi_average_value": 48,
                  "period": "2022-11-30"
                },
                {
                  "source_nm": "CVS",
                  "kpi_name": "New Patients",
                  "kpi_value": 21,
                  "kpi_average_name": "New Network Avg",
                  "kpi_average_value": 36,
                  "period": "2022-11-30"
                },
                {
                  "source_nm": "CVS",
                  "kpi_name": "Refill Patients",
                  "kpi_value": 56,
                  "kpi_average_name": "Refill Network Avg",
                  "kpi_average_value": 48,
                  "period": "2022-11-30"
                },
                {
                  "source_nm": "Amber",
                  "kpi_name": "New Patients",
                  "kpi_value": 14,
                  "kpi_average_name": "New Network Avg",
                  "kpi_average_value": 36,
                  "period": "2022-11-30"
                },
                {
                  "source_nm": "Amber",
                  "kpi_name": "Refill Patients",
                  "kpi_value": 45,
                  "kpi_average_name": "Refill Network Avg",
                  "kpi_average_value": 48,
                  "period": "2022-11-30"
                },
                {
                  "source_nm": "RxCrossroads",
                  "kpi_name": "New Patients",
                  "kpi_value": 30,
                  "kpi_average_name": "New Network Avg",
                  "kpi_average_value": 36,
                  "period": "2022-11-30"
                },
                {
                  "source_nm": "RxCrossroads",
                  "kpi_name": "Refill Patients",
                  "kpi_value": 52,
                  "kpi_average_name": "Refill Network Avg",
                  "kpi_average_value": 48,
                  "period": "2022-11-30"
                },
                {
                  "source_nm": "Biologics",
                  "kpi_name": "New Patients",
                  "kpi_value": 38,
                  "kpi_average_name": "New Network Avg",
                  "kpi_average_value": 29,
                  "period": "2022-12-31"
                },
                {
                  "source_nm": "Biologics",
                  "kpi_name": "Refill Patients",
                  "kpi_value": 42,
                  "kpi_average_name": "Refill Network Avg",
                  "kpi_average_value": 16,
                  "period": "2022-12-31"
                },
                {
                  "source_nm": "CVS",
                  "kpi_name": "New Patients",
                  "kpi_value": 22,
                  "kpi_average_name": "New Network Avg",
                  "kpi_average_value": 29,
                  "period": "2022-12-31"
                },
                {
                  "source_nm": "CVS",
                  "kpi_name": "Refill Patients",
                  "kpi_value": 57,
                  "kpi_average_name": "Refill Network Avg",
                  "kpi_average_value": 16,
                  "period": "2022-12-31"
                },
                {
                  "source_nm": "Amber",
                  "kpi_name": "New Patients",
                  "kpi_value": 15,
                  "kpi_average_name": "New Network Avg",
                  "kpi_average_value": 29,
                  "period": "2022-12-31"
                },
                {
                  "source_nm": "Amber",
                  "kpi_name": "Refill Patients",
                  "kpi_value": 46,
                  "kpi_average_name": "Refill Network Avg",
                  "kpi_average_value": 16,
                  "period": "2022-12-31"
                },
                {
                  "source_nm": "RxCrossroads",
                  "kpi_name": "New Patients",
                  "kpi_value": 31,
                  "kpi_average_name": "New Network Avg",
                  "kpi_average_value": 29,
                  "period": "2022-12-31"
                },
                {
                  "source_nm": "RxCrossroads",
                  "kpi_name": "Refill Patients",
                  "kpi_value": 53,
                  "kpi_average_name": "Refill Network Avg",
                  "kpi_average_value": 16,
                  "period": "2022-12-31"
                },
                {
                  "source_nm": "Biologics",
                  "kpi_name": "New Patients",
                  "kpi_value": 39,
                  "kpi_average_name": "New Network Avg",
                  "kpi_average_value": 60,
                  "period": "2023-01-31"
                },
                {
                  "source_nm": "Biologics",
                  "kpi_name": "Refill Patients",
                  "kpi_value": 43,
                  "kpi_average_name": "Refill Network Avg",
                  "kpi_average_value": 40,
                  "period": "2023-01-31"
                },
                {
                  "source_nm": "CVS",
                  "kpi_name": "New Patients",
                  "kpi_value": 23,
                  "kpi_average_name": "New Network Avg",
                  "kpi_average_value": 60,
                  "period": "2023-01-31"
                },
                {
                  "source_nm": "CVS",
                  "kpi_name": "Refill Patients",
                  "kpi_value": 58,
                  "kpi_average_name": "Refill Network Avg",
                  "kpi_average_value": 40,
                  "period": "2023-01-31"
                },
                {
                  "source_nm": "Amber",
                  "kpi_name": "New Patients",
                  "kpi_value": 16,
                  "kpi_average_name": "New Network Avg",
                  "kpi_average_value": 60,
                  "period": "2023-01-31"
                },
                {
                  "source_nm": "Amber",
                  "kpi_name": "Refill Patients",
                  "kpi_value": 47,
                  "kpi_average_name": "Refill Network Avg",
                  "kpi_average_value": 40,
                  "period": "2023-01-31"
                },
                {
                  "source_nm": "RxCrossroads",
                  "kpi_name": "New Patients",
                  "kpi_value": 32,
                  "kpi_average_name": "New Network Avg",
                  "kpi_average_value": 60,
                  "period": "2023-01-31"
                },
                {
                  "source_nm": "RxCrossroads",
                  "kpi_name": "Refill Patients",
                  "kpi_value": 54,
                  "kpi_average_name": "Refill Network Avg",
                  "kpi_average_value": 40,
                  "period": "2023-01-31"
                },
                {
                  "source_nm": "Biologics",
                  "kpi_name": "New Patients",
                  "kpi_value": 41,
                  "kpi_average_name": "New Network Avg",
                  "kpi_average_value": 36,
                  "period": "2023-02-28"
                },
                {
                  "source_nm": "Biologics",
                  "kpi_name": "Refill Patients",
                  "kpi_value": 44,
                  "kpi_average_name": "Refill Network Avg",
                  "kpi_average_value": 50,
                  "period": "2023-02-28"
                },
                {
                  "source_nm": "CVS",
                  "kpi_name": "New Patients",
                  "kpi_value": 24,
                  "kpi_average_name": "New Network Avg",
                  "kpi_average_value": 36,
                  "period": "2023-02-28"
                },
                {
                  "source_nm": "CVS",
                  "kpi_name": "Refill Patients",
                  "kpi_value": 59,
                  "kpi_average_name": "Refill Network Avg",
                  "kpi_average_value": 50,
                  "period": "2023-02-28"
                },
                {
                  "source_nm": "Amber",
                  "kpi_name": "New Patients",
                  "kpi_value": 17,
                  "kpi_average_name": "New Network Avg",
                  "kpi_average_value": 36,
                  "period": "2023-02-28"
                },
                {
                  "source_nm": "Amber",
                  "kpi_name": "Refill Patients",
                  "kpi_value": 48,
                  "kpi_average_name": "Refill Network Avg",
                  "kpi_average_value": 50,
                  "period": "2023-02-28"
                },
                {
                  "source_nm": "RxCrossroads",
                  "kpi_name": "New Patients",
                  "kpi_value": 33,
                  "kpi_average_name": "New Network Avg",
                  "kpi_average_value": 36,
                  "period": "2023-02-28"
                },
                {
                  "source_nm": "RxCrossroads",
                  "kpi_name": "Refill Patients",
                  "kpi_value": 55,
                  "kpi_average_name": "Refill Network Avg",
                  "kpi_average_value": 50,
                  "period": "2023-02-28"
                },
                {
                  "source_nm": "Biologics",
                  "kpi_name": "New Patients",
                  "kpi_value": 42,
                  "kpi_average_name": "New Network Avg",
                  "kpi_average_value": 42,
                  "period": "2023-03-31"
                },
                {
                  "source_nm": "Biologics",
                  "kpi_name": "Refill Patients",
                  "kpi_value": 45,
                  "kpi_average_name": "Refill Network Avg",
                  "kpi_average_value": 25,
                  "period": "2023-03-31"
                },
                {
                  "source_nm": "CVS",
                  "kpi_name": "New Patients",
                  "kpi_value": 25,
                  "kpi_average_name": "New Network Avg",
                  "kpi_average_value": 42,
                  "period": "2023-03-31"
                },
                {
                  "source_nm": "CVS",
                  "kpi_name": "Refill Patients",
                  "kpi_value": 60,
                  "kpi_average_name": "Refill Network Avg",
                  "kpi_average_value": 25,
                  "period": "2023-03-31"
                },
                {
                  "source_nm": "Amber",
                  "kpi_name": "New Patients",
                  "kpi_value": 18,
                  "kpi_average_name": "New Network Avg",
                  "kpi_average_value": 42,
                  "period": "2023-03-31"
                },
                {
                  "source_nm": "Amber",
                  "kpi_name": "Refill Patients",
                  "kpi_value": 49,
                  "kpi_average_name": "Refill Network Avg",
                  "kpi_average_value": 25,
                  "period": "2023-03-31"
                },
                {
                  "source_nm": "RxCrossroads",
                  "kpi_name": "New Patients",
                  "kpi_value": 34,
                  "kpi_average_name": "New Network Avg",
                  "kpi_average_value": 42,
                  "period": "2023-03-31"
                },
                {
                  "source_nm": "RxCrossroads",
                  "kpi_name": "Refill Patients",
                  "kpi_value": 56,
                  "kpi_average_name": "Refill Network Avg",
                  "kpi_average_value": 25,
                  "period": "2023-03-31"
                },
                {
                  "source_nm": "Biologics",
                  "kpi_name": "New Patients",
                  "kpi_value": 18,
                  "kpi_average_name": "New Network Avg",
                  "kpi_average_value": 22,
                  "period": "2023-04-30"
                },
                {
                  "source_nm": "Biologics",
                  "kpi_name": "Refill Patients",
                  "kpi_value": 28,
                  "kpi_average_name": "Refill Network Avg",
                  "kpi_average_value": 9,
                  "period": "2023-04-30"
                },
                {
                  "source_nm": "CVS",
                  "kpi_name": "New Patients",
                  "kpi_value": 12,
                  "kpi_average_name": "New Network Avg",
                  "kpi_average_value": 22,
                  "period": "2023-04-30"
                },
                {
                  "source_nm": "CVS",
                  "kpi_name": "Refill Patients",
                  "kpi_value": 43,
                  "kpi_average_name": "Refill Network Avg",
                  "kpi_average_value": 9,
                  "period": "2023-04-30"
                },
                {
                  "source_nm": "Amber",
                  "kpi_name": "New Patients",
                  "kpi_value": 6,
                  "kpi_average_name": "New Network Avg",
                  "kpi_average_value": 22,
                  "period": "2023-04-30"
                },
                {
                  "source_nm": "Amber",
                  "kpi_name": "Refill Patients",
                  "kpi_value": 37,
                  "kpi_average_name": "Refill Network Avg",
                  "kpi_average_value": 9,
                  "period": "2023-04-30"
                },
                {
                  "source_nm": "RxCrossroads",
                  "kpi_name": "New Patients",
                  "kpi_value": 7,
                  "kpi_average_name": "New Network Avg",
                  "kpi_average_value": 22,
                  "period": "2023-04-30"
                },
                {
                  "source_nm": "RxCrossroads",
                  "kpi_name": "Refill Patients",
                  "kpi_value": 36,
                  "kpi_average_name": "Refill Network Avg",
                  "kpi_average_value": 9,
                  "period": "2023-04-30"
                },
                {
                  "source_nm": "Biologics",
                  "kpi_name": "New Patients",
                  "kpi_value": 17,
                  "kpi_average_name": "New Network Avg",
                  "kpi_average_value": 10,
                  "period": "2023-05-31"
                },
                {
                  "source_nm": "Biologics",
                  "kpi_name": "Refill Patients",
                  "kpi_value": 29,
                  "kpi_average_name": "Refill Network Avg",
                  "kpi_average_value": 23,
                  "period": "2023-05-31"
                },
                {
                  "source_nm": "CVS",
                  "kpi_name": "New Patients",
                  "kpi_value": 14,
                  "kpi_average_name": "New Network Avg",
                  "kpi_average_value": 10,
                  "period": "2023-05-31"
                },
                {
                  "source_nm": "CVS",
                  "kpi_name": "Refill Patients",
                  "kpi_value": 44,
                  "kpi_average_name": "Refill Network Avg",
                  "kpi_average_value": 23,
                  "period": "2023-05-31"
                },
                {
                  "source_nm": "Amber",
                  "kpi_name": "New Patients",
                  "kpi_value": 8,
                  "kpi_average_name": "New Network Avg",
                  "kpi_average_value": 10,
                  "period": "2023-05-31"
                },
                {
                  "source_nm": "Amber",
                  "kpi_name": "Refill Patients",
                  "kpi_value": 38,
                  "kpi_average_name": "Refill Network Avg",
                  "kpi_average_value": 23,
                  "period": "2023-05-31"
                },
                {
                  "source_nm": "RxCrossroads",
                  "kpi_name": "New Patients",
                  "kpi_value": 9,
                  "kpi_average_name": "New Network Avg",
                  "kpi_average_value": 10,
                  "period": "2023-05-31"
                },
                {
                  "source_nm": "RxCrossroads",
                  "kpi_name": "Refill Patients",
                  "kpi_value": 34,
                  "kpi_average_name": "Refill Network Avg",
                  "kpi_average_value": 23,
                  "period": "2023-05-31"
                },
                {
                  "source_nm": "Biologics",
                  "kpi_name": "New Patients",
                  "kpi_value": 19,
                  "kpi_average_name": "New Network Avg",
                  "kpi_average_value": 21,
                  "period": "2023-06-30"
                },
                {
                  "source_nm": "Biologics",
                  "kpi_name": "Refill Patients",
                  "kpi_value": 30,
                  "kpi_average_name": "Refill Network Avg",
                  "kpi_average_value": 11,
                  "period": "2023-06-30"
                },
                {
                  "source_nm": "CVS",
                  "kpi_name": "New Patients",
                  "kpi_value": 13,
                  "kpi_average_name": "New Network Avg",
                  "kpi_average_value": 21,
                  "period": "2023-06-30"
                },
                {
                  "source_nm": "CVS",
                  "kpi_name": "Refill Patients",
                  "kpi_value": 42,
                  "kpi_average_name": "Refill Network Avg",
                  "kpi_average_value": 11,
                  "period": "2023-06-30"
                },
                {
                  "source_nm": "Amber",
                  "kpi_name": "New Patients",
                  "kpi_value": 7,
                  "kpi_average_name": "New Network Avg",
                  "kpi_average_value": 21,
                  "period": "2023-06-30"
                },
                {
                  "source_nm": "Amber",
                  "kpi_name": "Refill Patients",
                  "kpi_value": 33,
                  "kpi_average_name": "Refill Network Avg",
                  "kpi_average_value": 11,
                  "period": "2023-06-30"
                },
                {
                  "source_nm": "RxCrossroads",
                  "kpi_name": "New Patients",
                  "kpi_value": 12,
                  "kpi_average_name": "New Network Avg",
                  "kpi_average_value": 21,
                  "period": "2023-06-30"
                },
                {
                  "source_nm": "RxCrossroads",
                  "kpi_name": "Refill Patients",
                  "kpi_value": 39,
                  "kpi_average_name": "Refill Network Avg",
                  "kpi_average_value": 11,
                  "period": "2023-06-30"
                },
                {
                  "source_nm": "Biologics",
                  "kpi_name": "New Patients",
                  "kpi_value": 16,
                  "kpi_average_name": "New Network Avg",
                  "kpi_average_value": 43,
                  "period": "2023-07-31"
                },
                {
                  "source_nm": "Biologics",
                  "kpi_name": "Refill Patients",
                  "kpi_value": 32,
                  "kpi_average_name": "Refill Network Avg",
                  "kpi_average_value": 24,
                  "period": "2023-07-31"
                },
                {
                  "source_nm": "CVS",
                  "kpi_name": "New Patients",
                  "kpi_value": 15,
                  "kpi_average_name": "New Network Avg",
                  "kpi_average_value": 43,
                  "period": "2023-07-31"
                },
                {
                  "source_nm": "CVS",
                  "kpi_name": "Refill Patients",
                  "kpi_value": 46,
                  "kpi_average_name": "Refill Network Avg",
                  "kpi_average_value": 24,
                  "period": "2023-07-31"
                },
                {
                  "source_nm": "Amber",
                  "kpi_name": "New Patients",
                  "kpi_value": 9,
                  "kpi_average_name": "New Network Avg",
                  "kpi_average_value": 43,
                  "period": "2023-07-31"
                },
                {
                  "source_nm": "Amber",
                  "kpi_name": "Refill Patients",
                  "kpi_value": 41,
                  "kpi_average_name": "Refill Network Avg",
                  "kpi_average_value": 24,
                  "period": "2023-07-31"
                },
                {
                  "source_nm": "RxCrossroads",
                  "kpi_name": "New Patients",
                  "kpi_value": 14,
                  "kpi_average_name": "New Network Avg",
                  "kpi_average_value": 43,
                  "period": "2023-07-31"
                },
                {
                  "source_nm": "RxCrossroads",
                  "kpi_name": "Refill Patients",
                  "kpi_value": 43,
                  "kpi_average_name": "Refill Network Avg",
                  "kpi_average_value": 24,
                  "period": "2023-07-31"
                },
                {
                  "source_nm": "Biologics",
                  "kpi_name": "New Patients",
                  "kpi_value": 25,
                  "kpi_average_name": "New Network Avg",
                  "kpi_average_value": 20,
                  "period": "2023-08-31"
                },
                {
                  "source_nm": "Biologics",
                  "kpi_name": "Refill Patients",
                  "kpi_value": 33,
                  "kpi_average_name": "Refill Network Avg",
                  "kpi_average_value": 0,
                  "period": "2023-08-31"
                },
                {
                  "source_nm": "CVS",
                  "kpi_name": "New Patients",
                  "kpi_value": 18,
                  "kpi_average_name": "New Network Avg",
                  "kpi_average_value": 20,
                  "period": "2023-08-31"
                },
                {
                  "source_nm": "CVS",
                  "kpi_name": "Refill Patients",
                  "kpi_value": 47,
                  "kpi_average_name": "Refill Network Avg",
                  "kpi_average_value": 10,
                  "period": "2023-08-31"
                },
                {
                  "source_nm": "Amber",
                  "kpi_name": "New Patients",
                  "kpi_value": 11,
                  "kpi_average_name": "New Network Avg",
                  "kpi_average_value": 20,
                  "period": "2023-08-31"
                },
                {
                  "source_nm": "Amber",
                  "kpi_name": "Refill Patients",
                  "kpi_value": 42,
                  "kpi_average_name": "Refill Network Avg",
                  "kpi_average_value": 10,
                  "period": "2023-08-31"
                },
                {
                  "source_nm": "RxCrossroads",
                  "kpi_name": "New Patients",
                  "kpi_value": 19,
                  "kpi_average_name": "New Network Avg",
                  "kpi_average_value": 20,
                  "period": "2023-08-31"
                },
                {
                  "source_nm": "RxCrossroads",
                  "kpi_name": "Refill Patients",
                  "kpi_value": 44,
                  "kpi_average_name": "Refill Network Avg",
                  "kpi_average_value": 10,
                  "period": "2023-08-31"
                }
              ]
            } else {
              res = [
                {
                    "source_nm": "Biologics",
                    "kpi_name": "Active",
                    "kpi_value": 18,
                    "kpi_average_name": "Active Avg",
                    "kpi_average_value": 22,
                    "period": "2021-09-30"
                },
                {
                    "source_nm": "Biologics",
                    "kpi_name": "Pending",
                    "kpi_value": 28,
                    "kpi_average_name": "Pending Avg",
                    "kpi_average_value": 20,
                    "period": "2021-09-30"
                },
                {
                    "source_nm": "Biologics",
                    "kpi_name": "Discontinued",
                    "kpi_value": 28,
                    "kpi_average_name": "Discontinued Avg",
                    "kpi_average_value": 22,
                    "period": "2021-09-30"
                },
                {
                    "source_nm": "Biologics",
                    "kpi_name": "Cancelled",
                    "kpi_value": 9,
                    "kpi_average_name": "Cancelled Avg",
                    "kpi_average_value": 15,
                    "period": "2021-09-30"
                },
                {
                    "source_nm": "CVS",
                    "kpi_name": "Active",
                    "kpi_value": 30,
                    "kpi_average_name": "Active Avg",
                    "kpi_average_value": 22,
                    "period": "2021-09-30"
                },
                {
                    "source_nm": "CVS",
                    "kpi_name": "Pending",
                    "kpi_value": 20,
                    "kpi_average_name": "Pending Avg",
                    "kpi_average_value": 20,
                    "period": "2021-09-30"
                },
                {
                    "source_nm": "CVS",
                    "kpi_name": "Discontinued",
                    "kpi_value": 25,
                    "kpi_average_name": "Discontinued Avg",
                    "kpi_average_value": 22,
                    "period": "2021-09-30"
                },
                {
                    "source_nm": "CVS",
                    "kpi_name": "Cancelled",
                    "kpi_value": 19,
                    "kpi_average_name": "Cancelled Avg",
                    "kpi_average_value": 15,
                    "period": "2021-09-30"
                },
                {
                    "source_nm": "Amber",
                    "kpi_name": "Active",
                    "kpi_value": 25,
                    "kpi_average_name": "Active Avg",
                    "kpi_average_value": 22,
                    "period": "2021-09-30"
                },
                {
                    "source_nm": "Amber",
                    "kpi_name": "Pending",
                    "kpi_value": 10,
                    "kpi_average_name": "Pending Avg",
                    "kpi_average_value": 20,
                    "period": "2021-09-30"
                },
                {
                    "source_nm": "Amber",
                    "kpi_name": "Discontinued",
                    "kpi_value": 35,
                    "kpi_average_name": "Discontinued Avg",
                    "kpi_average_value": 22,
                    "period": "2021-09-30"
                },
                {
                    "source_nm": "Amber",
                    "kpi_name": "Cancelled",
                    "kpi_value": 13,
                    "kpi_average_name": "Cancelled Avg",
                    "kpi_average_value": 15,
                    "period": "2021-09-30"
                },
                {
                    "source_nm": "RxCrossroads",
                    "kpi_name": "Active",
                    "kpi_value": 43,
                    "kpi_average_name": "Active Avg",
                    "kpi_average_value": 22,
                    "period": "2021-09-30"
                },
                {
                    "source_nm": "RxCrossroads",
                    "kpi_name": "Pending",
                    "kpi_value": 5,
                    "kpi_average_name": "Pending Avg",
                    "kpi_average_value": 20,
                    "period": "2021-09-30"
                },
                {
                    "source_nm": "RxCrossroads",
                    "kpi_name": "Discontinued",
                    "kpi_value": 12,
                    "kpi_average_name": "Discontinued Avg",
                    "kpi_average_value": 22,
                    "period": "2021-09-30"
                },
                {
                    "source_nm": "RxCrossroads",
                    "kpi_name": "Cancelled",
                    "kpi_value": 23,
                    "kpi_average_name": "Cancelled Avg",
                    "kpi_average_value": 15,
                    "period": "2021-09-30"
                },
                {
                    "source_nm": "Biologics",
                    "kpi_name": "Active",
                    "kpi_value": 32,
                    "kpi_average_name": "Active Avg",
                    "kpi_average_value": 43,
                    "period": "2021-10-31"
                },
                {
                    "source_nm": "Biologics",
                    "kpi_name": "Pending",
                    "kpi_value": 28,
                    "kpi_average_name": "Pending Avg",
                    "kpi_average_value": 15,
                    "period": "2021-10-31"
                },
                {
                    "source_nm": "Biologics",
                    "kpi_name": "Discontinued",
                    "kpi_value": 30,
                    "kpi_average_name": "Discontinued Avg",
                    "kpi_average_value": 23,
                    "period": "2021-10-31"
                },
                {
                    "source_nm": "Biologics",
                    "kpi_name": "Cancelled",
                    "kpi_value": 9,
                    "kpi_average_name": "Cancelled Avg",
                    "kpi_average_value": 35,
                    "period": "2021-10-31"
                },
                {
                    "source_nm": "CVS",
                    "kpi_name": "Active",
                    "kpi_value": 23,
                    "kpi_average_name": "Active Avg",
                    "kpi_average_value": 43,
                    "period": "2021-10-31"
                },
                {
                    "source_nm": "CVS",
                    "kpi_name": "Pending",
                    "kpi_value": 10,
                    "kpi_average_name": "Pending Avg",
                    "kpi_average_value": 15,
                    "period": "2021-10-31"
                },
                {
                    "source_nm": "CVS",
                    "kpi_name": "Discontinued",
                    "kpi_value": 21,
                    "kpi_average_name": "Discontinued Avg",
                    "kpi_average_value": 23,
                    "period": "2021-10-31"
                },
                {
                    "source_nm": "CVS",
                    "kpi_name": "Cancelled",
                    "kpi_value": 39,
                    "kpi_average_name": "Cancelled Avg",
                    "kpi_average_value": 35,
                    "period": "2021-10-31"
                },
                {
                    "source_nm": "Amber",
                    "kpi_name": "Active",
                    "kpi_value": 50,
                    "kpi_average_name": "Active Avg",
                    "kpi_average_value": 43,
                    "period": "2021-10-31"
                },
                {
                    "source_nm": "Amber",
                    "kpi_name": "Pending",
                    "kpi_value": 5,
                    "kpi_average_name": "Pending Avg",
                    "kpi_average_value": 15,
                    "period": "2021-10-31"
                },
                {
                    "source_nm": "Amber",
                    "kpi_name": "Discontinued",
                    "kpi_value": 15,
                    "kpi_average_name": "Discontinued Avg",
                    "kpi_average_value": 23,
                    "period": "2021-10-31"
                },
                {
                    "source_nm": "Amber",
                    "kpi_name": "Cancelled",
                    "kpi_value": 59,
                    "kpi_average_name": "Cancelled Avg",
                    "kpi_average_value": 35,
                    "period": "2021-10-31"
                },
                {
                    "source_nm": "RxCrossroads",
                    "kpi_name": "Active",
                    "kpi_value": 11,
                    "kpi_average_name": "Active Avg",
                    "kpi_average_value": 43,
                    "period": "2021-10-31"
                },
                {
                    "source_nm": "RxCrossroads",
                    "kpi_name": "Pending",
                    "kpi_value": 15,
                    "kpi_average_name": "Pending Avg",
                    "kpi_average_value": 15,
                    "period": "2021-10-31"
                },
                {
                    "source_nm": "RxCrossroads",
                    "kpi_name": "Discontinued",
                    "kpi_value": 9,
                    "kpi_average_name": "Discontinued Avg",
                    "kpi_average_value": 23,
                    "period": "2021-10-31"
                },
                {
                    "source_nm": "RxCrossroads",
                    "kpi_name": "Cancelled",
                    "kpi_value": 39,
                    "kpi_average_name": "Cancelled Avg",
                    "kpi_average_value": 35,
                    "period": "2021-10-31"
                },
                {
                    "source_nm": "Biologics",
                    "kpi_name": "Active",
                    "kpi_value": 34,
                    "kpi_average_name": "Active Avg",
                    "kpi_average_value": 28,
                    "period": "2021-11-30"
                },
                {
                    "source_nm": "Biologics",
                    "kpi_name": "Pending",
                    "kpi_value": 24,
                    "kpi_average_name": "Pending Avg",
                    "kpi_average_value": 22,
                    "period": "2021-11-30"
                },
                {
                    "source_nm": "Biologics",
                    "kpi_name": "Discontinued",
                    "kpi_value": 40,
                    "kpi_average_name": "Discontinued Avg",
                    "kpi_average_value": 31,
                    "period": "2021-11-30"
                },
                {
                    "source_nm": "Biologics",
                    "kpi_name": "Cancelled",
                    "kpi_value": 15,
                    "kpi_average_name": "Cancelled Avg",
                    "kpi_average_value": 27,
                    "period": "2021-11-30"
                },
                {
                    "source_nm": "CVS",
                    "kpi_name": "Active",
                    "kpi_value": 29,
                    "kpi_average_name": "Active Avg",
                    "kpi_average_value": 28,
                    "period": "2021-11-30"
                },
                {
                    "source_nm": "CVS",
                    "kpi_name": "Pending",
                    "kpi_value": 18,
                    "kpi_average_name": "Pending Avg",
                    "kpi_average_value": 22,
                    "period": "2021-11-30"
                },
                {
                    "source_nm": "CVS",
                    "kpi_name": "Discontinued",
                    "kpi_value": 27,
                    "kpi_average_name": "Discontinued Avg",
                    "kpi_average_value": 31,
                    "period": "2021-11-30"
                },
                {
                    "source_nm": "CVS",
                    "kpi_name": "Cancelled",
                    "kpi_value": 20,
                    "kpi_average_name": "Cancelled Avg",
                    "kpi_average_value": 27,
                    "period": "2021-11-30"
                },
                {
                    "source_nm": "Amber",
                    "kpi_name": "Active",
                    "kpi_value": 26,
                    "kpi_average_name": "Active Avg",
                    "kpi_average_value": 28,
                    "period": "2021-11-30"
                },
                {
                    "source_nm": "Amber",
                    "kpi_name": "Pending",
                    "kpi_value": 13,
                    "kpi_average_name": "Pending Avg",
                    "kpi_average_value": 22,
                    "period": "2021-11-30"
                },
                {
                    "source_nm": "Amber",
                    "kpi_name": "Discontinued",
                    "kpi_value": 33,
                    "kpi_average_name": "Discontinued Avg",
                    "kpi_average_value": 31,
                    "period": "2021-11-30"
                },
                {
                    "source_nm": "Amber",
                    "kpi_name": "Cancelled",
                    "kpi_value": 12,
                    "kpi_average_name": "Cancelled Avg",
                    "kpi_average_value": 27,
                    "period": "2021-11-30"
                },
                {
                    "source_nm": "RxCrossroads",
                    "kpi_name": "Active",
                    "kpi_value": 19,
                    "kpi_average_name": "Active Avg",
                    "kpi_average_value": 28,
                    "period": "2021-11-30"
                },
                {
                    "source_nm": "RxCrossroads",
                    "kpi_name": "Pending",
                    "kpi_value": 8,
                    "kpi_average_name": "Pending Avg",
                    "kpi_average_value": 22,
                    "period": "2021-11-30"
                },
                {
                    "source_nm": "RxCrossroads",
                    "kpi_name": "Discontinued",
                    "kpi_value": 10,
                    "kpi_average_name": "Discontinued Avg",
                    "kpi_average_value": 31,
                    "period": "2021-11-30"
                },
                {
                    "source_nm": "RxCrossroads",
                    "kpi_name": "Cancelled",
                    "kpi_value": 21,
                    "kpi_average_name": "Cancelled Avg",
                    "kpi_average_value": 27,
                    "period": "2021-11-30"
                },
                {
                    "source_nm": "Biologics",
                    "kpi_name": "Active",
                    "kpi_value": 36,
                    "kpi_average_name": "Active Avg",
                    "kpi_average_value": 30,
                    "period": "2021-12-31"
                },
                {
                    "source_nm": "Biologics",
                    "kpi_name": "Pending",
                    "kpi_value": 20,
                    "kpi_average_name": "Pending Avg",
                    "kpi_average_value": 18,
                    "period": "2021-12-31"
                },
                {
                    "source_nm": "Biologics",
                    "kpi_name": "Discontinued",
                    "kpi_value": 32,
                    "kpi_average_name": "Discontinued Avg",
                    "kpi_average_value": 28,
                    "period": "2021-12-31"
                },
                {
                    "source_nm": "Biologics",
                    "kpi_name": "Cancelled",
                    "kpi_value": 16,
                    "kpi_average_name": "Cancelled Avg",
                    "kpi_average_value": 20,
                    "period": "2021-12-31"
                },
                {
                    "source_nm": "CVS",
                    "kpi_name": "Active",
                    "kpi_value": 24,
                    "kpi_average_name": "Active Avg",
                    "kpi_average_value": 30,
                    "period": "2021-12-31"
                },
                {
                    "source_nm": "CVS",
                    "kpi_name": "Pending",
                    "kpi_value": 12,
                    "kpi_average_name": "Pending Avg",
                    "kpi_average_value": 18,
                    "period": "2021-12-31"
                },
                {
                    "source_nm": "CVS",
                    "kpi_name": "Discontinued",
                    "kpi_value": 23,
                    "kpi_average_name": "Discontinued Avg",
                    "kpi_average_value": 28,
                    "period": "2021-12-31"
                },
                {
                    "source_nm": "CVS",
                    "kpi_name": "Cancelled",
                    "kpi_value": 22,
                    "kpi_average_name": "Cancelled Avg",
                    "kpi_average_value": 20,
                    "period": "2021-12-31"
                },
                {
                    "source_nm": "Amber",
                    "kpi_name": "Active",
                    "kpi_value": 29,
                    "kpi_average_name": "Active Avg",
                    "kpi_average_value": 30,
                    "period": "2021-12-31"
                },
                {
                    "source_nm": "Amber",
                    "kpi_name": "Pending",
                    "kpi_value": 15,
                    "kpi_average_name": "Pending Avg",
                    "kpi_average_value": 18,
                    "period": "2021-12-31"
                },
                {
                    "source_nm": "Amber",
                    "kpi_name": "Discontinued",
                    "kpi_value": 25,
                    "kpi_average_name": "Discontinued Avg",
                    "kpi_average_value": 28,
                    "period": "2021-12-31"
                },
                {
                    "source_nm": "Amber",
                    "kpi_name": "Cancelled",
                    "kpi_value": 14,
                    "kpi_average_name": "Cancelled Avg",
                    "kpi_average_value": 20,
                    "period": "2021-12-31"
                },
                {
                    "source_nm": "RxCrossroads",
                    "kpi_name": "Active",
                    "kpi_value": 18,
                    "kpi_average_name": "Active Avg",
                    "kpi_average_value": 30,
                    "period": "2021-12-31"
                },
                {
                    "source_nm": "RxCrossroads",
                    "kpi_name": "Pending",
                    "kpi_value": 10,
                    "kpi_average_name": "Pending Avg",
                    "kpi_average_value": 18,
                    "period": "2021-12-31"
                },
                {
                    "source_nm": "RxCrossroads",
                    "kpi_name": "Discontinued",
                    "kpi_value": 12,
                    "kpi_average_name": "Discontinued Avg",
                    "kpi_average_value": 28,
                    "period": "2021-12-31"
                },
                {
                    "source_nm": "RxCrossroads",
                    "kpi_name": "Cancelled",
                    "kpi_value": 27,
                    "kpi_average_name": "Cancelled Avg",
                    "kpi_average_value": 20,
                    "period": "2021-12-31"
                },
                {
                    "source_nm": "Biologics",
                    "kpi_name": "Active",
                    "kpi_value": 40,
                    "kpi_average_name": "Active Avg",
                    "kpi_average_value": 28,
                    "period": "2022-01-31"
                },
                {
                    "source_nm": "Biologics",
                    "kpi_name": "Pending",
                    "kpi_value": 18,
                    "kpi_average_name": "Pending Avg",
                    "kpi_average_value": 25,
                    "period": "2022-01-31"
                },
                {
                    "source_nm": "Biologics",
                    "kpi_name": "Discontinued",
                    "kpi_value": 30,
                    "kpi_average_name": "Discontinued Avg",
                    "kpi_average_value": 20,
                    "period": "2022-01-31"
                },
                {
                    "source_nm": "Biologics",
                    "kpi_name": "Cancelled",
                    "kpi_value": 19,
                    "kpi_average_name": "Cancelled Avg",
                    "kpi_average_value": 22,
                    "period": "2022-01-31"
                },
                {
                    "source_nm": "CVS",
                    "kpi_name": "Active",
                    "kpi_value": 34,
                    "kpi_average_name": "Active Avg",
                    "kpi_average_value": 28,
                    "period": "2022-01-31"
                },
                {
                    "source_nm": "CVS",
                    "kpi_name": "Pending",
                    "kpi_value": 16,
                    "kpi_average_name": "Pending Avg",
                    "kpi_average_value": 25,
                    "period": "2022-01-31"
                },
                {
                    "source_nm": "CVS",
                    "kpi_name": "Discontinued",
                    "kpi_value": 28,
                    "kpi_average_name": "Discontinued Avg",
                    "kpi_average_value": 20,
                    "period": "2022-01-31"
                },
                {
                    "source_nm": "CVS",
                    "kpi_name": "Cancelled",
                    "kpi_value": 10,
                    "kpi_average_name": "Cancelled Avg",
                    "kpi_average_value": 22,
                    "period": "2022-01-31"
                },
                {
                    "source_nm": "Amber",
                    "kpi_name": "Active",
                    "kpi_value": 22,
                    "kpi_average_name": "Active Avg",
                    "kpi_average_value": 28,
                    "period": "2022-01-31"
                },
                {
                    "source_nm": "Amber",
                    "kpi_name": "Pending",
                    "kpi_value": 12,
                    "kpi_average_name": "Pending Avg",
                    "kpi_average_value": 25,
                    "period": "2022-01-31"
                },
                {
                    "source_nm": "Amber",
                    "kpi_name": "Discontinued",
                    "kpi_value": 20,
                    "kpi_average_name": "Discontinued Avg",
                    "kpi_average_value": 20,
                    "period": "2022-01-31"
                },
                {
                    "source_nm": "Amber",
                    "kpi_name": "Cancelled",
                    "kpi_value": 11,
                    "kpi_average_name": "Cancelled Avg",
                    "kpi_average_value": 22,
                    "period": "2022-01-31"
                },
                {
                    "source_nm": "RxCrossroads",
                    "kpi_name": "Active",
                    "kpi_value": 12,
                    "kpi_average_name": "Active Avg",
                    "kpi_average_value": 28,
                    "period": "2022-01-31"
                },
                {
                    "source_nm": "RxCrossroads",
                    "kpi_name": "Pending",
                    "kpi_value": 14,
                    "kpi_average_name": "Pending Avg",
                    "kpi_average_value": 25,
                    "period": "2022-01-31"
                },
                {
                    "source_nm": "RxCrossroads",
                    "kpi_name": "Discontinued",
                    "kpi_value": 18,
                    "kpi_average_name": "Discontinued Avg",
                    "kpi_average_value": 20,
                    "period": "2022-01-31"
                },
                {
                    "source_nm": "RxCrossroads",
                    "kpi_name": "Cancelled",
                    "kpi_value": 30,
                    "kpi_average_name": "Cancelled Avg",
                    "kpi_average_value": 22,
                    "period": "2022-01-31"
                },
                {
                    "source_nm": "Biologics",
                    "kpi_name": "Active",
                    "kpi_value": 29,
                    "kpi_average_name": "Active Avg",
                    "kpi_average_value": 25,
                    "period": "2022-02-28"
                },
                {
                    "source_nm": "Biologics",
                    "kpi_name": "Pending",
                    "kpi_value": 15,
                    "kpi_average_name": "Pending Avg",
                    "kpi_average_value": 20,
                    "period": "2022-02-28"
                },
                {
                    "source_nm": "Biologics",
                    "kpi_name": "Discontinued",
                    "kpi_value": 20,
                    "kpi_average_name": "Discontinued Avg",
                    "kpi_average_value": 22,
                    "period": "2022-02-28"
                },
                {
                    "source_nm": "Biologics",
                    "kpi_name": "Cancelled",
                    "kpi_value": 12,
                    "kpi_average_name": "Cancelled Avg",
                    "kpi_average_value": 15,
                    "period": "2022-02-28"
                },
                {
                    "source_nm": "CVS",
                    "kpi_name": "Active",
                    "kpi_value": 33,
                    "kpi_average_name": "Active Avg",
                    "kpi_average_value": 25,
                    "period": "2022-02-28"
                },
                {
                    "source_nm": "CVS",
                    "kpi_name": "Pending",
                    "kpi_value": 18,
                    "kpi_average_name": "Pending Avg",
                    "kpi_average_value": 20,
                    "period": "2022-02-28"
                },
                {
                    "source_nm": "CVS",
                    "kpi_name": "Discontinued",
                    "kpi_value": 25,
                    "kpi_average_name": "Discontinued Avg",
                    "kpi_average_value": 22,
                    "period": "2022-02-28"
                },
                {
                    "source_nm": "CVS",
                    "kpi_name": "Cancelled",
                    "kpi_value": 17,
                    "kpi_average_name": "Cancelled Avg",
                    "kpi_average_value": 15,
                    "period": "2022-02-28"
                },
                {
                    "source_nm": "Amber",
                    "kpi_name": "Active",
                    "kpi_value": 30,
                    "kpi_average_name": "Active Avg",
                    "kpi_average_value": 25,
                    "period": "2022-02-28"
                },
                {
                    "source_nm": "Amber",
                    "kpi_name": "Pending",
                    "kpi_value": 11,
                    "kpi_average_name": "Pending Avg",
                    "kpi_average_value": 20,
                    "period": "2022-02-28"
                },
                {
                    "source_nm": "Amber",
                    "kpi_name": "Discontinued",
                    "kpi_value": 28,
                    "kpi_average_name": "Discontinued Avg",
                    "kpi_average_value": 22,
                    "period": "2022-02-28"
                },
                {
                    "source_nm": "Amber",
                    "kpi_name": "Cancelled",
                    "kpi_value": 14,
                    "kpi_average_name": "Cancelled Avg",
                    "kpi_average_value": 15,
                    "period": "2022-02-28"
                },
                {
                    "source_nm": "RxCrossroads",
                    "kpi_name": "Active",
                    "kpi_value": 18,
                    "kpi_average_name": "Active Avg",
                    "kpi_average_value": 25,
                    "period": "2022-02-28"
                },
                {
                    "source_nm": "RxCrossroads",
                    "kpi_name": "Pending",
                    "kpi_value": 8,
                    "kpi_average_name": "Pending Avg",
                    "kpi_average_value": 20,
                    "period": "2022-02-28"
                },
                {
                    "source_nm": "RxCrossroads",
                    "kpi_name": "Discontinued",
                    "kpi_value": 16,
                    "kpi_average_name": "Discontinued Avg",
                    "kpi_average_value": 22,
                    "period": "2022-02-28"
                },
                {
                    "source_nm": "RxCrossroads",
                    "kpi_name": "Cancelled",
                    "kpi_value": 20,
                    "kpi_average_name": "Cancelled Avg",
                    "kpi_average_value": 15,
                    "period": "2022-02-28"
                },
                {
                    "source_nm": "Biologics",
                    "kpi_name": "Active",
                    "kpi_value": 38,
                    "kpi_average_name": "Active Avg",
                    "kpi_average_value": 26,
                    "period": "2022-03-31"
                },
                {
                    "source_nm": "Biologics",
                    "kpi_name": "Pending",
                    "kpi_value": 20,
                    "kpi_average_name": "Pending Avg",
                    "kpi_average_value": 22,
                    "period": "2022-03-31"
                },
                {
                    "source_nm": "Biologics",
                    "kpi_name": "Discontinued",
                    "kpi_value": 30,
                    "kpi_average_name": "Discontinued Avg",
                    "kpi_average_value": 24,
                    "period": "2022-03-31"
                },
                {
                    "source_nm": "Biologics",
                    "kpi_name": "Cancelled",
                    "kpi_value": 14,
                    "kpi_average_name": "Cancelled Avg",
                    "kpi_average_value": 16,
                    "period": "2022-03-31"
                },
                {
                    "source_nm": "CVS",
                    "kpi_name": "Active",
                    "kpi_value": 21,
                    "kpi_average_name": "Active Avg",
                    "kpi_average_value": 26,
                    "period": "2022-03-31"
                },
                {
                    "source_nm": "CVS",
                    "kpi_name": "Pending",
                    "kpi_value": 16,
                    "kpi_average_name": "Pending Avg",
                    "kpi_average_value": 22,
                    "period": "2022-03-31"
                },
                {
                    "source_nm": "CVS",
                    "kpi_name": "Discontinued",
                    "kpi_value": 19,
                    "kpi_average_name": "Discontinued Avg",
                    "kpi_average_value": 24,
                    "period": "2022-03-31"
                },
                {
                    "source_nm": "CVS",
                    "kpi_name": "Cancelled",
                    "kpi_value": 15,
                    "kpi_average_name": "Cancelled Avg",
                    "kpi_average_value": 16,
                    "period": "2022-03-31"
                },
                {
                    "source_nm": "Amber",
                    "kpi_name": "Active",
                    "kpi_value": 25,
                    "kpi_average_name": "Active Avg",
                    "kpi_average_value": 26,
                    "period": "2022-03-31"
                },
                {
                    "source_nm": "Amber",
                    "kpi_name": "Pending",
                    "kpi_value": 10,
                    "kpi_average_name": "Pending Avg",
                    "kpi_average_value": 22,
                    "period": "2022-03-31"
                },
                {
                    "source_nm": "Amber",
                    "kpi_name": "Discontinued",
                    "kpi_value": 20,
                    "kpi_average_name": "Discontinued Avg",
                    "kpi_average_value": 24,
                    "period": "2022-03-31"
                },
                {
                    "source_nm": "Amber",
                    "kpi_name": "Cancelled",
                    "kpi_value": 12,
                    "kpi_average_name": "Cancelled Avg",
                    "kpi_average_value": 16,
                    "period": "2022-03-31"
                },
                {
                    "source_nm": "RxCrossroads",
                    "kpi_name": "Active",
                    "kpi_value": 30,
                    "kpi_average_name": "Active Avg",
                    "kpi_average_value": 26,
                    "period": "2022-03-31"
                },
                {
                    "source_nm": "RxCrossroads",
                    "kpi_name": "Pending",
                    "kpi_value": 12,
                    "kpi_average_name": "Pending Avg",
                    "kpi_average_value": 22,
                    "period": "2022-03-31"
                },
                {
                    "source_nm": "RxCrossroads",
                    "kpi_name": "Discontinued",
                    "kpi_value": 15,
                    "kpi_average_name": "Discontinued Avg",
                    "kpi_average_value": 24,
                    "period": "2022-03-31"
                },
                {
                    "source_nm": "RxCrossroads",
                    "kpi_name": "Cancelled",
                    "kpi_value": 18,
                    "kpi_average_name": "Cancelled Avg",
                    "kpi_average_value": 16,
                    "period": "2022-03-31"
                },
                {
                    "source_nm": "Biologics",
                    "kpi_name": "Active",
                    "kpi_value": 40,
                    "kpi_average_name": "Active Avg",
                    "kpi_average_value": 27,
                    "period": "2022-04-30"
                },
                {
                    "source_nm": "Biologics",
                    "kpi_name": "Pending",
                    "kpi_value": 24,
                    "kpi_average_name": "Pending Avg",
                    "kpi_average_value": 23,
                    "period": "2022-04-30"
                },
                {
                    "source_nm": "Biologics",
                    "kpi_name": "Discontinued",
                    "kpi_value": 33,
                    "kpi_average_name": "Discontinued Avg",
                    "kpi_average_value": 25,
                    "period": "2022-04-30"
                },
                {
                    "source_nm": "Biologics",
                    "kpi_name": "Cancelled",
                    "kpi_value": 20,
                    "kpi_average_name": "Cancelled Avg",
                    "kpi_average_value": 17,
                    "period": "2022-04-30"
                },
                {
                    "source_nm": "CVS",
                    "kpi_name": "Active",
                    "kpi_value": 30,
                    "kpi_average_name": "Active Avg",
                    "kpi_average_value": 27,
                    "period": "2022-04-30"
                },
                {
                    "source_nm": "CVS",
                    "kpi_name": "Pending",
                    "kpi_value": 19,
                    "kpi_average_name": "Pending Avg",
                    "kpi_average_value": 23,
                    "period": "2022-04-30"
                },
                {
                    "source_nm": "CVS",
                    "kpi_name": "Discontinued",
                    "kpi_value": 21,
                    "kpi_average_name": "Discontinued Avg",
                    "kpi_average_value": 25,
                    "period": "2022-04-30"
                },
                {
                    "source_nm": "CVS",
                    "kpi_name": "Cancelled",
                    "kpi_value": 14,
                    "kpi_average_name": "Cancelled Avg",
                    "kpi_average_value": 17,
                    "period": "2022-04-30"
                },
                {
                    "source_nm": "Amber",
                    "kpi_name": "Active",
                    "kpi_value": 22,
                    "kpi_average_name": "Active Avg",
                    "kpi_average_value": 27,
                    "period": "2022-04-30"
                },
                {
                    "source_nm": "Amber",
                    "kpi_name": "Pending",
                    "kpi_value": 12,
                    "kpi_average_name": "Pending Avg",
                    "kpi_average_value": 23,
                    "period": "2022-04-30"
                },
                {
                    "source_nm": "Amber",
                    "kpi_name": "Discontinued",
                    "kpi_value": 29,
                    "kpi_average_name": "Discontinued Avg",
                    "kpi_average_value": 25,
                    "period": "2022-04-30"
                },
                {
                    "source_nm": "Amber",
                    "kpi_name": "Cancelled",
                    "kpi_value": 11,
                    "kpi_average_name": "Cancelled Avg",
                    "kpi_average_value": 17,
                    "period": "2022-04-30"
                },
                {
                    "source_nm": "RxCrossroads",
                    "kpi_name": "Active",
                    "kpi_value": 25,
                    "kpi_average_name": "Active Avg",
                    "kpi_average_value": 27,
                    "period": "2022-04-30"
                },
                {
                    "source_nm": "RxCrossroads",
                    "kpi_name": "Pending",
                    "kpi_value": 8,
                    "kpi_average_name": "Pending Avg",
                    "kpi_average_value": 23,
                    "period": "2022-04-30"
                },
                {
                    "source_nm": "RxCrossroads",
                    "kpi_name": "Discontinued",
                    "kpi_value": 12,
                    "kpi_average_name": "Discontinued Avg",
                    "kpi_average_value": 25,
                    "period": "2022-04-30"
                },
                {
                    "source_nm": "RxCrossroads",
                    "kpi_name": "Cancelled",
                    "kpi_value": 21,
                    "kpi_average_name": "Cancelled Avg",
                    "kpi_average_value": 17,
                    "period": "2022-04-30"
                },
                {
                    "source_nm": "Biologics",
                    "kpi_name": "Active",
                    "kpi_value": 39,
                    "kpi_average_name": "Active Avg",
                    "kpi_average_value": 28,
                    "period": "2022-05-31"
                },
                {
                    "source_nm": "Biologics",
                    "kpi_name": "Pending",
                    "kpi_value": 22,
                    "kpi_average_name": "Pending Avg",
                    "kpi_average_value": 24,
                    "period": "2022-05-31"
                },
                {
                    "source_nm": "Biologics",
                    "kpi_name": "Discontinued",
                    "kpi_value": 31,
                    "kpi_average_name": "Discontinued Avg",
                    "kpi_average_value": 27,
                    "period": "2022-05-31"
                },
                {
                    "source_nm": "Biologics",
                    "kpi_name": "Cancelled",
                    "kpi_value": 19,
                    "kpi_average_name": "Cancelled Avg",
                    "kpi_average_value": 18,
                    "period": "2022-05-31"
                },
                {
                    "source_nm": "CVS",
                    "kpi_name": "Active",
                    "kpi_value": 27,
                    "kpi_average_name": "Active Avg",
                    "kpi_average_value": 28,
                    "period": "2022-05-31"
                },
                {
                    "source_nm": "CVS",
                    "kpi_name": "Pending",
                    "kpi_value": 15,
                    "kpi_average_name": "Pending Avg",
                    "kpi_average_value": 24,
                    "period": "2022-05-31"
                },
                {
                    "source_nm": "CVS",
                    "kpi_name": "Discontinued",
                    "kpi_value": 24,
                    "kpi_average_name": "Discontinued Avg",
                    "kpi_average_value": 27,
                    "period": "2022-05-31"
                },
                {
                    "source_nm": "CVS",
                    "kpi_name": "Cancelled",
                    "kpi_value": 13,
                    "kpi_average_name": "Cancelled Avg",
                    "kpi_average_value": 18,
                    "period": "2022-05-31"
                },
                {
                    "source_nm": "Amber",
                    "kpi_name": "Active",
                    "kpi_value": 30,
                    "kpi_average_name": "Active Avg",
                    "kpi_average_value": 28,
                    "period": "2022-05-31"
                },
                {
                    "source_nm": "Amber",
                    "kpi_name": "Pending",
                    "kpi_value": 11,
                    "kpi_average_name": "Pending Avg",
                    "kpi_average_value": 24,
                    "period": "2022-05-31"
                },
                {
                    "source_nm": "Amber",
                    "kpi_name": "Discontinued",
                    "kpi_value": 27,
                    "kpi_average_name": "Discontinued Avg",
                    "kpi_average_value": 27,
                    "period": "2022-05-31"
                },
                {
                    "source_nm": "Amber",
                    "kpi_name": "Cancelled",
                    "kpi_value": 12,
                    "kpi_average_name": "Cancelled Avg",
                    "kpi_average_value": 18,
                    "period": "2022-05-31"
                },
                {
                    "source_nm": "RxCrossroads",
                    "kpi_name": "Active",
                    "kpi_value": 24,
                    "kpi_average_name": "Active Avg",
                    "kpi_average_value": 28,
                    "period": "2022-05-31"
                },
                {
                    "source_nm": "RxCrossroads",
                    "kpi_name": "Pending",
                    "kpi_value": 9,
                    "kpi_average_name": "Pending Avg",
                    "kpi_average_value": 24,
                    "period": "2022-05-31"
                },
                {
                    "source_nm": "RxCrossroads",
                    "kpi_name": "Discontinued",
                    "kpi_value": 16,
                    "kpi_average_name": "Discontinued Avg",
                    "kpi_average_value": 27,
                    "period": "2022-05-31"
                },
                {
                    "source_nm": "RxCrossroads",
                    "kpi_name": "Cancelled",
                    "kpi_value": 20,
                    "kpi_average_name": "Cancelled Avg",
                    "kpi_average_value": 18,
                    "period": "2022-05-31"
                },
                {
                    "source_nm": "Biologics",
                    "kpi_name": "Active",
                    "kpi_value": 36,
                    "kpi_average_name": "Active Avg",
                    "kpi_average_value": 29,
                    "period": "2022-06-30"
                },
                {
                    "source_nm": "Biologics",
                    "kpi_name": "Pending",
                    "kpi_value": 20,
                    "kpi_average_name": "Pending Avg",
                    "kpi_average_value": 25,
                    "period": "2022-06-30"
                },
                {
                    "source_nm": "Biologics",
                    "kpi_name": "Discontinued",
                    "kpi_value": 34,
                    "kpi_average_name": "Discontinued Avg",
                    "kpi_average_value": 30,
                    "period": "2022-06-30"
                },
                {
                    "source_nm": "Biologics",
                    "kpi_name": "Cancelled",
                    "kpi_value": 18,
                    "kpi_average_name": "Cancelled Avg",
                    "kpi_average_value": 19,
                    "period": "2022-06-30"
                },
                {
                    "source_nm": "CVS",
                    "kpi_name": "Active",
                    "kpi_value": 29,
                    "kpi_average_name": "Active Avg",
                    "kpi_average_value": 29,
                    "period": "2022-06-30"
                },
                {
                    "source_nm": "CVS",
                    "kpi_name": "Pending",
                    "kpi_value": 18,
                    "kpi_average_name": "Pending Avg",
                    "kpi_average_value": 25,
                    "period": "2022-06-30"
                },
                {
                    "source_nm": "CVS",
                    "kpi_name": "Discontinued",
                    "kpi_value": 26,
                    "kpi_average_name": "Discontinued Avg",
                    "kpi_average_value": 30,
                    "period": "2022-06-30"
                },
                {
                    "source_nm": "CVS",
                    "kpi_name": "Cancelled",
                    "kpi_value": 15,
                    "kpi_average_name": "Cancelled Avg",
                    "kpi_average_value": 19,
                    "period": "2022-06-30"
                },
                {
                    "source_nm": "Amber",
                    "kpi_name": "Active",
                    "kpi_value": 34,
                    "kpi_average_name": "Active Avg",
                    "kpi_average_value": 29,
                    "period": "2022-06-30"
                },
                {
                    "source_nm": "Amber",
                    "kpi_name": "Pending",
                    "kpi_value": 13,
                    "kpi_average_name": "Pending Avg",
                    "kpi_average_value": 25,
                    "period": "2022-06-30"
                },
                {
                    "source_nm": "Amber",
                    "kpi_name": "Discontinued",
                    "kpi_value": 32,
                    "kpi_average_name": "Discontinued Avg",
                    "kpi_average_value": 30,
                    "period": "2022-06-30"
                },
                {
                    "source_nm": "Amber",
                    "kpi_name": "Cancelled",
                    "kpi_value": 14,
                    "kpi_average_name": "Cancelled Avg",
                    "kpi_average_value": 19,
                    "period": "2022-06-30"
                },
                {
                    "source_nm": "RxCrossroads",
                    "kpi_name": "Active",
                    "kpi_value": 19,
                    "kpi_average_name": "Active Avg",
                    "kpi_average_value": 29,
                    "period": "2022-06-30"
                },
                {
                    "source_nm": "RxCrossroads",
                    "kpi_name": "Pending",
                    "kpi_value": 7,
                    "kpi_average_name": "Pending Avg",
                    "kpi_average_value": 25,
                    "period": "2022-06-30"
                },
                {
                    "source_nm": "RxCrossroads",
                    "kpi_name": "Discontinued",
                    "kpi_value": 18,
                    "kpi_average_name": "Discontinued Avg",
                    "kpi_average_value": 30,
                    "period": "2022-06-30"
                },
                {
                    "source_nm": "RxCrossroads",
                    "kpi_name": "Cancelled",
                    "kpi_value": 25,
                    "kpi_average_name": "Cancelled Avg",
                    "kpi_average_value": 19,
                    "period": "2022-06-30"
                },
                {
                    "source_nm": "Biologics",
                    "kpi_name": "Active",
                    "kpi_value": 37,
                    "kpi_average_name": "Active Avg",
                    "kpi_average_value": 30,
                    "period": "2022-07-31"
                },
                {
                    "source_nm": "Biologics",
                    "kpi_name": "Pending",
                    "kpi_value": 23,
                    "kpi_average_name": "Pending Avg",
                    "kpi_average_value": 26,
                    "period": "2022-07-31"
                },
                {
                    "source_nm": "Biologics",
                    "kpi_name": "Discontinued",
                    "kpi_value": 29,
                    "kpi_average_name": "Discontinued Avg",
                    "kpi_average_value": 32,
                    "period": "2022-07-31"
                },
                {
                    "source_nm": "Biologics",
                    "kpi_name": "Cancelled",
                    "kpi_value": 14,
                    "kpi_average_name": "Cancelled Avg",
                    "kpi_average_value": 20,
                    "period": "2022-07-31"
                },
                {
                    "source_nm": "CVS",
                    "kpi_name": "Active",
                    "kpi_value": 31,
                    "kpi_average_name": "Active Avg",
                    "kpi_average_value": 30,
                    "period": "2022-07-31"
                },
                {
                    "source_nm": "CVS",
                    "kpi_name": "Pending",
                    "kpi_value": 17,
                    "kpi_average_name": "Pending Avg",
                    "kpi_average_value": 26,
                    "period": "2022-07-31"
                },
                {
                    "source_nm": "CVS",
                    "kpi_name": "Discontinued",
                    "kpi_value": 24,
                    "kpi_average_name": "Discontinued Avg",
                    "kpi_average_value": 32,
                    "period": "2022-07-31"
                },
                {
                    "source_nm": "CVS",
                    "kpi_name": "Cancelled",
                    "kpi_value": 11,
                    "kpi_average_name": "Cancelled Avg",
                    "kpi_average_value": 20,
                    "period": "2022-07-31"
                },
                {
                    "source_nm": "Amber",
                    "kpi_name": "Active",
                    "kpi_value": 29,
                    "kpi_average_name": "Active Avg",
                    "kpi_average_value": 30,
                    "period": "2022-07-31"
                },
                {
                    "source_nm": "Amber",
                    "kpi_name": "Pending",
                    "kpi_value": 10,
                    "kpi_average_name": "Pending Avg",
                    "kpi_average_value": 26,
                    "period": "2022-07-31"
                },
                {
                    "source_nm": "Amber",
                    "kpi_name": "Discontinued",
                    "kpi_value": 22,
                    "kpi_average_name": "Discontinued Avg",
                    "kpi_average_value": 32,
                    "period": "2022-07-31"
                },
                {
                    "source_nm": "Amber",
                    "kpi_name": "Cancelled",
                    "kpi_value": 16,
                    "kpi_average_name": "Cancelled Avg",
                    "kpi_average_value": 20,
                    "period": "2022-07-31"
                },
                {
                    "source_nm": "RxCrossroads",
                    "kpi_name": "Active",
                    "kpi_value": 28,
                    "kpi_average_name": "Active Avg",
                    "kpi_average_value": 30,
                    "period": "2022-07-31"
                },
                {
                    "source_nm": "RxCrossroads",
                    "kpi_name": "Pending",
                    "kpi_value": 9,
                    "kpi_average_name": "Pending Avg",
                    "kpi_average_value": 26,
                    "period": "2022-07-31"
                },
                {
                    "source_nm": "RxCrossroads",
                    "kpi_name": "Discontinued",
                    "kpi_value": 15,
                    "kpi_average_name": "Discontinued Avg",
                    "kpi_average_value": 32,
                    "period": "2022-07-31"
                },
                {
                    "source_nm": "RxCrossroads",
                    "kpi_name": "Cancelled",
                    "kpi_value": 19,
                    "kpi_average_name": "Cancelled Avg",
                    "kpi_average_value": 20,
                    "period": "2022-07-31"
                },
                {
                    "source_nm": "Biologics",
                    "kpi_name": "Active",
                    "kpi_value": 36,
                    "kpi_average_name": "Active Avg",
                    "kpi_average_value": 32,
                    "period": "2022-08-31"
                },
                {
                    "source_nm": "Biologics",
                    "kpi_name": "Pending",
                    "kpi_value": 20,
                    "kpi_average_name": "Pending Avg",
                    "kpi_average_value": 24,
                    "period": "2022-08-31"
                },
                {
                    "source_nm": "Biologics",
                    "kpi_name": "Discontinued",
                    "kpi_value": 27,
                    "kpi_average_name": "Discontinued Avg",
                    "kpi_average_value": 29,
                    "period": "2022-08-31"
                },
                {
                    "source_nm": "Biologics",
                    "kpi_name": "Cancelled",
                    "kpi_value": 21,
                    "kpi_average_name": "Cancelled Avg",
                    "kpi_average_value": 22,
                    "period": "2022-08-31"
                },
                {
                    "source_nm": "CVS",
                    "kpi_name": "Active",
                    "kpi_value": 34,
                    "kpi_average_name": "Active Avg",
                    "kpi_average_value": 32,
                    "period": "2022-08-31"
                },
                {
                    "source_nm": "CVS",
                    "kpi_name": "Pending",
                    "kpi_value": 15,
                    "kpi_average_name": "Pending Avg",
                    "kpi_average_value": 24,
                    "period": "2022-08-31"
                },
                {
                    "source_nm": "CVS",
                    "kpi_name": "Discontinued",
                    "kpi_value": 23,
                    "kpi_average_name": "Discontinued Avg",
                    "kpi_average_value": 29,
                    "period": "2022-08-31"
                },
                {
                    "source_nm": "CVS",
                    "kpi_name": "Cancelled",
                    "kpi_value": 12,
                    "kpi_average_name": "Cancelled Avg",
                    "kpi_average_value": 22,
                    "period": "2022-08-31"
                },
                {
                    "source_nm": "Amber",
                    "kpi_name": "Active",
                    "kpi_value": 28,
                    "kpi_average_name": "Active Avg",
                    "kpi_average_value": 32,
                    "period": "2022-08-31"
                },
                {
                    "source_nm": "Amber",
                    "kpi_name": "Pending",
                    "kpi_value": 11,
                    "kpi_average_name": "Pending Avg",
                    "kpi_average_value": 24,
                    "period": "2022-08-31"
                },
                {
                    "source_nm": "Amber",
                    "kpi_name": "Discontinued",
                    "kpi_value": 30,
                    "kpi_average_name": "Discontinued Avg",
                    "kpi_average_value": 29,
                    "period": "2022-08-31"
                },
                {
                    "source_nm": "Amber",
                    "kpi_name": "Cancelled",
                    "kpi_value": 14,
                    "kpi_average_name": "Cancelled Avg",
                    "kpi_average_value": 22,
                    "period": "2022-08-31"
                },
                {
                    "source_nm": "RxCrossroads",
                    "kpi_name": "Active",
                    "kpi_value": 21,
                    "kpi_average_name": "Active Avg",
                    "kpi_average_value": 32,
                    "period": "2022-08-31"
                },
                {
                    "source_nm": "RxCrossroads",
                    "kpi_name": "Pending",
                    "kpi_value": 9,
                    "kpi_average_name": "Pending Avg",
                    "kpi_average_value": 24,
                    "period": "2022-08-31"
                },
                {
                    "source_nm": "RxCrossroads",
                    "kpi_name": "Discontinued",
                    "kpi_value": 14,
                    "kpi_average_name": "Discontinued Avg",
                    "kpi_average_value": 29,
                    "period": "2022-08-31"
                },
                {
                    "source_nm": "RxCrossroads",
                    "kpi_name": "Cancelled",
                    "kpi_value": 19,
                    "kpi_average_name": "Cancelled Avg",
                    "kpi_average_value": 22,
                    "period": "2022-08-31"
                },
                {
                    "source_nm": "Biologics",
                    "kpi_name": "Active",
                    "kpi_value": 38,
                    "kpi_average_name": "Active Avg",
                    "kpi_average_value": 30,
                    "period": "2022-09-30"
                },
                {
                    "source_nm": "Biologics",
                    "kpi_name": "Pending",
                    "kpi_value": 25,
                    "kpi_average_name": "Pending Avg",
                    "kpi_average_value": 22,
                    "period": "2022-09-30"
                },
                {
                    "source_nm": "Biologics",
                    "kpi_name": "Discontinued",
                    "kpi_value": 32,
                    "kpi_average_name": "Discontinued Avg",
                    "kpi_average_value": 31,
                    "period": "2022-09-30"
                },
                {
                    "source_nm": "Biologics",
                    "kpi_name": "Cancelled",
                    "kpi_value": 15,
                    "kpi_average_name": "Cancelled Avg",
                    "kpi_average_value": 20,
                    "period": "2022-09-30"
                },
                {
                    "source_nm": "CVS",
                    "kpi_name": "Active",
                    "kpi_value": 36,
                    "kpi_average_name": "Active Avg",
                    "kpi_average_value": 30,
                    "period": "2022-09-30"
                },
                {
                    "source_nm": "CVS",
                    "kpi_name": "Pending",
                    "kpi_value": 20,
                    "kpi_average_name": "Pending Avg",
                    "kpi_average_value": 22,
                    "period": "2022-09-30"
                },
                {
                    "source_nm": "CVS",
                    "kpi_name": "Discontinued",
                    "kpi_value": 24,
                    "kpi_average_name": "Discontinued Avg",
                    "kpi_average_value": 31,
                    "period": "2022-09-30"
                },
                {
                    "source_nm": "CVS",
                    "kpi_name": "Cancelled",
                    "kpi_value": 13,
                    "kpi_average_name": "Cancelled Avg",
                    "kpi_average_value": 20,
                    "period": "2022-09-30"
                },
                {
                    "source_nm": "Amber",
                    "kpi_name": "Active",
                    "kpi_value": 29,
                    "kpi_average_name": "Active Avg",
                    "kpi_average_value": 30,
                    "period": "2022-09-30"
                },
                {
                    "source_nm": "Amber",
                    "kpi_name": "Pending",
                    "kpi_value": 12,
                    "kpi_average_name": "Pending Avg",
                    "kpi_average_value": 22,
                    "period": "2022-09-30"
                },
                {
                    "source_nm": "Amber",
                    "kpi_name": "Discontinued",
                    "kpi_value": 28,
                    "kpi_average_name": "Discontinued Avg",
                    "kpi_average_value": 31,
                    "period": "2022-09-30"
                },
                {
                    "source_nm": "Amber",
                    "kpi_name": "Cancelled",
                    "kpi_value": 10,
                    "kpi_average_name": "Cancelled Avg",
                    "kpi_average_value": 20,
                    "period": "2022-09-30"
                },
                {
                    "source_nm": "RxCrossroads",
                    "kpi_name": "Active",
                    "kpi_value": 33,
                    "kpi_average_name": "Active Avg",
                    "kpi_average_value": 30,
                    "period": "2022-09-30"
                },
                {
                    "source_nm": "RxCrossroads",
                    "kpi_name": "Pending",
                    "kpi_value": 11,
                    "kpi_average_name": "Pending Avg",
                    "kpi_average_value": 22,
                    "period": "2022-09-30"
                },
                {
                    "source_nm": "RxCrossroads",
                    "kpi_name": "Discontinued",
                    "kpi_value": 16,
                    "kpi_average_name": "Discontinued Avg",
                    "kpi_average_value": 31,
                    "period": "2022-09-30"
                },
                {
                    "source_nm": "RxCrossroads",
                    "kpi_name": "Cancelled",
                    "kpi_value": 21,
                    "kpi_average_name": "Cancelled Avg",
                    "kpi_average_value": 20,
                    "period": "2022-09-30"
                },
                {
                    "source_nm": "Biologics",
                    "kpi_name": "Active",
                    "kpi_value": 36,
                    "kpi_average_name": "Active Avg",
                    "kpi_average_value": 31,
                    "period": "2022-10-31"
                },
                {
                    "source_nm": "Biologics",
                    "kpi_name": "Pending",
                    "kpi_value": 27,
                    "kpi_average_name": "Pending Avg",
                    "kpi_average_value": 23,
                    "period": "2022-10-31"
                },
                {
                    "source_nm": "Biologics",
                    "kpi_name": "Discontinued",
                    "kpi_value": 29,
                    "kpi_average_name": "Discontinued Avg",
                    "kpi_average_value": 30,
                    "period": "2022-10-31"
                },
                {
                    "source_nm": "Biologics",
                    "kpi_name": "Cancelled",
                    "kpi_value": 14,
                    "kpi_average_name": "Cancelled Avg",
                    "kpi_average_value": 21,
                    "period": "2022-10-31"
                },
                {
                    "source_nm": "CVS",
                    "kpi_name": "Active",
                    "kpi_value": 38,
                    "kpi_average_name": "Active Avg",
                    "kpi_average_value": 31,
                    "period": "2022-10-31"
                },
                {
                    "source_nm": "CVS",
                    "kpi_name": "Pending",
                    "kpi_value": 22,
                    "kpi_average_name": "Pending Avg",
                    "kpi_average_value": 23,
                    "period": "2022-10-31"
                },
                {
                    "source_nm": "CVS",
                    "kpi_name": "Discontinued",
                    "kpi_value": 25,
                    "kpi_average_name": "Discontinued Avg",
                    "kpi_average_value": 30,
                    "period": "2022-10-31"
                },
                {
                    "source_nm": "CVS",
                    "kpi_name": "Cancelled",
                    "kpi_value": 17,
                    "kpi_average_name": "Cancelled Avg",
                    "kpi_average_value": 21,
                    "period": "2022-10-31"
                },
                {
                    "source_nm": "Amber",
                    "kpi_name": "Active",
                    "kpi_value": 32,
                    "kpi_average_name": "Active Avg",
                    "kpi_average_value": 31,
                    "period": "2022-10-31"
                },
                {
                    "source_nm": "Amber",
                    "kpi_name": "Pending",
                    "kpi_value": 15,
                    "kpi_average_name": "Pending Avg",
                    "kpi_average_value": 23,
                    "period": "2022-10-31"
                },
                {
                    "source_nm": "Amber",
                    "kpi_name": "Discontinued",
                    "kpi_value": 27,
                    "kpi_average_name": "Discontinued Avg",
                    "kpi_average_value": 30,
                    "period": "2022-10-31"
                },
                {
                    "source_nm": "Amber",
                    "kpi_name": "Cancelled",
                    "kpi_value": 11,
                    "kpi_average_name": "Cancelled Avg",
                    "kpi_average_value": 21,
                    "period": "2022-10-31"
                },
                {
                    "source_nm": "RxCrossroads",
                    "kpi_name": "Active",
                    "kpi_value": 29,
                    "kpi_average_name": "Active Avg",
                    "kpi_average_value": 31,
                    "period": "2022-10-31"
                },
                {
                    "source_nm": "RxCrossroads",
                    "kpi_name": "Pending",
                    "kpi_value": 9,
                    "kpi_average_name": "Pending Avg",
                    "kpi_average_value": 23,
                    "period": "2022-10-31"
                },
                {
                    "source_nm": "RxCrossroads",
                    "kpi_name": "Discontinued",
                    "kpi_value": 14,
                    "kpi_average_name": "Discontinued Avg",
                    "kpi_average_value": 30,
                    "period": "2022-10-31"
                },
                {
                    "source_nm": "RxCrossroads",
                    "kpi_name": "Cancelled",
                    "kpi_value": 18,
                    "kpi_average_name": "Cancelled Avg",
                    "kpi_average_value": 21,
                    "period": "2022-10-31"
                },
                {
                    "source_nm": "Biologics",
                    "kpi_name": "Active",
                    "kpi_value": 40,
                    "kpi_average_name": "Active Avg",
                    "kpi_average_value": 30,
                    "period": "2022-11-30"
                },
                {
                    "source_nm": "Biologics",
                    "kpi_name": "Pending",
                    "kpi_value": 26,
                    "kpi_average_name": "Pending Avg",
                    "kpi_average_value": 24,
                    "period": "2022-11-30"
                },
                {
                    "source_nm": "Biologics",
                    "kpi_name": "Discontinued",
                    "kpi_value": 31,
                    "kpi_average_name": "Discontinued Avg",
                    "kpi_average_value": 32,
                    "period": "2022-11-30"
                },
                {
                    "source_nm": "Biologics",
                    "kpi_name": "Cancelled",
                    "kpi_value": 15,
                    "kpi_average_name": "Cancelled Avg",
                    "kpi_average_value": 22,
                    "period": "2022-11-30"
                },
                {
                    "source_nm": "CVS",
                    "kpi_name": "Active",
                    "kpi_value": 35,
                    "kpi_average_name": "Active Avg",
                    "kpi_average_value": 30,
                    "period": "2022-11-30"
                },
                {
                    "source_nm": "CVS",
                    "kpi_name": "Pending",
                    "kpi_value": 19,
                    "kpi_average_name": "Pending Avg",
                    "kpi_average_value": 24,
                    "period": "2022-11-30"
                },
                {
                    "source_nm": "CVS",
                    "kpi_name": "Discontinued",
                    "kpi_value": 27,
                    "kpi_average_name": "Discontinued Avg",
                    "kpi_average_value": 32,
                    "period": "2022-11-30"
                },
                {
                    "source_nm": "CVS",
                    "kpi_name": "Cancelled",
                    "kpi_value": 12,
                    "kpi_average_name": "Cancelled Avg",
                    "kpi_average_value": 22,
                    "period": "2022-11-30"
                },
                {
                    "source_nm": "Amber",
                    "kpi_name": "Active",
                    "kpi_value": 33,
                    "kpi_average_name": "Active Avg",
                    "kpi_average_value": 30,
                    "period": "2022-11-30"
                },
                {
                    "source_nm": "Amber",
                    "kpi_name": "Pending",
                    "kpi_value": 14,
                    "kpi_average_name": "Pending Avg",
                    "kpi_average_value": 24,
                    "period": "2022-11-30"
                },
                {
                    "source_nm": "Amber",
                    "kpi_name": "Discontinued",
                    "kpi_value": 29,
                    "kpi_average_name": "Discontinued Avg",
                    "kpi_average_value": 32,
                    "period": "2022-11-30"
                },
                {
                    "source_nm": "Amber",
                    "kpi_name": "Cancelled",
                    "kpi_value": 11,
                    "kpi_average_name": "Cancelled Avg",
                    "kpi_average_value": 22,
                    "period": "2022-11-30"
                },
                {
                    "source_nm": "RxCrossroads",
                    "kpi_name": "Active",
                    "kpi_value": 37,
                    "kpi_average_name": "Active Avg",
                    "kpi_average_value": 30,
                    "period": "2022-11-30"
                },
                {
                    "source_nm": "RxCrossroads",
                    "kpi_name": "Pending",
                    "kpi_value": 10,
                    "kpi_average_name": "Pending Avg",
                    "kpi_average_value": 24,
                    "period": "2022-11-30"
                },
                {
                    "source_nm": "RxCrossroads",
                    "kpi_name": "Discontinued",
                    "kpi_value": 16,
                    "kpi_average_name": "Discontinued Avg",
                    "kpi_average_value": 32,
                    "period": "2022-11-30"
                },
                {
                    "source_nm": "RxCrossroads",
                    "kpi_name": "Cancelled",
                    "kpi_value": 20,
                    "kpi_average_name": "Cancelled Avg",
                    "kpi_average_value": 22,
                    "period": "2022-11-30"
                },
                {
                    "source_nm": "Biologics",
                    "kpi_name": "Active",
                    "kpi_value": 39,
                    "kpi_average_name": "Active Avg",
                    "kpi_average_value": 32,
                    "period": "2022-12-31"
                },
                {
                    "source_nm": "Biologics",
                    "kpi_name": "Pending",
                    "kpi_value": 22,
                    "kpi_average_name": "Pending Avg",
                    "kpi_average_value": 25,
                    "period": "2022-12-31"
                },
                {
                    "source_nm": "Biologics",
                    "kpi_name": "Discontinued",
                    "kpi_value": 30,
                    "kpi_average_name": "Discontinued Avg",
                    "kpi_average_value": 29,
                    "period": "2022-12-31"
                },
                {
                    "source_nm": "Biologics",
                    "kpi_name": "Cancelled",
                    "kpi_value": 17,
                    "kpi_average_name": "Cancelled Avg",
                    "kpi_average_value": 23,
                    "period": "2022-12-31"
                },
                {
                    "source_nm": "CVS",
                    "kpi_name": "Active",
                    "kpi_value": 34,
                    "kpi_average_name": "Active Avg",
                    "kpi_average_value": 32,
                    "period": "2022-12-31"
                },
                {
                    "source_nm": "CVS",
                    "kpi_name": "Pending",
                    "kpi_value": 18,
                    "kpi_average_name": "Pending Avg",
                    "kpi_average_value": 25,
                    "period": "2022-12-31"
                },
                {
                    "source_nm": "CVS",
                    "kpi_name": "Discontinued",
                    "kpi_value": 28,
                    "kpi_average_name": "Discontinued Avg",
                    "kpi_average_value": 29,
                    "period": "2022-12-31"
                },
                {
                    "source_nm": "CVS",
                    "kpi_name": "Cancelled",
                    "kpi_value": 15,
                    "kpi_average_name": "Cancelled Avg",
                    "kpi_average_value": 23,
                    "period": "2022-12-31"
                },
                {
                    "source_nm": "Amber",
                    "kpi_name": "Active",
                    "kpi_value": 29,
                    "kpi_average_name": "Active Avg",
                    "kpi_average_value": 32,
                    "period": "2022-12-31"
                },
                {
                    "source_nm": "Amber",
                    "kpi_name": "Pending",
                    "kpi_value": 12,
                    "kpi_average_name": "Pending Avg",
                    "kpi_average_value": 25,
                    "period": "2022-12-31"
                },
                {
                    "source_nm": "Amber",
                    "kpi_name": "Discontinued",
                    "kpi_value": 31,
                    "kpi_average_name": "Discontinued Avg",
                    "kpi_average_value": 29,
                    "period": "2022-12-31"
                },
                {
                    "source_nm": "Amber",
                    "kpi_name": "Cancelled",
                    "kpi_value": 10,
                    "kpi_average_name": "Cancelled Avg",
                    "kpi_average_value": 23,
                    "period": "2022-12-31"
                },
                {
                    "source_nm": "RxCrossroads",
                    "kpi_name": "Active",
                    "kpi_value": 36,
                    "kpi_average_name": "Active Avg",
                    "kpi_average_value": 32,
                    "period": "2022-12-31"
                },
                {
                    "source_nm": "RxCrossroads",
                    "kpi_name": "Pending",
                    "kpi_value": 9,
                    "kpi_average_name": "Pending Avg",
                    "kpi_average_value": 25,
                    "period": "2022-12-31"
                },
                {
                    "source_nm": "RxCrossroads",
                    "kpi_name": "Discontinued",
                    "kpi_value": 15,
                    "kpi_average_name": "Discontinued Avg",
                    "kpi_average_value": 29,
                    "period": "2022-12-31"
                },
                {
                    "source_nm": "RxCrossroads",
                    "kpi_name": "Cancelled",
                    "kpi_value": 19,
                    "kpi_average_name": "Cancelled Avg",
                    "kpi_average_value": 23,
                    "period": "2022-12-31"
                },
                {
                    "source_nm": "Biologics",
                    "kpi_name": "Active",
                    "kpi_value": 40,
                    "kpi_average_name": "Active Avg",
                    "kpi_average_value": 33,
                    "period": "2023-01-31"
                },
                {
                    "source_nm": "Biologics",
                    "kpi_name": "Pending",
                    "kpi_value": 24,
                    "kpi_average_name": "Pending Avg",
                    "kpi_average_value": 26,
                    "period": "2023-01-31"
                },
                {
                    "source_nm": "Biologics",
                    "kpi_name": "Discontinued",
                    "kpi_value": 33,
                    "kpi_average_name": "Discontinued Avg",
                    "kpi_average_value": 30,
                    "period": "2023-01-31"
                },
                {
                    "source_nm": "Biologics",
                    "kpi_name": "Cancelled",
                    "kpi_value": 16,
                    "kpi_average_name": "Cancelled Avg",
                    "kpi_average_value": 24,
                    "period": "2023-01-31"
                },
                {
                    "source_nm": "CVS",
                    "kpi_name": "Active",
                    "kpi_value": 37,
                    "kpi_average_name": "Active Avg",
                    "kpi_average_value": 33,
                    "period": "2023-01-31"
                },
                {
                    "source_nm": "CVS",
                    "kpi_name": "Pending",
                    "kpi_value": 20,
                    "kpi_average_name": "Pending Avg",
                    "kpi_average_value": 26,
                    "period": "2023-01-31"
                },
                {
                    "source_nm": "CVS",
                    "kpi_name": "Discontinued",
                    "kpi_value": 29,
                    "kpi_average_name": "Discontinued Avg",
                    "kpi_average_value": 30,
                    "period": "2023-01-31"
                },
                {
                    "source_nm": "CVS",
                    "kpi_name": "Cancelled",
                    "kpi_value": 14,
                    "kpi_average_name": "Cancelled Avg",
                    "kpi_average_value": 24,
                    "period": "2023-01-31"
                },
                {
                    "source_nm": "Amber",
                    "kpi_name": "Active",
                    "kpi_value": 33,
                    "kpi_average_name": "Active Avg",
                    "kpi_average_value": 33,
                    "period": "2023-01-31"
                },
                {
                    "source_nm": "Amber",
                    "kpi_name": "Pending",
                    "kpi_value": 12,
                    "kpi_average_name": "Pending Avg",
                    "kpi_average_value": 26,
                    "period": "2023-01-31"
                },
                {
                    "source_nm": "Amber",
                    "kpi_name": "Discontinued",
                    "kpi_value": 25,
                    "kpi_average_name": "Discontinued Avg",
                    "kpi_average_value": 30,
                    "period": "2023-01-31"
                },
                {
                    "source_nm": "Amber",
                    "kpi_name": "Cancelled",
                    "kpi_value": 13,
                    "kpi_average_name": "Cancelled Avg",
                    "kpi_average_value": 24,
                    "period": "2023-01-31"
                },
                {
                    "source_nm": "RxCrossroads",
                    "kpi_name": "Active",
                    "kpi_value": 30,
                    "kpi_average_name": "Active Avg",
                    "kpi_average_value": 33,
                    "period": "2023-01-31"
                },
                {
                    "source_nm": "RxCrossroads",
                    "kpi_name": "Pending",
                    "kpi_value": 11,
                    "kpi_average_name": "Pending Avg",
                    "kpi_average_value": 26,
                    "period": "2023-01-31"
                },
                {
                    "source_nm": "RxCrossroads",
                    "kpi_name": "Discontinued",
                    "kpi_value": 18,
                    "kpi_average_name": "Discontinued Avg",
                    "kpi_average_value": 30,
                    "period": "2023-01-31"
                },
                {
                    "source_nm": "RxCrossroads",
                    "kpi_name": "Cancelled",
                    "kpi_value": 21,
                    "kpi_average_name": "Cancelled Avg",
                    "kpi_average_value": 24,
                    "period": "2023-01-31"
                },
                {
                    "source_nm": "Biologics",
                    "kpi_name": "Active",
                    "kpi_value": 39,
                    "kpi_average_name": "Active Avg",
                    "kpi_average_value": 34,
                    "period": "2023-02-28"
                },
                {
                    "source_nm": "Biologics",
                    "kpi_name": "Pending",
                    "kpi_value": 25,
                    "kpi_average_name": "Pending Avg",
                    "kpi_average_value": 27,
                    "period": "2023-02-28"
                },
                {
                    "source_nm": "Biologics",
                    "kpi_name": "Discontinued",
                    "kpi_value": 32,
                    "kpi_average_name": "Discontinued Avg",
                    "kpi_average_value": 32,
                    "period": "2023-02-28"
                },
                {
                    "source_nm": "Biologics",
                    "kpi_name": "Cancelled",
                    "kpi_value": 15,
                    "kpi_average_name": "Cancelled Avg",
                    "kpi_average_value": 25,
                    "period": "2023-02-28"
                },
                {
                    "source_nm": "CVS",
                    "kpi_name": "Active",
                    "kpi_value": 35,
                    "kpi_average_name": "Active Avg",
                    "kpi_average_value": 34,
                    "period": "2023-02-28"
                },
                {
                    "source_nm": "CVS",
                    "kpi_name": "Pending",
                    "kpi_value": 19,
                    "kpi_average_name": "Pending Avg",
                    "kpi_average_value": 27,
                    "period": "2023-02-28"
                },
                {
                    "source_nm": "CVS",
                    "kpi_name": "Discontinued",
                    "kpi_value": 30,
                    "kpi_average_name": "Discontinued Avg",
                    "kpi_average_value": 32,
                    "period": "2023-02-28"
                },
                {
                    "source_nm": "CVS",
                    "kpi_name": "Cancelled",
                    "kpi_value": 12,
                    "kpi_average_name": "Cancelled Avg",
                    "kpi_average_value": 25,
                    "period": "2023-02-28"
                },
                {
                    "source_nm": "Amber",
                    "kpi_name": "Active",
                    "kpi_value": 36,
                    "kpi_average_name": "Active Avg",
                    "kpi_average_value": 34,
                    "period": "2023-02-28"
                },
                {
                    "source_nm": "Amber",
                    "kpi_name": "Pending",
                    "kpi_value": 14,
                    "kpi_average_name": "Pending Avg",
                    "kpi_average_value": 27,
                    "period": "2023-02-28"
                },
                {
                    "source_nm": "Amber",
                    "kpi_name": "Discontinued",
                    "kpi_value": 29,
                    "kpi_average_name": "Discontinued Avg",
                    "kpi_average_value": 32,
                    "period": "2023-02-28"
                },
                {
                    "source_nm": "Amber",
                    "kpi_name": "Cancelled",
                    "kpi_value": 14,
                    "kpi_average_name": "Cancelled Avg",
                    "kpi_average_value": 25,
                    "period": "2023-02-28"
                },
                {
                    "source_nm": "RxCrossroads",
                    "kpi_name": "Active",
                    "kpi_value": 28,
                    "kpi_average_name": "Active Avg",
                    "kpi_average_value": 34,
                    "period": "2023-02-28"
                },
                {
                    "source_nm": "RxCrossroads",
                    "kpi_name": "Pending",
                    "kpi_value": 10,
                    "kpi_average_name": "Pending Avg",
                    "kpi_average_value": 27,
                    "period": "2023-02-28"
                },
                {
                    "source_nm": "RxCrossroads",
                    "kpi_name": "Discontinued",
                    "kpi_value": 20,
                    "kpi_average_name": "Discontinued Avg",
                    "kpi_average_value": 32,
                    "period": "2023-02-28"
                },
                {
                    "source_nm": "RxCrossroads",
                    "kpi_name": "Cancelled",
                    "kpi_value": 19,
                    "kpi_average_name": "Cancelled Avg",
                    "kpi_average_value": 25,
                    "period": "2023-02-28"
                },
                {
                    "source_nm": "Biologics",
                    "kpi_name": "Active",
                    "kpi_value": 41,
                    "kpi_average_name": "Active Avg",
                    "kpi_average_value": 35,
                    "period": "2023-03-31"
                },
                {
                    "source_nm": "Biologics",
                    "kpi_name": "Pending",
                    "kpi_value": 28,
                    "kpi_average_name": "Pending Avg",
                    "kpi_average_value": 28,
                    "period": "2023-03-31"
                },
                {
                    "source_nm": "Biologics",
                    "kpi_name": "Discontinued",
                    "kpi_value": 30,
                    "kpi_average_name": "Discontinued Avg",
                    "kpi_average_value": 34,
                    "period": "2023-03-31"
                },
                {
                    "source_nm": "Biologics",
                    "kpi_name": "Cancelled",
                    "kpi_value": 18,
                    "kpi_average_name": "Cancelled Avg",
                    "kpi_average_value": 26,
                    "period": "2023-03-31"
                },
                {
                    "source_nm": "CVS",
                    "kpi_name": "Active",
                    "kpi_value": 38,
                    "kpi_average_name": "Active Avg",
                    "kpi_average_value": 35,
                    "period": "2023-03-31"
                },
                {
                    "source_nm": "CVS",
                    "kpi_name": "Pending",
                    "kpi_value": 23,
                    "kpi_average_name": "Pending Avg",
                    "kpi_average_value": 28,
                    "period": "2023-03-31"
                },
                {
                    "source_nm": "CVS",
                    "kpi_name": "Discontinued",
                    "kpi_value": 30,
                    "kpi_average_name": "Discontinued Avg",
                    "kpi_average_value": 34,
                    "period": "2023-03-31"
                },
                {
                    "source_nm": "CVS",
                    "kpi_name": "Cancelled",
                    "kpi_value": 16,
                    "kpi_average_name": "Cancelled Avg",
                    "kpi_average_value": 26,
                    "period": "2023-03-31"
                },
                {
                    "source_nm": "Amber",
                    "kpi_name": "Active",
                    "kpi_value": 34,
                    "kpi_average_name": "Active Avg",
                    "kpi_average_value": 35,
                    "period": "2023-03-31"
                },
                {
                    "source_nm": "Amber",
                    "kpi_name": "Pending",
                    "kpi_value": 15,
                    "kpi_average_name": "Pending Avg",
                    "kpi_average_value": 28,
                    "period": "2023-03-31"
                },
                {
                    "source_nm": "Amber",
                    "kpi_name": "Discontinued",
                    "kpi_value": 32,
                    "kpi_average_name": "Discontinued Avg",
                    "kpi_average_value": 34,
                    "period": "2023-03-31"
                },
                {
                    "source_nm": "Amber",
                    "kpi_name": "Cancelled",
                    "kpi_value": 13,
                    "kpi_average_name": "Cancelled Avg",
                    "kpi_average_value": 26,
                    "period": "2023-03-31"
                },
                {
                    "source_nm": "RxCrossroads",
                    "kpi_name": "Active",
                    "kpi_value": 36,
                    "kpi_average_name": "Active Avg",
                    "kpi_average_value": 35,
                    "period": "2023-03-31"
                },
                {
                    "source_nm": "RxCrossroads",
                    "kpi_name": "Pending",
                    "kpi_value": 12,
                    "kpi_average_name": "Pending Avg",
                    "kpi_average_value": 28,
                    "period": "2023-03-31"
                },
                {
                    "source_nm": "RxCrossroads",
                    "kpi_name": "Discontinued",
                    "kpi_value": 18,
                    "kpi_average_name": "Discontinued Avg",
                    "kpi_average_value": 34,
                    "period": "2023-03-31"
                },
                {
                    "source_nm": "RxCrossroads",
                    "kpi_name": "Cancelled",
                    "kpi_value": 20,
                    "kpi_average_name": "Cancelled Avg",
                    "kpi_average_value": 26,
                    "period": "2023-03-31"
                },
                {
                    "source_nm": "Biologics",
                    "kpi_name": "Active",
                    "kpi_value": 40,
                    "kpi_average_name": "Active Avg",
                    "kpi_average_value": 36,
                    "period": "2023-04-30"
                },
                {
                    "source_nm": "Biologics",
                    "kpi_name": "Pending",
                    "kpi_value": 29,
                    "kpi_average_name": "Pending Avg",
                    "kpi_average_value": 29,
                    "period": "2023-04-30"
                },
                {
                    "source_nm": "Biologics",
                    "kpi_name": "Discontinued",
                    "kpi_value": 33,
                    "kpi_average_name": "Discontinued Avg",
                    "kpi_average_value": 35,
                    "period": "2023-04-30"
                },
                {
                    "source_nm": "Biologics",
                    "kpi_name": "Cancelled",
                    "kpi_value": 19,
                    "kpi_average_name": "Cancelled Avg",
                    "kpi_average_value": 27,
                    "period": "2023-04-30"
                },
                {
                    "source_nm": "CVS",
                    "kpi_name": "Active",
                    "kpi_value": 37,
                    "kpi_average_name": "Active Avg",
                    "kpi_average_value": 36,
                    "period": "2023-04-30"
                },
                {
                    "source_nm": "CVS",
                    "kpi_name": "Pending",
                    "kpi_value": 25,
                    "kpi_average_name": "Pending Avg",
                    "kpi_average_value": 29,
                    "period": "2023-04-30"
                },
                {
                    "source_nm": "CVS",
                    "kpi_name": "Discontinued",
                    "kpi_value": 28,
                    "kpi_average_name": "Discontinued Avg",
                    "kpi_average_value": 35,
                    "period": "2023-04-30"
                },
                {
                    "source_nm": "CVS",
                    "kpi_name": "Cancelled",
                    "kpi_value": 15,
                    "kpi_average_name": "Cancelled Avg",
                    "kpi_average_value": 27,
                    "period": "2023-04-30"
                },
                {
                    "source_nm": "Amber",
                    "kpi_name": "Active",
                    "kpi_value": 35,
                    "kpi_average_name": "Active Avg",
                    "kpi_average_value": 36,
                    "period": "2023-04-30"
                },
                {
                    "source_nm": "Amber",
                    "kpi_name": "Pending",
                    "kpi_value": 14,
                    "kpi_average_name": "Pending Avg",
                    "kpi_average_value": 29,
                    "period": "2023-04-30"
                },
                {
                    "source_nm": "Amber",
                    "kpi_name": "Discontinued",
                    "kpi_value": 30,
                    "kpi_average_name": "Discontinued Avg",
                    "kpi_average_value": 35,
                    "period": "2023-04-30"
                },
                {
                    "source_nm": "Amber",
                    "kpi_name": "Cancelled",
                    "kpi_value": 12,
                    "kpi_average_name": "Cancelled Avg",
                    "kpi_average_value": 27,
                    "period": "2023-04-30"
                },
                {
                    "source_nm": "RxCrossroads",
                    "kpi_name": "Active",
                    "kpi_value": 39,
                    "kpi_average_name": "Active Avg",
                    "kpi_average_value": 36,
                    "period": "2023-04-30"
                },
                {
                    "source_nm": "RxCrossroads",
                    "kpi_name": "Pending",
                    "kpi_value": 11,
                    "kpi_average_name": "Pending Avg",
                    "kpi_average_value": 29,
                    "period": "2023-04-30"
                },
                {
                    "source_nm": "RxCrossroads",
                    "kpi_name": "Discontinued",
                    "kpi_value": 15,
                    "kpi_average_name": "Discontinued Avg",
                    "kpi_average_value": 35,
                    "period": "2023-04-30"
                },
                {
                    "source_nm": "RxCrossroads",
                    "kpi_name": "Cancelled",
                    "kpi_value": 22,
                    "kpi_average_name": "Cancelled Avg",
                    "kpi_average_value": 27,
                    "period": "2023-04-30"
                },
                {
                    "source_nm": "Biologics",
                    "kpi_name": "Active",
                    "kpi_value": 42,
                    "kpi_average_name": "Active Avg",
                    "kpi_average_value": 37,
                    "period": "2023-05-31"
                },
                {
                    "source_nm": "Biologics",
                    "kpi_name": "Pending",
                    "kpi_value": 31,
                    "kpi_average_name": "Pending Avg",
                    "kpi_average_value": 30,
                    "period": "2023-05-31"
                },
                {
                    "source_nm": "Biologics",
                    "kpi_name": "Discontinued",
                    "kpi_value": 31,
                    "kpi_average_name": "Discontinued Avg",
                    "kpi_average_value": 34,
                    "period": "2023-05-31"
                },
                {
                    "source_nm": "Biologics",
                    "kpi_name": "Cancelled",
                    "kpi_value": 21,
                    "kpi_average_name": "Cancelled Avg",
                    "kpi_average_value": 28,
                    "period": "2023-05-31"
                },
                {
                    "source_nm": "CVS",
                    "kpi_name": "Active",
                    "kpi_value": 38,
                    "kpi_average_name": "Active Avg",
                    "kpi_average_value": 37,
                    "period": "2023-05-31"
                },
                {
                    "source_nm": "CVS",
                    "kpi_name": "Pending",
                    "kpi_value": 22,
                    "kpi_average_name": "Pending Avg",
                    "kpi_average_value": 30,
                    "period": "2023-05-31"
                },
                {
                    "source_nm": "CVS",
                    "kpi_name": "Discontinued",
                    "kpi_value": 27,
                    "kpi_average_name": "Discontinued Avg",
                    "kpi_average_value": 34,
                    "period": "2023-05-31"
                },
                {
                    "source_nm": "CVS",
                    "kpi_name": "Cancelled",
                    "kpi_value": 18,
                    "kpi_average_name": "Cancelled Avg",
                    "kpi_average_value": 28,
                    "period": "2023-05-31"
                },
                {
                    "source_nm": "Amber",
                    "kpi_name": "Active",
                    "kpi_value": 36,
                    "kpi_average_name": "Active Avg",
                    "kpi_average_value": 37,
                    "period": "2023-05-31"
                },
                {
                    "source_nm": "Amber",
                    "kpi_name": "Pending",
                    "kpi_value": 16,
                    "kpi_average_name": "Pending Avg",
                    "kpi_average_value": 30,
                    "period": "2023-05-31"
                },
                {
                    "source_nm": "Amber",
                    "kpi_name": "Discontinued",
                    "kpi_value": 29,
                    "kpi_average_name": "Discontinued Avg",
                    "kpi_average_value": 34,
                    "period": "2023-05-31"
                },
                {
                    "source_nm": "Amber",
                    "kpi_name": "Cancelled",
                    "kpi_value": 14,
                    "kpi_average_name": "Cancelled Avg",
                    "kpi_average_value": 28,
                    "period": "2023-05-31"
                },
                {
                    "source_nm": "RxCrossroads",
                    "kpi_name": "Active",
                    "kpi_value": 34,
                    "kpi_average_name": "Active Avg",
                    "kpi_average_value": 37,
                    "period": "2023-05-31"
                },
                {
                    "source_nm": "RxCrossroads",
                    "kpi_name": "Pending",
                    "kpi_value": 10,
                    "kpi_average_name": "Pending Avg",
                    "kpi_average_value": 30,
                    "period": "2023-05-31"
                },
                {
                    "source_nm": "RxCrossroads",
                    "kpi_name": "Discontinued",
                    "kpi_value": 20,
                    "kpi_average_name": "Discontinued Avg",
                    "kpi_average_value": 34,
                    "period": "2023-05-31"
                },
                {
                    "source_nm": "RxCrossroads",
                    "kpi_name": "Cancelled",
                    "kpi_value": 23,
                    "kpi_average_name": "Cancelled Avg",
                    "kpi_average_value": 28,
                    "period": "2023-05-31"
                },
                {
                    "source_nm": "Biologics",
                    "kpi_name": "Active",
                    "kpi_value": 43,
                    "kpi_average_name": "Active Avg",
                    "kpi_average_value": 38,
                    "period": "2023-06-30"
                },
                {
                    "source_nm": "Biologics",
                    "kpi_name": "Pending",
                    "kpi_value": 30,
                    "kpi_average_name": "Pending Avg",
                    "kpi_average_value": 31,
                    "period": "2023-06-30"
                },
                {
                    "source_nm": "Biologics",
                    "kpi_name": "Discontinued",
                    "kpi_value": 29,
                    "kpi_average_name": "Discontinued Avg",
                    "kpi_average_value": 33,
                    "period": "2023-06-30"
                },
                {
                    "source_nm": "Biologics",
                    "kpi_name": "Cancelled",
                    "kpi_value": 20,
                    "kpi_average_name": "Cancelled Avg",
                    "kpi_average_value": 29,
                    "period": "2023-06-30"
                },
                {
                    "source_nm": "CVS",
                    "kpi_name": "Active",
                    "kpi_value": 39,
                    "kpi_average_name": "Active Avg",
                    "kpi_average_value": 38,
                    "period": "2023-06-30"
                },
                {
                    "source_nm": "CVS",
                    "kpi_name": "Pending",
                    "kpi_value": 24,
                    "kpi_average_name": "Pending Avg",
                    "kpi_average_value": 31,
                    "period": "2023-06-30"
                },
                {
                    "source_nm": "CVS",
                    "kpi_name": "Discontinued",
                    "kpi_value": 28,
                    "kpi_average_name": "Discontinued Avg",
                    "kpi_average_value": 33,
                    "period": "2023-06-30"
                },
                {
                    "source_nm": "CVS",
                    "kpi_name": "Cancelled",
                    "kpi_value": 17,
                    "kpi_average_name": "Cancelled Avg",
                    "kpi_average_value": 29,
                    "period": "2023-06-30"
                },
                {
                    "source_nm": "Amber",
                    "kpi_name": "Active",
                    "kpi_value": 34,
                    "kpi_average_name": "Active Avg",
                    "kpi_average_value": 38,
                    "period": "2023-06-30"
                },
                {
                    "source_nm": "Amber",
                    "kpi_name": "Pending",
                    "kpi_value": 15,
                    "kpi_average_name": "Pending Avg",
                    "kpi_average_value": 31,
                    "period": "2023-06-30"
                },
                {
                    "source_nm": "Amber",
                    "kpi_name": "Discontinued",
                    "kpi_value": 26,
                    "kpi_average_name": "Discontinued Avg",
                    "kpi_average_value": 33,
                    "period": "2023-06-30"
                },
                {
                    "source_nm": "Amber",
                    "kpi_name": "Cancelled",
                    "kpi_value": 15,
                    "kpi_average_name": "Cancelled Avg",
                    "kpi_average_value": 29,
                    "period": "2023-06-30"
                },
                {
                    "source_nm": "RxCrossroads",
                    "kpi_name": "Active",
                    "kpi_value": 31,
                    "kpi_average_name": "Active Avg",
                    "kpi_average_value": 38,
                    "period": "2023-06-30"
                },
                {
                    "source_nm": "RxCrossroads",
                    "kpi_name": "Pending",
                    "kpi_value": 12,
                    "kpi_average_name": "Pending Avg",
                    "kpi_average_value": 31,
                    "period": "2023-06-30"
                },
                {
                    "source_nm": "RxCrossroads",
                    "kpi_name": "Discontinued",
                    "kpi_value": 18,
                    "kpi_average_name": "Discontinued Avg",
                    "kpi_average_value": 33,
                    "period": "2023-06-30"
                },
                {
                    "source_nm": "RxCrossroads",
                    "kpi_name": "Cancelled",
                    "kpi_value": 22,
                    "kpi_average_name": "Cancelled Avg",
                    "kpi_average_value": 29,
                    "period": "2023-06-30"
                },
                {
                    "source_nm": "Biologics",
                    "kpi_name": "Active",
                    "kpi_value": 45,
                    "kpi_average_name": "Active Avg",
                    "kpi_average_value": 39,
                    "period": "2023-07-31"
                },
                {
                    "source_nm": "Biologics",
                    "kpi_name": "Pending",
                    "kpi_value": 32,
                    "kpi_average_name": "Pending Avg",
                    "kpi_average_value": 32,
                    "period": "2023-07-31"
                },
                {
                    "source_nm": "Biologics",
                    "kpi_name": "Discontinued",
                    "kpi_value": 27,
                    "kpi_average_name": "Discontinued Avg",
                    "kpi_average_value": 32,
                    "period": "2023-07-31"
                },
                {
                    "source_nm": "Biologics",
                    "kpi_name": "Cancelled",
                    "kpi_value": 23,
                    "kpi_average_name": "Cancelled Avg",
                    "kpi_average_value": 30,
                    "period": "2023-07-31"
                },
                {
                    "source_nm": "CVS",
                    "kpi_name": "Active",
                    "kpi_value": 40,
                    "kpi_average_name": "Active Avg",
                    "kpi_average_value": 39,
                    "period": "2023-07-31"
                },
                {
                    "source_nm": "CVS",
                    "kpi_name": "Pending",
                    "kpi_value": 28,
                    "kpi_average_name": "Pending Avg",
                    "kpi_average_value": 32,
                    "period": "2023-07-31"
                },
                {
                    "source_nm": "CVS",
                    "kpi_name": "Discontinued",
                    "kpi_value": 26,
                    "kpi_average_name": "Discontinued Avg",
                    "kpi_average_value": 32,
                    "period": "2023-07-31"
                },
                {
                    "source_nm": "CVS",
                    "kpi_name": "Cancelled",
                    "kpi_value": 20,
                    "kpi_average_name": "Cancelled Avg",
                    "kpi_average_value": 30,
                    "period": "2023-07-31"
                },
                {
                    "source_nm": "Amber",
                    "kpi_name": "Active",
                    "kpi_value": 37,
                    "kpi_average_name": "Active Avg",
                    "kpi_average_value": 39,
                    "period": "2023-07-31"
                },
                {
                    "source_nm": "Amber",
                    "kpi_name": "Pending",
                    "kpi_value": 18,
                    "kpi_average_name": "Pending Avg",
                    "kpi_average_value": 32,
                    "period": "2023-07-31"
                },
                {
                    "source_nm": "Amber",
                    "kpi_name": "Discontinued",
                    "kpi_value": 30,
                    "kpi_average_name": "Discontinued Avg",
                    "kpi_average_value": 32,
                    "period": "2023-07-31"
                },
                {
                    "source_nm": "Amber",
                    "kpi_name": "Cancelled",
                    "kpi_value": 19,
                    "kpi_average_name": "Cancelled Avg",
                    "kpi_average_value": 30,
                    "period": "2023-07-31"
                },
                {
                    "source_nm": "RxCrossroads",
                    "kpi_name": "Active",
                    "kpi_value": 32,
                    "kpi_average_name": "Active Avg",
                    "kpi_average_value": 39,
                    "period": "2023-07-31"
                },
                {
                    "source_nm": "RxCrossroads",
                    "kpi_name": "Pending",
                    "kpi_value": 14,
                    "kpi_average_name": "Pending Avg",
                    "kpi_average_value": 32,
                    "period": "2023-07-31"
                },
                {
                    "source_nm": "RxCrossroads",
                    "kpi_name": "Discontinued",
                    "kpi_value": 22,
                    "kpi_average_name": "Discontinued Avg",
                    "kpi_average_value": 32,
                    "period": "2023-07-31"
                },
                {
                    "source_nm": "RxCrossroads",
                    "kpi_name": "Cancelled",
                    "kpi_value": 21,
                    "kpi_average_name": "Cancelled Avg",
                    "kpi_average_value": 30,
                    "period": "2023-07-31"
                },
                {
                    "source_nm": "Biologics",
                    "kpi_name": "Active",
                    "kpi_value": 48,
                    "kpi_average_name": "Active Avg",
                    "kpi_average_value": 40,
                    "period": "2023-08-31"
                },
                {
                    "source_nm": "Biologics",
                    "kpi_name": "Pending",
                    "kpi_value": 34,
                    "kpi_average_name": "Pending Avg",
                    "kpi_average_value": 33,
                    "period": "2023-08-31"
                },
                {
                    "source_nm": "Biologics",
                    "kpi_name": "Discontinued",
                    "kpi_value": 29,
                    "kpi_average_name": "Discontinued Avg",
                    "kpi_average_value": 30,
                    "period": "2023-08-31"
                },
                {
                    "source_nm": "Biologics",
                    "kpi_name": "Cancelled",
                    "kpi_value": 22,
                    "kpi_average_name": "Cancelled Avg",
                    "kpi_average_value": 31,
                    "period": "2023-08-31"
                },
                {
                    "source_nm": "CVS",
                    "kpi_name": "Active",
                    "kpi_value": 42,
                    "kpi_average_name": "Active Avg",
                    "kpi_average_value": 40,
                    "period": "2023-08-31"
                },
                {
                    "source_nm": "CVS",
                    "kpi_name": "Pending",
                    "kpi_value": 26,
                    "kpi_average_name": "Pending Avg",
                    "kpi_average_value": 33,
                    "period": "2023-08-31"
                },
                {
                    "source_nm": "CVS",
                    "kpi_name": "Discontinued",
                    "kpi_value": 27,
                    "kpi_average_name": "Discontinued Avg",
                    "kpi_average_value": 30,
                    "period": "2023-08-31"
                },
                {
                    "source_nm": "CVS",
                    "kpi_name": "Cancelled",
                    "kpi_value": 20,
                    "kpi_average_name": "Cancelled Avg",
                    "kpi_average_value": 31,
                    "period": "2023-08-31"
                },
                {
                    "source_nm": "Amber",
                    "kpi_name": "Active",
                    "kpi_value": 39,
                    "kpi_average_name": "Active Avg",
                    "kpi_average_value": 40,
                    "period": "2023-08-31"
                },
                {
                    "source_nm": "Amber",
                    "kpi_name": "Pending",
                    "kpi_value": 20,
                    "kpi_average_name": "Pending Avg",
                    "kpi_average_value": 33,
                    "period": "2023-08-31"
                },
                {
                    "source_nm": "Amber",
                    "kpi_name": "Discontinued",
                    "kpi_value": 28,
                    "kpi_average_name": "Discontinued Avg",
                    "kpi_average_value": 30,
                    "period": "2023-08-31"
                },
                {
                    "source_nm": "Amber",
                    "kpi_name": "Cancelled",
                    "kpi_value": 18,
                    "kpi_average_name": "Cancelled Avg",
                    "kpi_average_value": 31,
                    "period": "2023-08-31"
                },
                {
                    "source_nm": "RxCrossroads",
                    "kpi_name": "Active",
                    "kpi_value": 34,
                    "kpi_average_name": "Active Avg",
                    "kpi_average_value": 40,
                    "period": "2023-08-31"
                },
                {
                    "source_nm": "RxCrossroads",
                    "kpi_name": "Pending",
                    "kpi_value": 11,
                    "kpi_average_name": "Pending Avg",
                    "kpi_average_value": 33,
                    "period": "2023-08-31"
                },
                {
                    "source_nm": "RxCrossroads",
                    "kpi_name": "Discontinued",
                    "kpi_value": 16,
                    "kpi_average_name": "Discontinued Avg",
                    "kpi_average_value": 30,
                    "period": "2023-08-31"
                },
                {
                    "source_nm": "RxCrossroads",
                    "kpi_name": "Cancelled",
                    "kpi_value": 19,
                    "kpi_average_name": "Cancelled Avg",
                    "kpi_average_value": 31,
                    "period": "2023-08-31"
                }
              ]
            }
            
            // debugger
            const updatedRes = res.map((m:any) => {
              return { ...m, [cfg['x_axis_main']]: moment(m[cfg['x_axis_main']]).format('YYYY-MM-DD')};
            });
            
            
            this.chartData = [...updatedRes];
                        
            this.props = {
              xAxisLabel: '',
              yAxisLabel: '',
              
              xAxisVar: cfg['x_axis_main'],
              subGroupName: cfg['x_axis_sub'],
      
              // Bar
              stackVar: cfg['stack_bar_name'],
              stackValueVar: cfg['stack_bar_value'],
      
              // Area
              stackAreaVar: cfg['stack_area_name'],
              stackAreaValueVar: cfg['stack_area_value'],
      
              showBrush: true,
              
              d3AxisFormatting: false,
              yAxisFormatting: '-2~s',
              dateFormat: 'M',
              axisFontSize: 12,
              axisFontWeight: 700,
              movingAveragePeriod: 1,
              chartHeight: 600,
              partialPeriod: false,
            };

            this.props['barFill'] =  {};
            this.props['barLabels'] =  {};

            cfg.iconConditionalFormat.values.map((m:any)=> {
              this.props['barFill'][m.col_value] = m.color;
              this.props['barLabels'][m.col_value] = m.col_value;
            })

            this.loader = true;
          } else {
            this.chartData = [];
            this.loader = true;
          }
        }, (err: any) => {
          this.chartData = [];
          this.loader = true;
        })
      )
    }
  }

  isEmptyObject(obj: any) {
    return Object.keys(obj).length === 0;
  }
}
