<div #stackAreaBarContainer></div>

<!-- <div [id]="tooltip" style="width: 250px;">
    <div style="background-color: #000;border-radius: 10px;" *ngIf="isTooltip">
        <div class="row">
            <div class="col-12" style="background-color: #343435;padding: 10px;border-radius: 10px 10px 0 0;">
                <span style="font-family: Poppins;font-size: 12px;line-height: 16px;color: #fff;">{{ toolTipValue.date }}</span>
                <br>
                <span style="font-family: Poppins;font-size: 14px;font-weight: 600;line-height: 16px;color: #fff;">{{ toolTipValue.subGroup ? toolTipValue.subGroup.toUpperCase() : '' }}</span>
            </div>
            <div class="col-12" style="background-color: #000000;padding: 10px;border-radius: 0 0 10px 10px;">
                <div *ngFor="let all of toolTipValue.data;"> 
                    <div class="d-flex align-items-center mb-2">
                        <div [style.background-color]="this.props['barFill'][all.key]" class="mr-2" style="width: 15px; height: 15px;border-radius: 50px;border: 2px solid #fff;"></div>
                        <p class="mr-2" style="font-family: Poppins;font-size: 12px;font-weight: 500;line-height: 16px;color: #fff;margin-bottom: 0;">{{ all.value }}</p>
                        <p class="mr-2" style="font-family: Poppins;font-size: 12px;font-weight: 500;line-height: 16px;color: #fff;margin-bottom: 0;">{{ all.key ? all.key.toUpperCase() : '' }}</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div> -->

<div [id]="tooltip">
    <div class="container chart_tooltip" *ngIf="isTooltip">
        <div class="row chart_tooltip_header">
            <div class="col-12 d-flex flex-column mt-1">
                <div class="chart_tooltip_header_heading" style="font-size: 10px !important;">
                    {{ mytooltipData['date'] }}
                </div>
            </div>
            <div class="col-12 d-flex flex-column mb-1">
                <div class="chart_tooltip_header_heading" style="font-size: 12px;">
                    {{ mytooltipData.subGroup ? mytooltipData.subGroup.toUpperCase() : '' }}
                </div>
            </div>
        </div>
        <div class="mt-2 mb-5 pb-1" *ngIf="height > 200"
            [ngClass]="(dataTurn < 350) ? 'chart_tooltip_body-after' :'chart_tooltip_body'">
            <div class=" col-12 d-flex m-auto align-items-center pl-0"
                *ngFor="let data of mytooltipData.data ">

                <div>
                    <div class="tooltip_outlay"
                        [style.background]="data.color"></div>
                </div>
                <div class="chart_tooltip_body_data ml-2"> 
                    {{data?.value ? numbedPipe(data?.value) : 0}} {{ " " }} {{data.name}} 
                </div>
            </div>
        </div>

        <div class="mt-2 mb-5 pb-1" *ngIf="height < 200"
            [ngClass]="(dataTurn < 350) ? 'chart_tooltip_body-after100' :'chart_tooltip_body100'">
            <div class=" col-12 d-flex m-auto align-items-center pl-0"
                *ngFor="let data of mytooltipData.data ">

                <div>
                    <div class="tooltip_outlay"
                        [style.background]="data.color"></div>
                </div>
                <div class="chart_tooltip_body_data ml-2"> 
                    {{data?.value ? numbedPipe(data?.value) : 0}} {{ " " }} {{data.name}} </div>
            </div>
        </div>

    </div>
</div>