import { Component, Input, OnInit, OnChanges, SimpleChanges } from '@angular/core';
import * as d3 from 'd3';

@Component({
  selector: 'app-donut-simple-chart',
  templateUrl: './donut-simple-chart.component.html',
  styleUrls: ['./donut-simple-chart.component.scss']
})
export class DonutSimpleChartComponent implements OnInit, OnChanges {

  @Input() data: any;
  @Input() apiKeys: any;
  divId = '';

  constructor() { }

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    // debugger
    if (changes?.['data'] && changes?.['data'].currentValue !== changes?.['data'].previousValue) {
      this.divId = 'donut' + this.apiKeys;
      setTimeout(() => {
        this.createChart();
      }, 1500);
    }
  }

  createChart() {
    console.log('donut'+this.apiKeys, this.data)
    if (!this.data || this.data.length === 0) {
      console.error("No data provided for donut d3 chart");
      return;
    }

    const margin = { top: 10, right: 30, bottom: 30, left: 40 };
    const width = 150;
    const height = 150;

    const radius = Math.min(width, height) / 2 - margin.left;
    // Remove the previous chart if it exists
    d3.select(`#${this.divId}`).selectAll("*").remove();

    const svg = d3.select(`#${this.divId}`)
      .append("svg")
      .attr("width", width/2)
      .attr("height", height/2)
      .append("g")
      .attr("transform", "translate(" + width / 4 + "," + height / 4 + ")");

    const pie:any = d3.pie()
      .sort(null)
      .value((d:any) => {
        return +d.value;
      });

    const data_ready = pie(this.data);

    // The arc generator
    const arc:any = d3.arc()
      .innerRadius(radius * 0.6) // Size of the donut hole
      .outerRadius(radius * 0.9);

    // Build the pie chart: each slice is a path that we build using the arc function.
    svg.selectAll("allSlices")
      .data(data_ready)
      .enter()
      .append("path")
      .attr("d", arc)
      .attr("fill", (d: any) => d.data.color || '#000') // Directly use color from data
      .attr("stroke", "white")
      .style("stroke-width", "2px")
      .style("opacity", 0.7);

  }
}
