<!-- Widget-Container -->
<loading *ngIf="loader" type="kpi"></loading>
<div class="container-fluid widget-container navigation-card" *ngIf="item.config">
  
  <div class="row" *ngIf="item.config?.['direction_so_for']?.['value'] == 'vertical'">
    
    <div class="text-center my-2" *ngIf="item.config['donut_visibility']?.['name']!='No' || (customHeader?.['headerNameInput'] || customHeader?.['headerNameDrop'])">
      <div *ngIf="customHeader" class="mb-3">
        <div class="fontColor fontColor1" *ngIf="customHeader?.['headerName']=='1'" [ngStyle]="{'font-size': customHeader?.['headerFontSize'] +'px', 'color': customHeader?.['headerColor'], 'font-weight': customHeader?.['headerFontWeight']}">{{customHeader?.['headerNameInput'] || ''}}</div>
        <div class="fontColor fontColor1" *ngIf="customHeader?.['headerName']=='2'" [ngStyle]="{'font-size': customHeader?.['headerFontSize'] +'px', 'color': customHeader?.['headerColor'], 'font-weight': customHeader?.['headerFontWeight']}">{{headerName || customHeader?.['headerNameInput']}}</div>
      </div>
      
      <div *ngIf="item.config['donut_visibility']?.['name']!='No'">
          <app-donut-simple-chart [data]="donutData" [apiKeys]="apiKeys"></app-donut-simple-chart>
          <!-- <svg width="70" height="70" viewBox="0 0 70 70" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M64.4423 53.9249C66.9275 50.0585 68.6269 45.7404 69.4434 41.2172C70.2598 36.6941 70.1774 32.0544 69.2008 27.5631C68.2241 23.0718 66.3724 18.8168 63.7514 15.0411C61.1304 11.2654 57.7913 8.04295 53.9249 5.5577C50.0585 3.07245 45.7404 1.37308 41.2172 0.556623C36.6941 -0.259837 32.0544 -0.177394 27.5631 0.799236C23.0718 1.77587 18.8168 3.62757 15.0411 6.24861C11.2654 8.86965 8.04296 12.2087 5.55771 16.0751L13.8015 21.3741C15.5909 18.5903 17.9111 16.1861 20.6296 14.299C23.3481 12.4118 26.4117 11.0786 29.6454 10.3755C32.8792 9.67227 36.2197 9.61292 39.4764 10.2008C42.7331 10.7886 45.8421 12.0122 48.6259 13.8015C51.4097 15.5909 53.8139 17.9111 55.701 20.6296C57.5882 23.3481 58.9214 26.4117 59.6246 29.6454C60.3277 32.8791 60.3871 36.2197 59.7992 39.4764C59.2114 42.7331 57.9878 45.8421 56.1985 48.6259L64.4423 53.9249Z" fill="#E34071"/>
              <path d="M65.3109 52.5C60.6696 60.5389 53.025 66.4049 44.0587 68.8074C35.0924 71.2099 25.539 69.9522 17.5001 65.3109C9.46114 60.6697 3.59517 53.0251 1.19263 44.0588C-1.20991 35.0925 0.0477742 25.5391 4.68902 17.5002L13.1761 22.4001C9.8344 28.1882 8.92887 35.0666 10.6587 41.5223C12.3885 47.978 16.612 53.4822 22.4001 56.8239C28.1881 60.1656 35.0666 61.0711 41.5223 59.3413C47.978 57.6115 53.4821 53.388 56.8238 47.6L65.3109 52.5Z" fill="#1363DF"/>
          </svg> -->
      </div>
    </div>

    <div class="d-flex">
      <div *ngIf="customHeader" style="width: 100%;">
         <div class="d-flex align-items-center justify-content-between" style="width: 100%;">
           <div style="width: 100%;margin: 10px 15px 0px;">
             <div [class.mb-3]="!last" *ngFor="let data of item.config?.['kpi_header_value']?.controls; let last = last; let first = first;">
               
                <div *ngIf="!loader && data.swapText==true" class="d-flex align-items-center">
                  <div class="mr-2 dountDot" *ngIf="item.config['donut_visibility']?.['name']!='No'" [style.background]="data.donutColor || '#000'"></div>
                  <div class="fontColor fontColor2 verFontColor1" [style.color]="data?.subHeaderColor || '#000'" [ngStyle]="{'font-size': data?.subHeaderFont +'px', 'font-weight': data?.subHeaderFontWeight}">{{dbData?.[data?.linkName] || ''}}</div>
                </div>
               
                <div class="d-flex align-items-center justify-content-between" style="width: 100%;">
                  <div class="fontColor fontColor3 d-flex my-1" *ngIf="!loader">
                    <!-- Prefix Name -->
                    <span *ngIf="data?.isPrefix" [style.color]="data?.prefixNameColor || '#000'"
                          [ngStyle]="{'font-size': data?.prefixNameFont + 'px', 'font-weight': data?.prefixNameFontWeight, 'white-space': 'pre-wrap'}">
                      <span *ngIf="data?.prefixNameType == '1'">{{data?.prefixName}}</span>
                      <span *ngIf="data?.prefixNameType == '2'">{{dbData ? dbData?.[data.prefixNameDrop] : ''}}</span>
                    </span>
                  
                    <!-- Link Data -->
                    <span [style.color]="data?.linkColor || '#000'"
                          [ngStyle]="{'font-size': data?.linkFont + 'px', 'font-weight': data?.linkFontWeight, 'white-space': 'pre-wrap'}">
                      <span *ngIf="data?.formatType === 'number'">{{ dbData?.[data?.linkPoint] || 0 | numberFormat }}</span>
                      <span *ngIf="data?.formatType === 'currency'">{{ dbData?.[data?.linkPoint] || 0 | customCurrency }}</span>
                      <span *ngIf="data?.formatType === 'string'">{{ dbData?.[data?.linkPoint] || '' }}</span>
                    </span>
                  
                    <!-- Suffix Name -->
                    <span *ngIf="data?.isSuffix" [style.color]="data?.suffixNameColor || '#000'"
                          [ngStyle]="{'font-size': data?.suffixNameFont + 'px', 'font-weight': data?.suffixNameFontWeight, 'white-space': 'pre-wrap'}">
                      <span *ngIf="data?.suffixNameType == '1'">{{data?.suffixName}}</span>
                      <span *ngIf="data?.suffixNameType == '2'">{{dbData ? dbData?.[data?.suffixNameDrop] : ''}}</span>
                    </span>
                  </div>
                  <div *ngIf="first && item.config?.['navigation']?.['name']=='Yes'">
                    <span style="cursor: pointer;" (click)="navigationToDetailPage(data)">
                      <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect x="1" y="1" width="26" height="26" rx="13" fill="white" stroke="#E8EAEE" stroke-width="2"/>
                        <path d="M15.3841 12.752L8 12.752L8 15.248L15.3841 15.248L12.5149 18.2274L14.222 20L20 14L14.222 8L12.5149 9.77264L15.3841 12.752Z" fill="#1363DF"/>
                      </svg>                        
                    </span>
                  </div>
                </div>
               <!-- <div  *ngIf="loader" [style.border-top]="  '5px solid '+item.config['icon_color']" class="loader"></div> -->
               <div *ngIf="!loader && data.swapText==false" class="d-flex align-items-center">
                <div class="mr-2 dountDot" *ngIf="item.config['donut_visibility']?.['name']!='No'" [style.background]="data.donutColor || '#000'"></div>
                <div class="fontColor fontColor2 verFontColor" [style.color]="data.subHeaderColor || '#000'" [ngStyle]="{'font-size': data.subHeaderFont +'px', 'font-weight': data.subHeaderFontWeight}">{{dbData ? dbData?.[data.linkName] : '' || ''}}</div>
               </div>
               
             </div>
           </div>
         </div>
      </div>
    </div>
  </div>
</div>