<div class="widget-container" #alertSubscriptions>
    <!-- Headings -->
    <main>
        <article #fs fullscreen-able [class.is-active]="isFullscreen" [class.isFulScreen]="isFullscreen"
            style="background-color: #fff;">
            <div>
                <icon-chart 
                    [headerConfig]="item.config['widget_header_config']" 
                    [pageKey]="pageKey" 
                    [expand]="fs"
                    [fullscreen]="isFullscreen" 
                    [item]="item" 
                    [data]="chartData" 
                    chartName="clusterStackedBarChart" 
                    [config]="clusterStackedBarChart"
                    [excelConfig]="excelConfig"
                    >
                </icon-chart>
            </div>

            <div *ngIf="!loader" style="width: 100%; height: 200px;">
                <loading></loading>
            </div>

            <div *ngIf="loader && chartData.length==0" style="height: 200px; width: auto;">
                <span class="bg-texts">There is no data for this time period</span>
            </div>

            <div #clusterStackedBarChart>
                <div *ngIf="loader && chartData.length>0">
                    <app-cluster-stacked-bar-chart 
                        [props]="props" 
                        [chartData]="chartData"
                        [config]="this.item.config"
                        [isFullscreen]="isFullscreen"
                        >
    
                    </app-cluster-stacked-bar-chart>
                </div>
            </div>
        </article>
    </main>
</div>