import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild, } from '@angular/core';
import 'ag-grid-enterprise';
import { ApiService } from 'src/app/services/api.service';
import { FilterService } from 'src/app/services/filter.service';
import { Subscription } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { UnderScoreDetailsComponent } from '../payment-record-details/under-score-details.component';
import moment from 'moment';
import { CurrencyPipe } from '@angular/common';
import { AgRecordDetailsComponent } from '../../shared/ag-record-details/ag-record-details.component';
import { RowGroupingDisplayType } from 'ag-grid-enterprise';
import { ActivatedRoute } from '@angular/router';
import { ValueAsIconSnippetComponent } from '../../shared/value-as-icon-snippet/value-as-icon-snippet.component';
import { CustomChildGroupComponent } from '../../shared/custom-child-group/custom-child-group.component';
import { ValueBtnComponent } from '../basic-grid-list/value-btn.component';
import { CurrencyFormatGridComponent } from './formats/currency-grid-format.component';
import { PercentageFormatGridComponent } from './formats/percentage-grid-format.component';
import { EnhancementService } from 'src/app/services/enhancements-service';
import _, { divide } from 'lodash';
import chroma from 'chroma-js';

import { CommentsGridComponent } from './comments-grid/comments-grid/comments-grid.component';
import { TimeFormatGridComponent } from './formats/time-grid-format.component';
import { StringConditionalComponent } from './formats/string-conditional-format.component';
@Component({
  selector: 'app-hierarchical-grid',
  templateUrl: './hierarchical-grid.component.html',
  styleUrls: ['./hierarchical-grid.component.scss']
})
export class HierarchicalGridComponent implements OnInit, OnDestroy {
  static key = 100608;

  @Input('item') item: any = {}
  @Input('pageKey') pageKey: any
  
  @Output('onSizeChange') onSizeChange = new EventEmitter<any>();
  @ViewChild('paymentGridDiv', { static: true }) paymentGridDiv!: ElementRef;

  panelOpenState = false;
  iconList: any = []
  heading: any;
  config: any;
  childData: any = [];
  parentData: any = [];
  reqSubcription: Subscription[] = [];
  rowData: any = [];
  columnDefs:any =[];
  childColumnDefs:any =[];
  loader=true;
  selectedGroup:any;
  routerQueryParam:any = {};
  isDetailGrid:any;
  reportType:any;
  isPivotGrid:any;
  inputConfig:any=[] // comments var foe the input
  queryFilterData:any;
  isMaskCurrentQuarter = false;
  dividerVisibility = true;
  contextMenuCondition = true;
  
  public groupDisplayType: RowGroupingDisplayType = 'multipleColumns';

  constructor(
    private filterService: FilterService, 
    private apiService: ApiService, 
    private httpClient: HttpClient,
    private currency: CurrencyPipe,
    private route: ActivatedRoute,
    private enhancementService: EnhancementService
  ) {

    this.reqSubcription.push(this.filterService.filterQuery.subscribe((query: any) => {
      this.loader=true;
      this.loadApiData();
    }))
  }

  ngOnInit(): void {

    // Auto Height
    const observer = new ResizeObserver((e: any) => {
      this.onSizeChange.emit(e[0].contentRect.height);
    });
    observer.observe(this.paymentGridDiv.nativeElement);

    let parentCfg = this.item.config['selected_column_parent'];
    let detailCfg = this.item.config['selected_column_detail'];
    this.reportType = this.item.config['defaultReportType'];
    this.isPivotGrid = this.item.config['isPivotGrid']?.name == 'true' ? true : false;
     console.log(this.item.config)

    this.selectedGroup = parentCfg.filter((flr:any)=> flr.rowGroup);

    this.updateGroupDisplayType(this.item.config?.['rowGroupingDisplayType']?.name || 'singleColumn') 
    this.isDetailGrid = this.item.config['connecting_detail_grid']?.name == 'Yes' ? true : false;
    this.isMaskCurrentQuarter = this.item.config['maskCurrentQuarter']?.name == 'Yes' ? true : false;
    this.dividerVisibility = this.item.config?.['dividerVisibility']?.name == 'No' ? false : true;
    this.contextMenuCondition = this.item.config?.['contextMenuCondition']?.name == 'No' ? false : true;
    
    if(this.item.config['connecting_detail_grid']?.name == 'Yes') {
      this.loadParentConfig(parentCfg);
      this.loadChildConfig(detailCfg, parentCfg);
    } else {
      this.loadParentConfig(parentCfg);
    }
  }

  updateGroupDisplayType(newType: RowGroupingDisplayType) {
    this.groupDisplayType = newType;
  }

  // Parent Grid Configuration
  loadParentConfig(data:any) {
    
    this.columnDefs = [];
    // let ifChildGrouping = data.find((f:any)=> f.ifChildGrouping)
    
    // if(ifChildGrouping) {
    //   this.columnDefs.push({
    //     headerName: "SP",
    //     colId: "yearAthleteGroup",
    //     minWidth: 220,
    //     showRowGroup:  "ds_nm" ,
    //     cellRenderer: "agGroupCellRenderer",
    //     valueGetter: "data ? data.file_nm : null",
    //   })
    // }
    
    if(data.length) {
      data.forEach((res: any) => {
        res.property_CB.forEach((cb: any) => {
          if (cb.name == "Add Column") {
            if (cb.value) {
              var coldef: any = {
                headerName: res.api_resp_column_display_nm,
                field: res.api_resp_column_nm,
                colId:  res.api_resp_column_nm,
                type : res.isDataType == 'integer' ? res.isFormats : res.isDataType,
                cellClass: 'cell-wrap-text-h',
                autoHeight: true,
                selectedRowConfig: res,
                cacheQuickFilter: res.isFilter || false,
                menuTabs: res.isFilterMenu || [],
                sortable: res.isSortable || false,
                enableRowGroup: true,
                enableValue: true,
                pinned: res.pinnedColumn ? res.pinnedColumnPosition : ''
              };
              // debugger
              // console.log(res.isFilterMenu);

              if(res.ifChildGrouping) {
                this.columnDefs.push({
                  headerName: res.api_resp_column_display_nm,
                  colId: res.api_resp_column_nm + res.childGroupingName,
                  minWidth: res.isMinWidth,
                  autoHeight: true,
                  cacheQuickFilter: res.isFilter || false,
                  menuTabs: res.isFilterMenu || [],
                  sortable: res.isSortable || false,
                  showRowGroup:  res.api_resp_column_nm,
                  // cellRenderer: "agGroupCellRenderer",
                  cellRenderer: CustomChildGroupComponent,
                  cellRendererParams: (params: any) => { return  {'configs' : this.item.config,'childGroupingName' :res.childGroupingName}},
                  // valueGetter: "data ? data.file_nm : null",
                })
              }

              if (res.isHeaderConfiguration) { 
                coldef["headerComponentParams"] = {
                  template: `
                        <div class="ag-cell-label-container" role="presentation">
                          <div ref="eLabel" class="ag-header-cell-label" role="presentation" style="display: ${res.headerVisiblity == 'No' ? 'none' : 'flex'};justify-content: ${res.isHeaderAlign || 'start'};text-align: ${res.isHeaderAlign || 'start'};padding-right: 5px;">
                              <span class="ag-header-cell-text" style="color: ${res.isHeaderColor || '#000'};">
                                ${res.api_resp_column_display_nm}
                              </span>
                              <span ref="eSortOrder" class="ag-header-icon ag-sort-order" ></span>
                              <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon" ></span>
                              <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon" ></span>
                              <span ref="eSortNone" class="ag-header-icon ag-sort-none-icon" ></span>
                              <span ref="eFilter" class="ag-header-icon ag-filter-icon"></span>
                          </div>
                          <span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button" style="position: absolute;margin: -5px;"></span>
                        </div>
                      `
                };
              }

              if(res.valueReplacingAsIcon) {
                coldef["cellRenderer"] = ValueAsIconSnippetComponent;
                coldef.cellRendererParams = (params: any) => {
                  return {
                      "conditionalFormat": this.item.config?.['iconConditionalFormat'],
                      "res": res
                  };
                };
              }

              if(res.isSortable && res.isSortableUsingOtherColumn) {
                coldef['comparator'] = (valueA: number, valueB: number, nodeA: any, nodeB: any, isDescending: any) => {
                  return nodeA['data'][res.sortableColumnName] - nodeB['data'][res.sortableColumnName]
                }
              }

              // Custom Sorting For Child Level
              let customSort = this.item.config?.['customSorting']?.name == 'Child Level';
              if(customSort) {
                coldef.comparator = (valueA: any, valueB: any, nodeA: any, nodeB: any, isInverted: boolean) => {
                  if(!nodeA && !nodeB) {
                    return 0
                  }
                  const isParentA = nodeA?.group && nodeA?.group === true;
                  const isParentB = nodeB?.group && nodeB?.group === true;

                  if (isParentA || isParentB) {
                    return 0;
                  } else {
                    if (typeof valueA === 'number' && typeof valueB === 'number') {
                      return valueA - valueB;
                    } else if (typeof valueA === 'string' && typeof valueB === 'string') {
                      if(res.isSortableUsingOtherColumn) return nodeA['data'][res.sortableColumnName] - nodeB['data'][res.sortableColumnName]
                      return valueA.localeCompare(valueB);
                    } else if (valueA instanceof Date && valueB instanceof Date) {
                      return valueA.getTime() - valueB.getTime();
                    } else {
                      return 0;
                    }
                  }
                };
              }

              if(res.GroupCellrenderer) {
                coldef['cellRenderer'] = 'agGroupCellRenderer' 
              }
              if(res.CommentsCellrenderer) {
                coldef["cellRenderer"] = CommentsGridComponent
                coldef["cellRendererParams"]= (params: any) => ({
                  comments_length:res?.['comments_length']||250
                }),
                this.inputConfig.push(res.api_resp_column_nm)
              }

              if(res.rowGroup) {
                coldef['rowGroup'] = true;
              }
              if(res.rowGroupHide) {
                coldef['hide'] = true;
              }

              if(res.isAggFunc) {
                coldef['aggFunc']= res.isAggFuncSelection
              }

              if(res.removeUnderScore) {
                coldef["cellRenderer"] = UnderScoreDetailsComponent;
              }
              if(res.isEditable) {
                coldef["editable"] = true;
              }  else{
                coldef["editable"] = false;

              }       
              

              coldef["minWidth"] = res.isMinWidth || 80;

              // Alignment
              coldef['cellStyle'] = { 
                'backgroundColor': res.isColumBgColor ? res.columnBgColor : '',
                'color': res.columTextColor || res.isTextColorCondition ? res.isTextColor : '#000' ,
                'padding-right':'15px',
                textAlign: res.isAlignment ? res.isAlignment.toLowerCase() : ''
              }

              if(res.isTextColorConditionWithOpacity) {
                coldef['cellRenderer'] = 'myCellRenderer'
                coldef['cellRendererParams'] = (params: any) => {
                  return { res: res };
                };
              }
  
              // Data Types
              if (res.isDataType == "date") {
                let a = res.isPrefix || "";
                let b = res.issuffix || "";
                coldef["valueGetter"] = (params: any) => params.data?.[res.api_resp_column_nm] ? a + " " + moment(params.data[res.api_resp_column_nm]).format("MM/DD/YYYY") + " " + b : "";
              }
              
              if (res.isDataType == "time") {
                coldef["cellRenderer"] = TimeFormatGridComponent;
                // coldef["valueGetter"] = (params: any) => params.data?.[res.api_resp_column_nm] ? a + "" + this.helperService.convertMinutesToReadableFormat(params.data?.[res.api_resp_column_nm]) + "" + b : "";
              }
              
              // Integer
              if (res.isDataType == "integer") {
                let a = res.isPrefix || "";
                let b = res.issuffix || "";
                if(res.isFormats=='Currency') {
                  coldef["cellRenderer"] = CurrencyFormatGridComponent;
                  coldef.valueGetter = (params: any) =>  (this.enhancementService.currentQuarterFlag && this.isMaskCurrentQuarter) ? '-' : params.data[res.api_resp_column_nm] ? params.data[res.api_resp_column_nm] : 0;
                  coldef.valueFormatter = (params: any) =>  (this.enhancementService.currentQuarterFlag && this.isMaskCurrentQuarter) ? '-' : params.value ? params.value : 0;
                  coldef.cellRendererParams = (params: any) => {
                    res['maskCurrentQuarter'] = this.isMaskCurrentQuarter;
                    return params['res']= res;
                  };

                } else if(res.isFormats=='Percentage') {
                  // if(!res.isConditionalFormat) { 
                    coldef["cellRenderer"] = PercentageFormatGridComponent;
                    coldef.valueGetter = (params: any) =>  (this.enhancementService.currentQuarterFlag && this.isMaskCurrentQuarter) ? '-' : params.data[res.api_resp_column_nm] ? params.data[res.api_resp_column_nm] : 0;
                    coldef.valueFormatter = (params: any) =>  (this.enhancementService.currentQuarterFlag && this.isMaskCurrentQuarter) ? '-' : params.value ? params.value : 0;
                    coldef.cellRendererParams = (params: any) => {
                      res['maskCurrentQuarter'] = this.isMaskCurrentQuarter;
                      return params['res']= res;
                    };
                  // } else {
                  //   coldef["valueFormatter"] = (params: any) => a + "" + params.value + "" + b;
                  // }
                } else {
                  // debugger
                  // coldef.valueGetter = (params: any) =>  a + "" + params.data[res.api_resp_column_nm] ? params.data[res.api_resp_column_nm] : 0 + "" + b;
                  coldef["valueFormatter"] = (params: any) => a + "" + this.currency.transform(Number(params.value), "", "", "1.0-2") + "" + b;
                }
              }
  
              // String for Init cap and upper and lower
              if (res.isDataType == "string") {
                let a = res.isPrefix || "";
                let b = res.issuffix || "";
                if (res.isTextCase == "from_db") {
                  coldef["valueFormatter"] = (params: any) => params.value ? a + "" + params.value + "" + b : "";
                } else if (res.isTextCase == "init") {
                  coldef["valueFormatter"] = (params: any) => params.value ? a + "" + params.value.toLowerCase().split(" ").map((s: any) => s.charAt(0).toUpperCase() + s.substring(1)).join(" ") + " " + b: "";
                } else if (res.isTextCase == "cap") {
                  coldef["valueFormatter"] = (params: any) => params.value ? a + "" + params.value.toUpperCase() + "" + b : "";
                } else {
                  coldef["valueFormatter"] = (params: any) => params.value ? a + "" + params.value.toLowerCase() + "" + b : "";
                }
              }

              if(res.isLinkingPage) {
                coldef.cellRenderer = AgRecordDetailsComponent;
                coldef.cellRendererParams = (params: any) => {
                    return {
                        type: res.isDataType,
                        item: this.item,
                        from: 'parent',
                        report_key:res.report_key,
                        detailChildApi:res.columnApi,
                        columnList: data,
                        linkingPageSelection: res.linkingPageSelection,
                        tabCount : res.tabCount
                    };
                };
              }

              if(res.isConditionalFormat) {
                if(res.isConditionalTextColor=="backgroundColor") {
                  coldef["cellRendererFramework"] = ValueBtnComponent;
                  coldef["cellRendererParams"] = (params: any) => {
                    // debugger
                    if(this.rowData.length==0) return;
                    
                    
                    if(res['isTypeOfFormat'] == 'typeOfString') {
                      return {color: this.getDataForStringColor(res.conditionalFormatForString, params), 'padding-right':'15px', textAlign: res.isAlignment ? res.isAlignment.toLowerCase() : ''}
                    } else {
                      const maxNumber = _.maxBy(this.rowData, (o:any) => o[res.api_resp_column_nm]|| 0);
                      const colorCode = res['dynamicSelctColor'];
                      const divideBy = res['dataDividedBy'];
                      
                      const dynamicRanges = this.getColorCode(maxNumber[res.api_resp_column_nm], Number(divideBy), colorCode, res);
                        if(res['logicsForColorCodes']=='dynamic') {
                          return { color: this.getDataColor(dynamicRanges, params),'response': res,'padding-right':'15px', textAlign: res.isAlignment ? res.isAlignment.toLowerCase() : ''}
                        } else {
                          return { color: this.getDataColor(res.conditionalFormat, params), 'padding-right':'15px', textAlign: res.isAlignment ? res.isAlignment.toLowerCase() : '' }
                        }
                    }
                  }
                } else {
                  if(res['isTypeOfFormat'] == 'typeOfString' && res['textWithBackgroundOpacityColor']) {
                    coldef.cellRenderer = StringConditionalComponent;
                    coldef.cellRendererParams = (params: any) => {
                      return {color: this.getDataForStringColor(res.conditionalFormatForString, params), 'background-color': this.getDataForBgColor(res, params), 'padding-right':'15px', textAlign: res.isAlignment ? res.isAlignment.toLowerCase() : ''}
                    };
                  } else {
                    coldef['cellStyle'] = (param:any) => {
  
                      if(this.rowData.length==0) return;
                      
                      if(res['isTypeOfFormat'] == 'typeOfString') {
                        return {color: this.getDataForStringColor(res.conditionalFormatForString, param), 'padding-right':'15px', textAlign: res.isAlignment ? res.isAlignment.toLowerCase() : ''}
                      } else {
                        const maxNumber = _.maxBy(this.rowData, (o:any) => o[res.api_resp_column_nm]||0);
                        const colorCode = res['dynamicSelctColor'];
                        const divideBy = res['dataDividedBy'];
                        
                        const dynamicRanges = this.getColorCode(maxNumber[res.api_resp_column_nm], Number(divideBy), colorCode, res);
    
                        if(res['logicsForColorCodes']=='dynamic') {
                          return { color: this.getDataColor(dynamicRanges, param), 'padding-right':'15px', textAlign: res.isAlignment ? res.isAlignment.toLowerCase() : ''}
                        } else {
                          return { color: this.getDataColor(res.conditionalFormat, param), 'padding-right':'15px', textAlign: res.isAlignment ? res.isAlignment.toLowerCase() : ''};
                        }
                      }
  
                    }
                  }
                }
              }

              this.columnDefs.push(coldef);
  
            }
          }
        })
      })

      this.columnDefs.push({
        headerName: 'Hidden Column',
        field: "hidden_column",
        hide: true,
        suppressPDFColumn: true,
        suppressColumnsToolPanel: true
      })

     

    }
    

  }

  generateShades(color: string, numShades: number): string[] {
    // Include the base color and generate lighter shades
    return Array.from({ length: numShades + 1 }, (_, i) => {
      const brightnessFactor = i / (numShades - 3.8);
      return i === 0 ? color : chroma(color).brighten(brightnessFactor).hex();
    });
  }

  getDataForStringColor(rangeValue:any, param:any) {
    // debugger
    if (!rangeValue) return null;

    for (let idx in rangeValue) {
      let r = rangeValue[idx];
      
      if(param.value == r.value) {
        return r.color;
      }

    }
    return null;
  }
  
  getDataForBgColor(rangeValue:any, param:any) {
    // debugger
    // textWithBackgroundOpacityColor
    if(!rangeValue.textWithBackgroundOpacityColor) return '';

    let value = rangeValue.conditionalFormatForString;
    if (!value) return null;

    for (let idx in value) {
      let r = value[idx];
      
      if(param.value == r.value) {
        return chroma(r.color).alpha(0.3).css();
      }

    }
    return null;
  }

  getColorCode(maxNumber: any, divideBy: number, colorCode: any, res:any) {
    if (divideBy === 1) {
      return [{ from: 0.1, to: maxNumber, color: res['rangeColorDynamic'][0].color }];
    }
    
    const shades:any = [];
    if(res['rangeColorDynamic']?.length) {
      res['rangeColorDynamic'].forEach((f:any)=> {
        shades.push(f.color);
      })
    }
    shades.reverse();

    const divide = Number(maxNumber) / divideBy;

    const dataWithColorCodes = Array.from({ length: divideBy }, (_, index) => {
      const from_value = index === 0 ? res['logicsForIncludesZero']=='yes' ? 0 : 0.001 : Number(((divide * index) + 0.01).toFixed(2));
      const to_value = Number((divide * (index + 1)).toFixed(2));
      return { from_value, to_value, color: shades[index] };
    });

    if(res['logicsForIncludesZero']=='no') {
      dataWithColorCodes.unshift({from_value: 0, to_value: 0, color: res['dynamicSelctZeroColor']})
    }

    return dataWithColorCodes;
  }
  
  getDataColor(rangeValue: any[], param: any): string | null {
    if (!rangeValue || !param) return null;
  
    const value = param.value?.value !== undefined ? param.value.value : param.value;
  
    for (let r of rangeValue) {
      if (r.from_value === '>') {
        // Handle special case: greater than a certain value
        if (value > parseFloat(r.to_value)) {
          return r.color;
        }
      } else {
        // Handle range: from_value to to_value
        const from = parseFloat(r.from_value);
        const to = parseFloat(r.to_value);
  
        if (value >= from && value <= to) {
          return r.color;
        }
      }
    }
  
    return null;
  }

  // Child Grid Configuration
  loadChildConfig(data:any, parentData:any) {

    let newData:any = [];
    const filter = parentData?.filter((f:any)=> f.GroupCellrenderer);
    filter.map((m:any)=> newData.push({filterKey:m.filterKey,key: m.api_resp_column_nm}))

    let filerRouter:any = [];
    
    const filerRouterData = data?.filter((f:any)=> f.isLinkingPage);
    filerRouterData.map((m:any)=> filerRouter.push({filterKey:m.filterKey,key: m.api_resp_column_nm}))

    if(data.length) {
      data.forEach((res: any) => {
        res.property_CB.forEach((cb: any) => {
          if (cb.name == "Add Column") {
            if (cb.value) {
              
              var coldef: any = {
                headerName: res.api_resp_column_display_nm,
                field: res.api_resp_column_nm,
                colId:  res.api_resp_column_nm,
                type : res.isDataType == 'integer' ? res.isFormats : res.isDataType,
                cellClass: 'cell-wrap-text-h',
                autoHeight: true,
                cacheQuickFilter: res.isFilter || false,
                menuTabs: res.isFilterMenu || [],
                sortable: res.isSortable || false
              };
        
              if (res.isHeaderConfiguration) { 
                coldef["headerComponentParams"] = {
                  template: `
                        <div class="ag-cell-label-container" role="presentation">
                          <div ref="eLabel" class="ag-header-cell-label" role="presentation" style="display: ${(res.headerVisiblity == 'No' ? 'none' : 'flex')};justify-content: ${res.isHeaderAlign || 'start'};text-align: ${res.isHeaderAlign || 'start'}">
                              <span class="ag-header-cell-text" style="color: ${res.isHeaderColor || '#000'}">
                                ${res.api_resp_column_display_nm}
                              </span>
                              <span ref="eSortOrder" class="ag-header-icon ag-sort-order" ></span>
                              <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon" ></span>
                              <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon" ></span>
                              <span ref="eSortNone" class="ag-header-icon ag-sort-none-icon" ></span>
                              <span ref="eFilter" class="ag-header-icon ag-filter-icon"></span>
                          </div>
                          <span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button" style="position: absolute;margin: -5px;"></span>
                        </div>
                      `
                };
              }

              if(res.removeUnderScore) {
                coldef["cellRenderer"] = UnderScoreDetailsComponent;
              }

              if (res.api_resp_column_nm == "processed_date") { 
                coldef["valueFormatter"] = (params: any) => params.data?.[res.api_resp_column_nm] ? moment(params.data[res.api_resp_column_nm]).format("MM/DD/YYYY") + " " : "";
              }
              
              coldef["minWidth"] = res.isMinWidth || 80;

              // Alignment
              coldef['cellStyle'] = { 
                'backgroundColor': res.isColumBgColor ? res.columnBgColor : '',
                'color': res.columTextColor || res.isTextColor || '#000' ,
                'padding-right':'15px',
                textAlign: res.isAlignment ? res.isAlignment.toLowerCase() : ''
              }
  
              if(res.isTextColorConditionWithOpacity) {
                coldef['cellRenderer'] = 'myCellRenderer'
                coldef['cellRendererParams'] = (params: any) => {
                  return { res: res };
                };
              }
              
              // Data Types
              if (res.isDataType == "date") {
                let a = res.isPrefix || "";
                let b = res.issuffix || "";
                coldef["valueFormatter"] = (params: any) => params.data?.[res.api_resp_column_nm] ? a + " " + moment(params.data[res.api_resp_column_nm]).format("MM/DD/YYYY") + " " + b : "";
              }

              // Integer
              if (res.isDataType == "integer") {
                let a = res.isPrefix || "";
                let b = res.issuffix || "";
                coldef["valueFormatter"] = (params: any) => a + "" + this.currency.transform(Number(params.value), "", "", "1.0-2") + "" + b;
              }
  
              // String for Init cap and upper and lower
              if (res.isDataType == "string") {
                let a = res.isPrefix || "";
                let b = res.issuffix || "";
                if (res.isTextCase == "from_db") {
                  coldef["valueFormatter"] = (params: any) => params.value ? a + "" + params.value + "" + b : "";
                } else if (res.isTextCase == "init") {
                  coldef["valueFormatter"] = (params: any) => params.value ? a + "" + params.value.toLowerCase().split(" ").map((s: any) => s.charAt(0).toUpperCase() + s.substring(1)).join(" ") + "" + b: "";
                } else if (res.isTextCase == "cap") {
                  coldef["valueFormatter"] = (params: any) => params.value ? a + "" + params.value.toUpperCase() + "" + b : "";
                } else {
                  coldef["valueFormatter"] = (params: any) => params.value ? a + "" + params.value.toLowerCase() + "" + b : "";
                }
              }

              if(res.isLinkingPage) {
                coldef['cellRendererFramework'] = AgRecordDetailsComponent;
                coldef['cellRendererParams'] = (params: any) => { 
                  params['type']=res.isDataType;
                  params['item']=this.item;
                  params['from'] = 'child';
                  params['filter'] = newData;
                  params['detailFilterKey'] = filerRouter;
                  return params;
                }
              }
              
              this.childColumnDefs.push(coldef);
  
            }
          }
        })
      })
    }
  }

  // API For Parent Data
  loadApiData() {
    
    this.parentData = [];
    this.childData = [];

    let parent = this.item.config["list_of_api_parent"] || {};
    
    if(this.filterService.isEmptyObject(this.filterService.baseQuery) == false) {
      let obj1:any = {"api_key" : parent.api_key}

      let query1 = Object.assign({}, this.filterService.baseQuery, obj1, this.routerQueryParam)
      query1["report_typ"] = this.filterService.report_type || this.reportType;
      
      if(parent.api_key==100190 || parent.api_key == 10019702 ) {
        if(this.enhancementService.currentQuarterFlag ) {
          query1["table_nm_version"] = 'curr'
        } else {
          query1["table_nm_version"] = 'hist'
        }
      }
      
      this.reqSubcription.push(
        this.httpClient.post(`${this.apiService.apiBaseUrl}/${this.apiService.executionUrl}`, query1).subscribe((res:any)=> {
          
          let resParentData:any = res == null ? [] : res;
          if(resParentData.length==0) {
            this.rowData=[];
            this.loader=false; 
          } else {
            if(this.item.config['connecting_detail_grid'].name == 'Yes') {
              this.childApiData(resParentData);
            } else {
              this.rowData = resParentData;
              this.loader=false; 
            }
          }
        },(err:any)=>{
          console.log('Payment Error Record Parent', err);
          this.rowData = [];
          this.loader=false;
        })
      )
    }
  }

  // API For Child Data
  childApiData(resParentData:any) {
    let detail = this.item.config["list_of_api_detail"] || {};
    let obj2:any = {"api_key" : detail.api_key}

    let query2 = Object.assign({}, this.filterService.baseQuery, obj2, this.routerQueryParam)
    query2["report_typ"] = this.filterService.report_type || this.reportType;
    if(detail.api_key==10019703 ) {
      if(this.enhancementService.currentQuarterFlag ) {
        query2["table_nm_version"] = 'curr'
      } else {
        query2["table_nm_version"] = 'hist'
      }
    }

    this.reqSubcription.push(
      this.httpClient.post(`${this.apiService.apiBaseUrl}/${this.apiService.executionUrl}`, query2).subscribe((res:any)=> {
        let resChildData  = res == null ? [] : res;
        if(resChildData.length==0) {
          this.rowData=resParentData;
          this.loader=false;
        } else {
          this.getData(resParentData, resChildData)
        }
      },(err:any)=>{
        console.log('Payment Error Record Child', err);
        this.rowData=resParentData;
        this.loader=false;
      })
    )
  }
  
  // Get Mergin reference
  getData(resParent:any, resChild:any) {
    
    resParent.forEach((parent: any) => {
      let condition: any = {};
      let string = '';
      // Construct the condition dynamically based on reference_column values
      this.item.config['reference_column'].forEach((column: any) => {
        condition[column.api_resp_column_nm] = parent[column.api_resp_column_nm];
      });
      
      let child = resChild.filter((child: any) => {
        // Check if all properties in the condition match with child object
        return Object.keys(condition).every(key => child[key] === condition[key]);
      });
    
      Object.assign(parent, {'callRecords': [...child]});
      
      string = '';
      if(child.length>0) {
        child.map((m:any)=> {  
          let Ovalue = Object.entries(m).map(([key, value]) => ({ key, value }));
          Ovalue.map((o:any) => string += o.value + ' ')
        })
      } else {
        string = '';
      }

      Object.assign(parent, {'hidden_column': string});
    });
    
    setTimeout(() => {
      this.rowData=[...resParent];
      this.loader=false;
    }, 200);
  }

  ngOnDestroy(): void {
    this.reqSubcription.forEach((res) => res.unsubscribe());
  }
}








